<template>
  <div @click="!isMobile() ? handleProductClick() : showDetail()" class="list-item  cursor-pointer" :class="this.class_half">
    <div class="list-item__image " ref="itemImage">
      <div class="p-10" :class="class_image"  :style="css_img" ref="itemImage" v-if="isMobile()">
        <template v-if="product.point > 0">
          <div class="flex flex-row">
            <div class="my-auto">
              <img class="slice-image" :src="img_url+product.image" :alt="product.name" v-if="!emptyVariable(product.image)">
            </div>
            <div class="flex-grow">
              <p class="typography-7 list-item__name flex-1 font-semibold" style="padding-bottom: 0 !important;">{{product.name}}</p>
              <p class="typography-7 list-item__desc flex-1">{{$t('common.format_point', {amount: formatCurrency(product.point, $const.SUFFIX_CURRENCY)})}}</p>
            </div>
            <div class="my-auto">
              <button v-if="product.point > 0 " class="button w-auto button--md text-red border-solid border-2 border-red flex-1 font-semibold p-20 t15 font-bold">
                <span>{{$t('common.use')}}</span>
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <img class="rounded" :src="img_url+product.image" :alt="product.name" v-if="!emptyVariable(product.image)">
        </template>
      </div>
      <div :class="class_image" :style="css_img" ref="itemImage" v-else>
        <img :src="img_url+product.image" :alt="product.name"  v-if="!emptyVariable(product.image)">
      </div>
      <div v-if="is_half_combo">
        <!-- <p class="typography-7 list-item__name flex-1 font-semibold" v-if="isMobile()">{{product.name}}</p>
        <p class="typography-4 list-item__name flex-1" v-else>{{product.name}}</p>
        <p class="typography-6 list-item__desc flex-1">{{product.description}}</p> -->
        <HighlightWords class="typography-7 list-item__name flex-1 font-semibold" v-if="isMobile()"
          highlightClassName="highlight"
          :searchWords="keywords()"
          :autoEscape="true"
          :textToHighlight="product.name"/>
        <HighlightWords class="typography-4 list-item__name flex-1" v-else
          highlightClassName="highlight"
          :searchWords="keywords()"
          :autoEscape="true"
          :textToHighlight="product.name"/>
        <HighlightWords class="typography-6 list-item__desc flex-1"
          highlightClassName="highlight"
          :searchWords="keywords()"
          :autoEscape="true"
          :textToHighlight="product.description"/>
      </div>
    </div>
    <template v-if="!product.point">
      <!-- <p class="typography-7 list-item__name flex-1 font-semibold" v-if="isMobile()">{{!is_half_combo ? product.name : ''}}</p>
      <p class="typography-4 list-item__name flex-1" v-else>{{!is_half_combo ? product.name : ''}}</p>
      <p class="typography-6 list-item__desc flex-1">{{!is_half_combo ? product.description : ''}}</p> -->
      <HighlightWords class="typography-7 list-item__name flex-1 font-semibold" v-if="isMobile()"
        highlightClassName="highlight"
        :searchWords="keywords()"
        :autoEscape="true"
        :textToHighlight="product.name"/>
      <HighlightWords class="typography-4 list-item__name flex-1" v-else
        highlightClassName="highlight"
        :searchWords="keywords()"
        :autoEscape="true"
        :textToHighlight="product.name"/>
      <HighlightWords class="typography-6 list-item__desc flex-1"
        highlightClassName="highlight"
        :searchWords="keywords()"
        :autoEscape="true"
        :textToHighlight="product.description"/>
    </template>

    <div class="mx-10 flex flex-row items-baseline justify-end mb-10" v-if="isMobile()">
      <span v-if="!is_half_combo" class="typography-4 list-item__price self-center w-full">
        <div class="flex flex-row" v-if="!product.point">
          <span class="my-auto t15 mt13">
            <span class="text-grey capitalize mr-5">{{$t('common.from')}}</span>
            {{$t('common.format_currency', {amount: formatCurrency(product.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
          </span>
          <button class="button w-auto ml-auto button--md button--red font-semibold t15 mr-10 px-30">
              <span>{{$t('common.btn.add')}}</span>
          </button>
        </div>
      </span>
      <button v-if="this.is_half_combo" class="button button--md font-semibold mt-10 height-auto button--red t15 mt14"  @click="handleProductClick">
        <span>{{$t('common.btn.add')}}</span>
        <span class="t15 mt13">
          {{$t('common.format_currency', {amount: formatCurrency(product.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
        </span>
      </button>
    </div>
    <div class="mx-10 py-10 flex items-baseline justify-end border-t pt-5" v-else>
      <span v-if="!this.is_half_combo" class="typography-4 list-item__price self-center pt-5 w-full">
        <button v-if="product.point > 0 " class="button button--md button--red flex-1 font-semibold t15 mt13" style="width: inherit">
          <span>{{$t('common.add_to_redeem')}}</span> <span class="w-auto">{{$t('common.format_point', {amount: formatCurrency(product.point, $const.SUFFIX_CURRENCY)})}}</span>
        </button>
        <span v-else class="t15 mt13">
          {{$t('common.format_currency', {amount: formatCurrency(product.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
        </span>
      </span>
      <button v-if="this.is_half_combo" class="button button--md font-semibold mt-10 height-auto button--red t15 mt14"  @click="handleProductClick">
        <span>{{$t('common.btn.add')}}</span>
        <span class="t15 mt13">
          {{$t('common.format_currency', {amount: formatCurrency(product.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import ModalVariantDetail from "@/components/modal/ModalVariantDetail"
import { mapState } from 'vuex'
import HighlightWords from 'vue-highlight-words'

export default {
  props:["product", 'codeRedeem'],
  components: {HighlightWords},
  data() {
    return {
      img_url: '',
      class_half: '',
      class_image: '',
      css_img:'',
      is_half_combo: false,
      words: this.currentKeyword
    }
  },
  created() {
    this.is_half_combo = this.product.is_combo && this.product.is_combo_half
    this.class_half = this.is_half_combo ? ' list-item--pizza' : (!this.product.point ? 'product-list--deals' : '')
    this.class_image = this.is_half_combo ? 'list-item__image flex-center flex' : ''
    this.css_img = this.is_half_combo ? 'height: 200px' : ''
  },
    methods: {
      keywords() {
        return this.currentKeyword.split(' ')
      },
      handleProductClick () {
        let dataPro = {
          'name': this.product.name,
          'id': this.product.uuid,
          'price': this.product.sub_price,
          'category': this.product.category && this.product.category.name ? this.product.category.name : '',
          'url': window.location.href,
          'is_upsale': this.$route.query.is_upsell && this.$route.query.product === this.product.uuid ? 1 : 0
        };
        this.eventProductClick(dataPro);
        this.$router.push({name: 'combo-detail', params: {uuid: this.product.uuid, code: this.codeRedeem, is_half_combo: this.is_half_combo}})
      },
      showDetail: debounce(function () {
        this.$modal.show(ModalVariantDetail, {
          productData: this.product,
        },
        {
          'class': 'modal-product-form'
        },
        {
          'before-open': function () {
          },
          'before-close': function () {
          }
        })
      }, 500),
    },
    mounted() {
      // const size = this.$refs.itemImage.clientWidth
        // this.img_url = this.$const.LINK_STATIC_FLY + "width=" + size + "/"
    },
    computed: {
      ...mapState({
        currentKeyword: 'currentKeyword',
      })
    }
}
</script>

<style scoped>
.slice-image {
  width: 70px !important;
  height: auto;
}
.highlight {
  background-color: yellow;
  padding: 0 2px;
}
</style>
