import types from '../../mutationTypes'

export default {
    [types.ORDER_SAVE_ITEM]({commit, state}, payload) {
        commit(types.ORDER_SAVE_ITEM, payload)
    },
    [types.ORDER_RESET_ITEM]({commit, state}) {
        commit(types.ORDER_SAVE_ITEM, {
          "order_code": "",
          "order_uuid": "",
          "order_status_code": "",
          "order_status_uuid": "",
          "collection_time": "",
          "order_information": {}
        })
    },
}
