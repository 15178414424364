<template>
  <div class="container">
    <template v-if="isAuth">
      <div class="flex flex-col px-15">
        <span class="typography-9 pt-10 pb-5 text-left font-bold">
          {{$t('my_order.history_order_type')}}
        </span>
      </div>
      <div class="order-type-box block-order-icon mx-15 mb-20 px-30 py-15 border border-grey-lightest border-solid border-rounded typography-8">
        <template v-for="(item, key) in orderTypes">
          <a class="text-center" :key="key" @click="currentOrderType = item">
            <img 
              class="pb-10"
              :src="require(`@/assets/images/trackers/icon-${item}-${item === currentOrderType ? 'active' : 'inactive'}.png`)"
            />
            <span class="capitalize">{{$t(`orders.${item}`)}}</span>
          </a>
        </template>
      </div>
      <div class="flex flex-col">
        <template v-for="(row, key) in orders[currentOrderType]">
          <div class="w-full" :key="key">
            <div class="bg-grey-lightestest typography-9 font-semibold text-grey px-15 py-3">
              {{ row.timekey }}
            </div>
            <template v-for="(item, key2) in row.data">
              <div class="flex flex-row py-15 px-15" :key="key2">
                <div class="flex flex-col pr-20">
                  <div class="flex typography-9">{{ timeOnlyFormat(item.order_time) }}</div>
                  <div class="flex typography-9 mt-auto font-bold">
                    {{$t('common.format_currency', {amount: formatCurrency(item.total, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="flex flex-col typography-9">
                    <span class="text-grey mb-5">Order #{{ item.code }}</span>
                    <span class="font-normal mb-10 font-semibold">{{ item.store.name }}</span>
                    <div class="flex typography-9 mt-auto capitalize" :class="{'text-red': isFinished(item.order_status.code)}">
                      <i class="fa my-auto mr-10" :class="{'fa-check': isFinished(item.order_status.code), 'fa-clock-o': !isFinished(item.order_status.code)}" /> {{item.order_status.name}}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col items-center justified-center py-40">
        <img src="@/assets/images/unsatisfied.png">
        <p class="typography-10 font-bold text-red">{{$t('my_order.no_history')}}</p>
        <p class="typography-7 text-center">{{$t('my_order.no_history_body')}}</p>
      </div>
    </template>
  </div>
</template>

<script>
import { findIndex } from 'lodash'
import OrderApi from '@/services/order'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutationTypes'
import moment from 'moment'

export default {
  props: {
    orderCode: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      orderTypes: ['delivery', 'carryout', 'dinein'],
      currentOrderType: 'dinein',
      orders: {
        delivery: [],
        carryout: [],
        dinein: []
      },
      query: {
        limit: 12,
        offset: 0
      }
    }
  },

  mounted() {
    this.ordersHistory(this.currentOrderType)
  },

  computed: {
    ...mapGetters ({
      isAuth: 'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
    }),
  },

  watch: {
    currentOrderType(v) {
      this.ordersHistory(v)
    }
  },

  methods: {
    async ordersHistory (v) {
      this.query.order_type_uuid = this.$const.ORDERTYPE_UUIDS[v.toUpperCase()]
      await OrderApi.orders({vm: this}, (response) => {
        const obj = []
        for (const i in response.data) {
          const timekey = moment.utc(response.data[i].order_time).local().format('MMMM DD, YYYY')
          if (!obj[i]) {
            obj[i] = []
          }
          const idx = findIndex(obj, o => o.timekey === timekey)
          if (idx !== -1) {
            obj[idx].data.push(response.data[i])
          } else {
            obj[i] = {timekey: timekey, data: [response.data[i]]}
          }
        }
        this.$set(this.orders, v, JSON.parse(JSON.stringify(obj)))
      })
    },
    headerTimeFormat (v) {
      return moment.utc(v).local().format('MMMM DD, YYYY')
    },
    timeOnlyFormat (v) {
      return moment.utc(v).local().format('HH:mm')
    },
    orderStatus(v) {
      let number = 0
      if (this.currentOrderType.toUpperCase() === this.$const.ORDERTYPE.DELIVERY || this.currentOrderType.toUpperCase() === this.$const.ORDERTYPE.CARRYOUT) {
        if (this.$const.ORDERSTATUS_MAPPING_CODE.BAKING.indexOf(v) !== -1) {
          number = 1
        } else if (this.$const.ORDERSTATUS_MAPPING_CODE.DELIVERING.indexOf(v) !== -1) {
          number = 2
        } else if (this.$const.ORDERSTATUS_MAPPING_CODE.FINISHED.indexOf(v) !== -1) {
          number = 3
        }
        return Object.keys(this.$const.ORDERSTATUS_MAPPING_CODE)[number].toLowerCase()
      } else if (this.currentOrderType.toUpperCase() === this.$const.ORDERTYPE.DINEIN) {
        if (this.$const.ORDERSTATUS_DINEIN_MAPPING_CODE.COOKING.indexOf(v) !== -1) {
          number = 1
        } else if (this.$const.ORDERSTATUS_DINEIN_MAPPING_CODE.SERVED.indexOf(v) !== -1) {
          number = 2
        }
        
        if (v === this.$const.ORDERSTATUS.FINISHED.toUpperCase()) {
          number = 3
          return Object.keys(this.$const.ORDERSTATUS_MAPPING_CODE)[number].toLowerCase()
        }
        return Object.keys(this.$const.ORDERSTATUS_DINEIN_MAPPING_CODE)[number].toLowerCase()
      }
    },
    isFinished (v) {
      return this.orderStatus(v).toUpperCase() === this.$const.ORDERSTATUS.FINISHED.toUpperCase() || this.orderStatus(v).toUpperCase() === this.$const.ORDERSTATUS.SERVED.toUpperCase()
    }
  }
}
</script>

<style scoped>
.order-type-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.order-type-box img {
  width: 23%;
  margin: auto;
}
</style>