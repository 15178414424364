<template>
  <transition name="modal" style="z-index: 999999 !important">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container h-full product-variant-model-container" :class="{ 'bg-grey-lightest': selectedGroupList.uuid, 'bg-white': !selectedGroupList.uuid }">
          <template v-if="!selectedGroupList.uuid">
            <div class="modal-header" style="padding-top: 0;">
              <div class="product-variant-image-bg" :style="{ backgroundImage: `url(${require('@/assets/images/detail-bg.png')})` }">
                <img class="m-auto" :src="productData.image" />
              </div>
              <button type="button" aria-label="Close" class="product-variant-close-btn close" @click="$emit('close')"><i class="fa fa-angle-left" /></button>
            </div>

            <div class="modal-body product-variant-body container mb-100">
              <div class="title-block">
                <h2 class="font-united-sans-bold uppercase px-15 mb-0 mt-10">{{ productData.name }}</h2>
                <div class="font-open-sans text-grey px-15" v-html="productData.description"></div>
              </div>
              <div class="option-group-block bg-grey-lightestest flex flex-col py-10 px-10" v-if="groupList.length > 0">
                <template v-for="(group, key) in groupList">
                  <a class="group-box bg-white border-rounded px-10 py-15 flex flex-row shadow my-10" :class="{ 'completed': !emptyVariable(groupDetails[key].info) }" :key="key" @click="!groupDetails[key].auto ? group.products.length > 1 ? setSelectedGroup(group, key) : showDetail(group.products[0], key) : false">
                    <span class="flex flex-col m-auto ml-5 typography-7" v-if="emptyVariable(groupDetails[key].info)">{{ group.name }}</span>
                    <div class="flex flex-col flex-grow m-auto ml-5" v-else>
                      <p class="typography-7">{{ groupDetails[key].info.product.name }}</p>
                      <p class="typography-9 mt-5 text-grey" v-if="!emptyVariable(groupDetails[key].info.crust)">
                        <span v-if="!emptyVariable(groupDetails[key].info.size)">{{ groupDetails[key].info.size.name }} </span>{{ groupDetails[key].info.crust.name }}
                      </p>
                      <div class="flex flex-row w-full" v-if="!emptyVariable(groupDetails[key].info.hasExtraCheese)">
                        <span class="flex typography-9 mt-5 text-grey mr-auto">{{ groupDetails[key].info.product.toppings.additional_toppings[0].name }}</span>
                        <span class="flex typography-9 mt-5 text-grey ml-auto">{{ $t('common.format_currency', {amount: formatCurrency(groupDetails[key].info.hasExtraCheese.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY}) }}</span>
                      </div>
                    </div>
                    <span class="my-auto px-10 text-red typography-1 font-semibold" v-if="emptyVariable(groupDetails[key].info)">+</span>
                    <span class="my-auto px-10 text-grey typography-5 font-semibold" v-else-if="!groupDetails[key].auto"><i class="fa fa-trash" /></span>
                  </a>
                </template>
              </div>
              <div class="quantity-block flex flex-col">
                <div class="bg-grey-lighter typography-7 font-semibold px-15 py-10 mb-10">{{$t('common.quantity')}}</div>
                <div class="flex mx-auto mt-5">
                  <button class="decrement-button border-2 border-solid border-rounded" :class="{ ' border-red text-red': !disabledDecrement, 'border-grey-lighter text-grey-lighter': disabledDecrement }" :disabled="disabledDecrement" @click="decrementQuantity">−</button>
                  <input class="quantity-input font-open-sans text-grey-darkest border border-solid border-grey-darkest border-rounded" name="quantity" type="number" :min="min" :max="max" :step="step"
                          v-model.number="quantity"
                          @keydown.esc="validateAmount"
                          @blur="validateAmount"
                          @keydown.enter="validateAmount"
                  />
                  <button class="increment-button border-2 border-solid border-rounded" :class="{ ' border-red text-red': !disabledIncrement, 'border-grey-lighter text-grey-lighter': disabledIncrement }" :disabled="disabledIncrement" @click="incrementQuantity">+</button>
                </div>
              </div>
              <div class="note-block px-15 mt-15">
                <div class="input-group border border-grey-light border-rounded px-15 py-10">
                  <div class="input-group-append"><img class="m-auto" src="@/assets/images/note-icon.png" alt="note-icon" style="width: 30px;" /></div>
                  <input type="text" v-model="note" class="form-control border-0 text-grey-dark typography-12 font-open-sans font normal" :placeholder="$t('common.item_note')"/>
                </div>
              </div>
            </div>

            <div class="modal-footer product-variant-footer absolute left-0 bottom-0 right-0 bg-white py-5" v-if="isEnoughAddCombo">
              <button @click="addToBasket" class="button button--md button--red flex-1 font-semibold t15 mt13" v-if="productData.point > 0">
                  <span>{{$t('common.add_to_redeem')}}</span>
                  <span class="w-auto" style="margin-left: 3px;">{{$t('common.format_point', {amount: formatCurrency(productData.point, $const.SUFFIX_CURRENCY)})}}</span>
              </button>
              <button @click="addToBasket" class="button button--md button--red flex-1 font-semibold t15 mt14 py-10 h-auto" v-else>
                  <span class="mx-auto">{{$t('common.add_to_cart')}}</span>
                  <span class="absolute right-0 mr-20 w-auto" style="margin-left: 3px;">{{$t('common.format_currency', {amount: formatCurrency(amount, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
              </button>
            </div>
          </template>
          <template v-else>
            <div class="modal-header flex bg-white relative" style="padding-top: 0;">
              <div class="product-variant-group-header font-united-sans-bold t20 uppercase m-auto py-15">{{ $t('common.favorite_menu') }}</div>
              <button type="button" aria-label="Close" class="product-variant-group-close-btn close" @click="selectedGroupList = {}; selectedGroupIndex = -1"><i class="fa fa-angle-left" /></button>
            </div>
            <div class="modal-body product-variant-body container">
              <div class="w-full flex flex-wrap items-center py-10 px-15">
                <template v-for="(product, key) in selectedGroupList.products">
                  <div class="product-variant-list-item bg-white rounded shadow" :key="key" @click="showDetail(product, selectedGroupIndex)">
                    <img class="w-2/3 mx-auto" :src="product.image" :alt="product.name" />
                    <span class="mx-auto text-center font-open-sans font-semibold typography-9 px-10">{{ product.name }}</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { debounce, cloneDeep } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import mutationTypes from "@/store/mutationTypes"
import MenuApi from '@/services/menu'
import braze from "@/services/braze"
import ModalProductDetail from "@/components/modal/ModalProductDetail"

export default {
  props: {
    productData: {
      type: Object,
      default: () => ({})
    },
    // addToCart: {
    //   type: Function
    // },
    max: {
      type: Number,
      default: 99
    },
    min: {
      type: Number,
      default: 1
    },
    step: {
      type: Number,
      default: 1
    }
  },

  data: () => ({
    note: '',
    quantity: 1,
    productDetail: {},
    groupDetails: [],
    selectedGroupList: {},
    selectedGroupIndex: -1,
    selectedProduct: {},
    productPoint: false,
    disabledDecrement: true,
    disabledIncrement: false,
  }),

  computed: {
    ...mapGetters ({
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
      orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
      timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
      defaultCategory: mutationTypes.GET_DEFAULT_CATEGORY,
      isAuth: 'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
      localisation: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_LOCALISATION,
      cartCount: 'moduleCart/' + mutationTypes.CART_COUNT,
      cartData: 'moduleCart/' + mutationTypes.CART_DETAIL,
      visitorID:  mutationTypes.GET_VISITOR_ID,
    }),
    ...mapState({
      listCategories: 'categories',
    }),
    groupList() {
      if (this.productDetail && this.productDetail.groups) {
        return this.productDetail.groups
      } 
      return []
    },
    amount() {
      let amount = this.productData.sub_price
      let _selectedProductItem = this.selectedProduct ? cloneDeep(this.selectedProduct) : {}
      if (!this.emptyVariable(_selectedProductItem)) {
        amount += _selectedProductItem.sub_price
      }
      for (let i in this.groupDetails) {
        if (!this.emptyVariable(this.groupDetails[i].info)) {
          amount += this.groupDetails[i].info.total
        }
      }
      amount = amount * this.quantity
      return amount || 0
    },
    point() {
      let grandPoint = this.productData.point || 0
      for (let i in this.groupDetails) {
        if (!this.emptyVariable(this.groupDetails[i].info)) {
          grandPoint += this.groupDetails[i].info.totalPoint || 0
        }
      }
      return grandPoint * this.quantity || 0
    },
    isEnoughAddCombo () {
      let count = 0
      for (let i in this.groupDetails) {
        if (!this.emptyVariable(this.groupDetails[i].info) || this.groupDetails[i].min === 0) {
          count++
        }
      }
      return this.productDetail.groups ? count === this.productDetail.groups.length : false
    },
  },

  mounted() {
    this.getComboDetail(this.productData.uuid)
  },

  methods: {
    ...mapActions({
      addToCart: 'moduleCart/' + mutationTypes.CART_SAVE_ITEM,
      setCurrentCategory: mutationTypes.SET_CURRENT_CATEGORY,
      setStore: 'moduleLocalisation/' + mutationTypes.LOCALISATION_SAVE_STORE,
    }),
    getComboDetail (uuid) {
      var vm = this;
      let payload = {
        type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        store_uuid: this.storeUuid,
        vm: this,
      }
      MenuApi.getComboDetail(uuid, payload, function (response) {
        vm.productDetail = response.data;
        if (vm.productDetail.point > 0) {
          vm.productPoint = true;
        }
        if(vm.productDetail.is_combo_half){
          vm.comboHalfGroups.first = vm.productDetail.groups[0].products[0].option_groups
          vm.comboHalfGroups.second = vm.productDetail.groups[1].products[0].option_groups
          vm.productDetail.groups[1].isSecond = true;
        }
        vm.initGroupsData()
        vm.autoAddItemIntoGroup();
      }, function (error) {
        vm.notificationError(vm, error.message);
      })
    },
    initGroupsData () {
      const obj = []
      for (const i in this.productDetail.groups) {
        obj.push({
          "max": this.productDetail.groups[i].max,
          "min": this.productDetail.groups[i].min,
          "name": this.productDetail.groups[i].name,
          "uuid": this.productDetail.groups[i].uuid,
          "show": false,
          "auto": false,
          "info": {

          }
        })
      }
      this.$set(this, 'groupDetails', obj)
    },
    autoAddItemIntoGroup(){
      for (let i in this.productDetail.groups) {
        let group = this.productDetail.groups[i];
        if(!this.emptyVariable(group.products)
            && group.products.length === 1
            && group.min > 0
            && group.products[0].sub_price <= 0
            && !this.hasSelection(group.products[0])
            && this.emptyVariable(group.products[0].toppings.additional_toppings)
        ) {
          let selectedCrustItem = {};
          let selectedSizeItem = {};
          let price = group.products[0].sub_price;
          let totalPoint = group.products[0].point || 0;
          if (group.products[0].option_groups && group.products[0].option_groups.length > 0) {
            selectedCrustItem = group.products[0].option_groups[0];
            price += selectedCrustItem.sub_price;
            totalPoint += selectedCrustItem.point || 0;
            if (selectedCrustItem.options.length > 0) {
              selectedSizeItem = selectedCrustItem.options[0];
              price += selectedSizeItem.sub_price;
              totalPoint += selectedSizeItem.point || 0;
            }
          }
          let dataProduct = {
            "crust": selectedCrustItem,
            "size": selectedSizeItem,
            "product": group.products[0],
            "quantity": 1,
            "hasExtraCheese": {},
            "total": price,
            "totalPoint": totalPoint,
            "hasOption": false,
            "toppings": {
              "minus": [],
              "plus": []
            }
          }
          this.addToDeal(dataProduct, i * 1, false)
        }
      }
    },
    hasSelection (item) {
      if (item && item.option_groups && item.option_groups != undefined) {
        return item.option_groups.length > 1 || ( item.option_groups.length == 1 && item.option_groups[0].options.length > 1)
      }
      return false
    },
    addToDeal (menu, groupIndex, next = true, auto = true) {
      if (groupIndex > -1) {
        this.groupDetails[groupIndex].info = menu;
        this.groupDetails[groupIndex].show = false;
        this.groupDetails[groupIndex].auto = auto;
      }
      if (next) {
        if(this.productDetail.is_combo_half){
            if(groupIndex === 1){
                this.defaultCrust = this.comboHalfGroups.first.find(crust => crust.uuid == menu.crust.uuid)
            } else {
                this.defaultCrust = this.comboHalfGroups.second.find(crust => crust.uuid == menu.crust.uuid)
            }
        }
      }
    },
    addToBasket() {
      let dataProduct = {
        "cart_uuid": this.cartUuid || '',
        "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        "items": [
          {
            "combo_uuid": this.productData.uuid,
            "is_point": this.productData.point > 0 ? 1 : 0,
            "promotion_code": this.codeRedeem || '',
            "products": [

            ],
            "quantity": this.quantity,
            "type": "combo",
            "voucher_code": "",
            "note": this.note
          }
        ],
        "now_order": this.orderNow,
        "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
        "store_uuid": this.storeUuid,
        "loyalty_id": this.visitorID,
      }

      // add table_number only for dine in
      if (this.localisation.orderType === this.$const.ORDERTYPE.DINEIN) {
        dataProduct.table_number = this.localisation.tableNumber
      }

      for (let detail of this.groupDetails) {
        let plus = [];
        if ( !this.emptyVariable(detail.info.hasExtraCheese)) {
          plus.push({
            quantity: 1,
            uuid: detail.info.hasExtraCheese.parent_uuid,
          });
        }
        if(detail.info.product){
          dataProduct.items[0].products.push({
            "category_uuid": detail.info.product.category.uuid,
            "combo_group_uuid": detail.uuid,
            "option_uuid": detail.info.size && !this.emptyVariable(detail.info.size) ? detail.info.size.uuid : '',
            "option_group_uuid": detail.info.crust && !this.emptyVariable(detail.info.crust) ? detail.info.crust.uuid : '',
            "product_uuid": detail.info.product.uuid,
            "quantity": 1,
            "toppings": {
              "removal": [],
              "addition": plus
            }
          })
        }
      }
      this.addToCart({vm: this, payload: dataProduct}).then((response) => {
        this.localisation.cartUuid = response.information.cart_uuid
        this.setStore(this.localisation)
      })
      braze.customEvent('add_to_cart', {
        product_name: this.productData.name,
        segment: this.$const.BRAZE_SEGMENT[this.orderType],
      })
      this.$emit('close')
    },
    showDetail: debounce(function (product, index) {
      this.$modal.show(ModalProductDetail, {
        productData: product,
        isCombo: true,
        addToDeals: (data) => {
          this.addToDeal(data, index * 1, false, false)
          this.selectedGroupList = {}
          this.selectedGroupIndex = -1
        }
      },
      {
        'class': 'modal-product-form'
      },
      {
        'before-open': function () {
        },
        'before-close': function () {
        }
      })
    }, 500),
    setSelectedGroup(group, index) {
      this.$set(this, 'selectedGroupList', group)
      this.$set(this, 'selectedGroupIndex', index)
    },
    validateAmount(){
      if ( !Number.isInteger(this.quantity) || this.quantity < 0) {
        this.$set(this, 'quantity', this.amount ? this.amount : 0)
      }
    },
    incrementQuantity() {
      if (this.disabledIncrement) {
        return
      }
      this.$set(this, 'disabledDecrement', false)
      this.handleValue(this.quantity + (this.step * 1))
    },
    handleValue (value){
      const old = this.quantity

      if (old === value) {
        return
      }
      if (value <= this.min) {
        value = this.min
        this.$set(this, 'disabledDecrement', true)
      }
      if (value >= this.max) {
        value = this.max
        this.$set(this, 'disabledIncrement', true)
      }
      this.$set(this, 'quantity', value)
    },
    decrementQuantity() {
      if (this.disabledDecrement) {
        return
      }
      this.$set(this, 'disabledIncrement', false)
      this.handleValue(this.quantity + (this.step * (-1)))
    },
  }
}
</script>

<style lang="scss">
.product-variant-model-container {
  overflow-y: auto;
  .decrement-button, .increment-button {
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
  }
  .quantity-input {
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
    margin: 0 20px;
    font-size: 0.857rem !important;
  }
  .group-box.completed {
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      background: red;
    }
  }
}

.product-variant-image-bg {
  background: grey;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.product-variant-close-btn {
  position: absolute;
  font-size: 2rem;
  background: white;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0 !important;
  margin-top: 15px !important;
  margin-left: 15px !important;
  border-radius: 50%;
  color: grey;
}

.product-variant-group-close-btn {
  position: absolute;
  font-size: 3rem;
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0 !important;
  margin-top: 10px !important;
  margin-left: 10px !important;
  border-radius: 50%;
  color: grey;
}

.product-variant-list-item {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px;
  text-decoration: none;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 calc(50% - 20px);
  -ms-flex: 1 1 calc(50% - 20px);
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 1px 1px -1px rgba(0, 0, 0, .6);
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, .6);
  aspect-ratio: 1/1;
}
</style>