<template>
    <div>
        <div class="app en-GB app-bg">
            <div class="order">
                <div class="grid basket-placeholder" style="padding-bottom: 125px;">
                    <header-order></header-order>
                    <div class="flex lg:flex-col">
                        <navigation v-if="!currentKeyword || (currentKeyword && currentKeyword.length === 0)"></navigation>
                        <router-view></router-view>
                    </div>
                </div>
                <cart></cart>
            </div>
            <div>
                <div class="notification-container notification-container__basket">
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import '@/assets/css/order.css';
import '@/assets/css/flag.css';
import Cart from '../components/partials/cart'
import Navigation from '../components/NavigationComponent'
import HeaderOrder from '../components/HeaderOrderComponent'
import types from '../store/mutationTypes'
import {  mapState, mapGetters, mapActions  } from 'vuex'
import localizationAPI from "../services/localization"
import OrderApi from '../services/order'
import ModalOrderTime from "../components/modal/ModalOrderTime"
import ModalNotifyBlack from "../components/modal/ModalNotifyBlack"
import mutationTypes from "@/store/mutationTypes"
import { orderMixins } from '../mixins/order'

export default {
  mixins: [orderMixins],
  data () {
    return {}
  },
  components: {
    'cart': Cart,
    'navigation': Navigation,
    'header-order': HeaderOrder,
  },
  computed: {
    ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      storeName: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_NAME,
      location: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCATION,
      orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
      cartData: 'moduleCart/' + types.CART_DETAIL,
      cartCount: 'moduleCart/' + types.CART_COUNT,
      localisationInfo: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
      orderCode: 'moduleOrder/' + types.ORDER_GET_ORDER_CODE,
      orderDetails: 'moduleOrder/' + types.ORDER_GET_ORDER_INFORMATION,
      defaultCategory:  mutationTypes.GET_DEFAULT_CATEGORY,
    }),
    ...mapState({
      listCategories: 'categories',
      currentCategory: 'currentCategory',
      currentKeyword: 'currentKeyword',
    })
  },
  watch: {
    '$route':  {
      handler: 'routeChanged',
    },
    currentCategory: function (newValue) {
      if (newValue) {
        // eslint-disable-next-line no-console
      }
    },
    currentKeyword: function (newValue) {
      if (newValue) {
        // eslint-disable-next-line no-console
      }
    }
  },
  mounted() {
    // eslint-disable-next-line no-console
    if (this.orderType === this.$const.ORDERTYPE.DINEIN) {
      if (this.orderCode) {
        this.checkLatestDineInOrder(false, true)
      } else {
        this.checkLatestDineInOrder(false, false)
      }
    }
  },
  created() {
    if (this.$route.params.slug != 'takeaway' && !this.storeUuid) {
        this.$router.push({name: 'home', query: {warning: '1',}});
    } else if (this.$route.params.slug == 'takeaway') {
      /* for new feature takeawayQR */
      // if (this.localisationInfo.orderType !== this.$const.ORDERTYPE.DINEIN && !this.localisationInfo.tableNumber && !this.orderCode && this.$route.query.uuid) {
      if (this.localisationInfo.orderType !== this.$const.ORDERTYPE.DINEIN && !this.localisationInfo.tableNumber && !this.orderCode && this.$route.params.id) {
        let vm = this
        /* for new feature takeawayQR */
        // this.resetLocalisation()
        // this.resetCart()
        // this.resetOrder()
        // const queries = atob(this.$route.query.uuid)
        // const url = new URL('https://' + window.location.host + '/?' + queries)
        // const store = url.searchParams.get("store")
        // localizationAPI.storeDetail({uuid: this.$route.params.id || store, vm: vm}, function (response) {
        localizationAPI.storeDetail({uuid: this.$route.params.id, vm: vm}, function (response) {
          if (response.code == 200) {
            let params = {
              lat: response.data.lat,
              long: response.data.long,
              now_order: vm.orderNow,
              datetime: vm.convertDateTimeFromTimezoneToUTC(vm.timezone, vm.collection_time),
              store_code: response.data.code,
              vm: vm
            }
            localizationAPI.getStoreCollection(params, function (storeCollection) {
              let storeData = storeCollection.data[0];
              if (vm.emptyVariable(storeData.business_hours) || vm.checkClosedStore(storeData.business_hours)) {
                vm.$modal.show(ModalNotifyBlack, {hideOK: true, contain: vm.$t('message.error.store_closed')},
                {
                    resizable: false, clickToClose: true,
                    classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'
                });
                vm.$router.push({name: 'home'});
              }
              if (storeData && storeData.available != 1) {
                vm.$modal.show(ModalOrderTime,
                  {
                    businessHoursCustom: storeData.business_hours,
                    timezoneHut: storeData.timezone,
                    triggerCheckLocalisationData: storeData,
                    chosenTime: function () {
                      vm.saveStore(storeData);
                    }
                  },
                  {
                    classes: (vm.isMobile()) ? 'modal-order-time' : ''
                  });
              } else if (storeData.available == 1) {
                vm.saveStore(storeData);
                vm.$root.$emit('triggerInitCategories', {goDeal: false});
                vm.$router.push({name: 'category', params: {slug: this.defaultCategory}});
              } else {
                this.$router.push({name: 'home', query: {warning: '1',}});
              }
              vm.saveStore(storeData);
              vm.$root.$emit('triggerInitCategories', {goDeal: false});
              vm.$router.push({name: 'category', params: {slug: this.defaultCategory}});
            })
          } else {
            vm.$router.push({name: 'home', query: {warning: '1',}});
          }
        }, function () {
          vm.$router.push({name: 'home', query: {warning: '1',}});
        })
      } else if (this.localisationInfo.orderType === this.$const.ORDERTYPE.DINEIN && this.localisationInfo.tableNumber && this.orderCode && this.orderDetails && this.orderDetails.order_type.code === this.$const.ORDERTYPE.DINEIN) {
        let vm = this
        vm.notificationError(vm, vm.$t('message.error.complete_order'))
        this.$router.push({name: 'status'})
      } else {
        this.resetLocalisation()
        this.resetCart()
        this.resetOrder()
        this.$router.push({name: 'home', query: {warning: '1',}});
      }
    }
  },
  methods: {
    ...mapActions({
      saveLocalisation: 'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
      resetCart: 'moduleCart/' + types.CART_RESET_DATA,
      resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
      resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      setCurrentKeyword: types.SET_CURRENT_KEYWORD_SEARCH,
    }),
    routeChanged() {
      // eslint-disable-next-line no-console
    },
    saveStore(store) {
      let storeData = this.localisationInfo;
      let storeDataNew = {
          "note": store.hasOwnProperty('note') ? store.note : '',
          "clientUUID": store.client_uuid || '',
          "code": store.code || '',
          "distance": store.distance || 0,
          "lat": store.lat,
          "location": '',
          "addressStore": store.location,
          "long": store.long,
          "name": store.name,
          "tierUUID": store.tier_uuid || null,
          "uuid": store.uuid,
          "timezone": store.timezone,
          "deliveryInfo": {},
          "businessHours": store.business_hours,
          "orderType": this.$const.ORDERTYPE.CARRYOUT,
          "storeType": store.store_type_id,
          "is_qr_code": 1,
          "storeBranch": store.storeBranch,
      }
      this.saveLocalisation({...storeData, ...storeDataNew});
      this.resetCart();
    },
  }
}
</script>