<template>
    <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
            <div class="p-20 bg-white">
                <h1 class="mb-40" style="color: #c8102e;"><b>NEWSROOM</b></h1>
                <div class="flex mb-40" v-for="(item) in listContent">
                  <div class="list mb-20 flex flex-col" v-if="isMobile()">
                    <img class="rounded-10 mb-20 w-full h-auto" :src=item.image>
                    <div class="flex-col ">
                      <h3 style="color: #c8102e;">{{ item.name }}</h3>
                      <p>{{ item.description }}</p>
                      <b class="mt-auto" :style="{ cursor: 'pointer' }" style="color: #c8102e;" @click="$router.push({name: 'newsroom-detail', params: {slug: item.slug}})">Baca lebih lanjut</b>
                    </div>
                  </div>

                  <div class="list mb-30 flex flex-row" v-else>
                    <img class="rounded-10" :src=item.image style="width: 300px; height: auto;">
                    <div class="flex-col ml-40">
                      <h2 style="color: #c8102e;">{{ item.name }}</h2>
                      <p>{{ item.description }}</p>
                      <b class="mt-auto" :style="{ cursor: 'pointer' }" style="color: #c8102e;" @click="$router.push({name: 'newsroom-detail', params: {slug: item.slug}})">Baca lebih lanjut</b>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import ContentApi from "@/services/content"

  export default {
    name: "Newsroom",
    data () {
      return {
        listContent: [],
        listSlug: [],
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList() {
        const vm = this
        let params = {
          vm: this
        };
        await ContentApi.getContentList(params,function (response) {
          const result = response.data.filter(function(e) {
            return e.name == "NEWSROOM";
          });
          vm.listContent = result[0].contents

          result[0].contents.forEach(e => {
            vm.listSlug.push(e.slug)
          })
        })
      }
    }
  }



</script>

<style scoped>
 /* .content {
     display:  inline-block;
     width: 100%;
     margin-bottom: 15px;
    }
 .content > img {
     float: left;
     width: 300px;
     margin-right: 20px;
 } */
</style>