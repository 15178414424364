import _constant from "../constant";

export default {
  lang: _constant.LANGUAGE.EN,
  isLoading: 0,
  categories: [],
  defaultCategory: '',
  currentCategory: {},
  customer_temp: {},
  visitor_id: null,
  deep_link: null
}