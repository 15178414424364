<template>
  <div class="full-screen-iframe">
    <iframe src="https://pizzahut40th.com" allow="camera"></iframe>
  </div>
</template>

<script>
export default {
  name: "40Tahun",
}
</script>

<style scoped>
  .full-screen-iframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: white;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
</style>