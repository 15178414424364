import Api from '@/services/api'
import types from "../store/mutationTypes";
const url = '/order/v2'
const urlV4 = '/order/v4'
const urlPayment = '/payment/v1'
export default {
    paymentStatus (uuid, data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(urlPayment + '/transaction-status/' + uuid)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    orderDetail (uuid, data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/orders/' + uuid)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    orderLatest (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            if (data.loader || data.loader === undefined) {
              mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            }
            delete data['vm']
        }
        return Api().get(urlV4 + '/orders-latest', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    orderLatestDineIn (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            if (data.loader || data.loader === undefined) {
              mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            }
            delete data['vm']
        }
        return Api().get(url + '/orders-latest-dine-in', {params: data})
          .then(response => {
            if (mainApp) {
              if (data.loader) {
                mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              if (data.loader) {
                mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    orderTable (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            if (data.loader || data.loader === undefined) {
              mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            }
            delete data['vm']
        }
        return Api().get(url + '/orders/table', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    validateTable (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            if (data.loader || data.loader === undefined) {
              mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            }
            delete data['vm']
        }
        return Api().get(url + '/validate-table-dine-in', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    orderTableGuest (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            if (data.loader || data.loader === undefined) {
              mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            }
            delete data['vm']
        }
        return Api().get(url + '/orders/table-guest', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    validateTableGuest (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            if (data.loader || data.loader === undefined) {
              mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            }
            delete data['vm']
        }
        return Api().get(url + '/validate-table-dine-in-guest', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    orders (data, success, error ) {
        var mainApp = null;
        let queries = null
        if (data.vm) {
            mainApp = data.vm;
            queries = data.vm.query
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/orders', {params: queries})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },  
    registerOrder (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/register-order', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
}