var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"en-GB app-bg"},[_c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"container py-40"},[_c('div',{class:`${_vm.isMobile() ? 'px-30' : 'px-60'}`},[_vm._m(0),_vm._m(1),_vm._m(2),_c('p',{staticClass:"text-justify mb-30 text-16"},[_vm._v("Pizza Hut Indonesia selalu menjalankan kepatuhan Halal tanpa ada pengecualian, termasuk untuk memperpanjang masa keberlakuan setiap kali berakhir sesuai dengan kebijakan dan ketetapan yang diberlakukan oleh Majelis Ulama Indonesia. Ini merupakan komitmen Kami untuk memastikan keamanan dan kenyamanan Para Pelanggan dalam mengkonsumsi produk-produk Pizza Hut di berbagai wilayah dari Sabang sampai Merauke.")]),_vm._m(3),_vm._m(4),_c('p',{staticClass:"text-justify mb-30 text-16 inline"},[_vm._v("Pizza Hut Indonesia terakhir kali menerima penghargaan LPPOM Majelis Ulama Indonesia ")]),_c('h2',{staticClass:"text-16 inline font-normal"},[_vm._v("Halal Award")]),_vm._m(5),_vm._m(6)]),_vm._m(7)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"mb-30 text-20 uppercase-text"},[_c('b',[_vm._v("Sertifikasi Halal Pizza Hut Indonesia")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-justify mb-30"},[_c('p',{staticClass:"text-justify mb-30 text-16 inline"},[_vm._v("Pizza Hut Indonesia mendapatkan ")]),_c('h2',{staticClass:"text-16 inline font-normal"},[_vm._v("Sertifikat Halal")]),_c('p',{staticClass:"text-justify mb-30 text-16 inline"},[_vm._v(" pertama kali dari LPPOM Majelis Ulama Indonesia (MUI) pada tahun 1997. Ruang lingkup Sertifikasi yang diberikan ")]),_c('b',[_vm._v("tidak hanya")]),_c('p',{staticClass:"text-justify mb-30 text-16 inline"},[_vm._v(" untuk seluruh Produk Makanan dan Minuman, namun juga mencakup Outlet-Outlet, Gudang-Gudang Penyimpanan, serta Kendaraan Pengangkutan.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-justify mb-30"},[_c('p',{staticClass:"text-justify mb-30 text-16 inline"},[_vm._v("Selain Sertifikat Halal, Pizza Hut Indonesia juga telah memperoleh sertifikasi Sistem Jaminan Halal (SJH) dari LPPOM ")]),_c('h2',{staticClass:"text-16 inline font-normal"},[_vm._v("MUI")]),_c('p',{staticClass:"text-justify mb-30 text-16 inline"},[_vm._v(" yang juga merupakan pembuktian bahwa Majelis Ulama Indonesia memberikan Penilaian dengan "),_c('b',[_vm._v("“Grade A”")]),_vm._v(" untuk Pizza Hut Indonesia selama periode 3x (tiga kali) berturut-turut. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-justify mb-30 text-16"},[_vm._v("Ketika Pemerintah Republik Indonesia memberlakukan Peraturan Pemerintah No. 39 Tahun 2021 tentang Penyelenggaraan Bidang Jaminan Produk Halal, Kami segera melakukan pembaharuan dan penyesuaian kepatuhan terhadap Regulasi Halal sesuai dengan ketentuan Pemerintah "),_c('b',[_vm._v("pada Tahun yang sama.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-justify mb-30 text-16"},[_vm._v("Saat ini (1) Ketetapan Halal Majelis Ulama Indonesia, (2) Sertifikat Sistem Jaminan Halal LPPOM MUI dan (3) Sertifikat Halal Badan Penyelenggara Jaminan Produk Halal (BPJPH) yang dimiliki Pizza Hut Indonesia masih berlaku sejak Tahun 2021 sampai dengan "),_c('b',[_vm._v("Tahun 2025.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-justify mb-30 text-16 inline"},[_vm._v(" 2023 untuk kategori "),_c('b',[_vm._v("Favorite Halal Brand – Category of Food Services (Food)")]),_vm._v(" pada tanggal 4 September 2023. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative",staticStyle:{"z-index":"-9999"}},[_c('h2',{staticClass:"text-white position-abolute"},[_vm._v("Pizza Hut")]),_c('h2',{staticClass:"text-white inline position-abolute"},[_vm._v("Sertifikat Halal Pizza Hut")]),_c('h2',{staticClass:"text-white inline position-abolute"},[_vm._v("Pizza Hut Halal")]),_c('h2',{staticClass:"text-white inline position-abolute"},[_vm._v("Restoran Halal")]),_c('h2',{staticClass:"text-white inline position-abolute"},[_vm._v("Donasi Pizza Hut")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-40 mb-40 px-60"},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("../assets/images/mui/Sertifikat_Halal_BPJPH.jpg"),"alt":"Sertifikat Halal BPJPH Pizza Hut"}}),_c('img',{attrs:{"src":require("../assets/images/mui/Fatwa_MUI_Ketetapan_Halal.jpg"),"alt":"Fatwa MUI Ketetapan Halal Pizza Hut"}})]),_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":require("../assets/images/mui/Sertifikat_SJH.jpg"),"alt":"Sertifikat SJH Pizza Hut"}}),_c('img',{attrs:{"src":require("../assets/images/mui/Fresh_Halal_Brand.jpg"),"alt":"Fresh Halal Brand Pizza Hut"}})])])
}]

export { render, staticRenderFns }