<template>
    <div>
        <img src="https://lp.adnear.net/ct.gif" width="1" height="1" />
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('orderConfirm.title_thank_you_order', {name: !emptyVariable(cartData) ? cartData.name : ''})}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.push({name: 'home'})"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white" v-if="emptyVariable(cartData)">
                    <div v-if="status == 2" class="text-center text-red t15">
                        {{$t('orderConfirm.load_order_detail_fail')}}
                    </div>
                </div>
                <div v-else-if="cartData.order_status && $const.ORDERSTATUS_MAPPING_CODE.CANCELLED.indexOf(cartData.order_status.code) >= 0">
                    <div class="block-order-status text-red t17 text-center">
                        {{$t('message.error.order_canceled')}}
                    </div>
                </div>
                <div class="p-20 bg-white" v-else>
                    <div class="block-order-info ">
                        <div class="block-order-status text-red t17 text-center"  v-if=" !emptyVariable(step)">
                            {{step.name}}
                        </div>
                        <div class="block-estimate-time text-center pb-20">
                            {{
                            $t('orderConfirm.estimated_time', {orderType : cartData.order_type.name.toLowerCase(),
                            dateTime: convertShowCollectionTime(cartData.collection_time, cartData)})
                            }}
                        </div>
                        <div class="block-order-icon" v-if="!emptyVariable(step)">
                            <div class=" w-full">
                                <ul class="process-bar w-full m-auto" style="max-width: 600px">
                                    <li :class="{'active': step.sequence == 1, 'highlight': step.sequence > 1 }">
                                        <img alt="accepted"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_1.ACTIVE" v-if="step.sequence == 1"  />
                                        <img alt="accepted" :src="$const.MAPPING_TRACKING_IMAGE.STEP_1.PAST" v-else  />
                                    </li>
                                    <li :class="{'active': step.sequence == 2, 'highlight': step.sequence > 2 }">
                                        <img alt="baking"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_2.PAST" v-if="step.sequence > 2" />
                                        <img alt="baking"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_2.ACTIVE" v-else-if="step.sequence == 2" />
                                        <img alt="baking"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_2.INACTIVE" v-else />
                                    </li>
                                    <li :class="{'active': step.sequence == 3, 'highlight': step.sequence > 3 }">
                                        <img alt="delivery"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_D.PAST" v-if="step.sequence > 3 && cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase()" />
                                        <img alt="delivery" :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_D.ACTIVE" v-else-if="step.sequence == 3 && cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase()" />
                                        <img alt="delivery"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_D.INACTIVE" v-else-if="cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase()" />

                                        <img alt="collection"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_C.PAST" v-if="step.sequence > 3 && cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.CARRYOUT.toLowerCase()" />
                                        <img alt="collection"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_C.ACTIVE" v-else-if="step.sequence == 3 && cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.CARRYOUT.toLowerCase()" />
                                        <img alt="collection"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_C.INACTIVE" v-else-if="cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.CARRYOUT.toLowerCase()" />
                                    </li>
                                    <li :class="{'active': step.sequence == 4, 'highlight': step.sequence > 4 }">
                                        <img alt="baking"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_4.PAST" v-if="step.sequence > 4" />
                                        <img alt="baking"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_4.ACTIVE" v-else-if="step.sequence == 4" />
                                        <img alt="baking"  :src="$const.MAPPING_TRACKING_IMAGE.STEP_4.INACTIVE" v-else />
                                    </li>
                                </ul>
                            </div>

                            <transition-group name="slide-fade" class="flex-center flex flex-row-reverse w-full" >
                                <img alt="accepted" :src="$const.MAPPING_TRACKING_IMAGE.STEP_1.GIFT" v-if="step.sequence == 1" :key="1" />
                                <img :key="2" alt="baking" :src="$const.MAPPING_TRACKING_IMAGE.STEP_2.GIFT" v-else-if="step.sequence == 2"/>
                                <img :key="3" alt="collection" :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_C.GIFT"  v-else-if="step.sequence == 3
                                                                                                                        && cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.CARRYOUT.toLowerCase()"/>
                                <img :key="4" alt="delivery" :src="$const.MAPPING_TRACKING_IMAGE.STEP_3_D.GIFT"  v-else-if="step.sequence == 3
                                                                                                                        && cartData.order_type.code.toLowerCase() === $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase()"/>
                                <img :key="5" alt="finish" :src="$const.MAPPING_TRACKING_IMAGE.STEP_4.GIFT"  v-else-if="step.sequence >= 4"/>
                            </transition-group>
                            <div class="text-center te"> {{step.description}} </div>
                        </div>

                    </div>
                    <div class="block-contain md:flex">
                        <div class="md:flex-1 bg-grey-lightestest ">
                            <div class="m-5 t17 bold">
                                {{$t('orderConfirm.order', {no: cartData.code})}}
                            </div>
                            <div class="block-cart-detail m-10">
                                <div class="block-cart-item " v-for="(item, indext) in cartData.details" :key="indext">
                                    <div  v-if="item.is_combo != 1" class="pt-10">
                                        <div class="flex">
                                            <div class="flex-1 bold t15">
                                                <span v-if="item.quantity > 1"> {{item.quantity}} x </span> <span v-if="item.product.option_group"> <span v-if="item.product.option_group && item.product.option_group.option"> {{item.product.option_group.option.name}} </span></span> {{item.product.name}}
                                            </div>
                                            <div>
                                                <span v-if="item.point > 0">{{$t('common.format_point', {amount: formatCurrency(item.point * item.quantity, $const.SUFFIX_CURRENCY)})}}</span>
                                                <span v-else>{{$t('common.format_currency', {amount: formatCurrency(item.sub_price * item.quantity, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                            </div>
                                        </div>
                                        <div v-if="item.product.option_group" class="flex ml-10 text-grey">
                                            <div class="flex-1  bold">
                                                <span>{{item.product.option_group.name}}</span>
                                            </div>
                                        </div>
                                        <div v-if="item.product.toppings && item.product.toppings.additional && item.product.toppings.additional.length > 0" class="flex ml-10 text-grey">
                                            <div class="flex-1  bold" v-for="(additional, indexA) in item.product.toppings.additional" :key="indexA">
                                                <span>{{$t('common.add')}} {{additional.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="pt-10">
                                        <div class="flex">
                                            <div class="flex-1 bold t15">
                                                <span v-if="item.quantity > 1"> {{item.quantity}} x </span> {{item.product.name}}
                                            </div>
                                            <div>
                                                <span v-if="item.point > 0">{{$t('common.format_point', {amount: formatCurrency(item.point * item.quantity, $const.SUFFIX_CURRENCY)})}}</span>
                                                <span v-else>{{$t('common.format_currency', {amount: formatCurrency(item.sub_price * item.quantity, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                            </div>
                                        </div>
                                        <div v-for="(menu,indexmenu) in item.combo_products" :key="indexmenu" class="pl-5">
                                            <div class="flex">
                                                <div class="flex-1 bold ">
                                                    <span v-if="menu.option_group"><span v-if="menu.option_group && menu.option_group.option">{{menu.option_group.option.name}}</span></span> {{menu.name}}
                                                </div>
                                            </div>
                                            <div v-if="menu.option_group" class="flex ml-10 text-grey">
                                                <div class="flex-1  bold">
                                                    <span>{{menu.option_group.name}}</span>
                                                </div>
                                            </div>
                                            <div v-if="menu.toppings && menu.toppings.additional && menu.toppings.additional.length > 0" class="flex ml-10 text-grey">
                                                <div class="flex-1  bold" v-for="(additional, indexA) in menu.toppings.additional" :key="indexA">
                                                    <span>{{$t('common.add')}} {{additional.name}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="block-total m-10" v-if="!emptyVariable(cartData.promotions)">
                                <div class="flex" v-for="(coupon, indexC) in cartData.promotions" :key="indexC">
                                    <div class="flex-1 bold ">
                                        {{coupon.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="block-total m-10">
                                <div class="flex">
                                    <div class="flex-1 bold ">
                                        {{$t('orderConfirm.subtotal')}}
                                    </div>
                                    <div>
                                        {{$t('common.format_currency', {amount: formatCurrency(cartData.sub_total, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                    </div>
                                </div>
                                <div class="flex" v-if="cartData.discount > 0">
                                    <div class="flex-1 bold " >
                                        {{$t('orderConfirm.discount')}}
                                    </div>
                                    <div>
                                        {{$t('common.format_currency', {amount: formatCurrency(cartData.discount, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                    </div>
                                </div>
                                <div class="flex" v-if="cartData.sub_delivery_fee > 0">
                                    <div class="flex-1 bold ">
                                        <!-- {{$t('orderConfirm.delivery_fee')}} -->
                                        {{ serviceChargeLabel.delivery }}
                                    </div>
                                    <div>
                                        {{$t('common.format_currency', {amount: formatCurrency(cartData.sub_delivery_fee, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                    </div>
                                </div>
                                <div class="flex" v-if="cartData.sub_takeaway_fee > 0">
                                    <div class="flex-1 bold " v-if="cartData.order_type.code == $const.ORDERTYPE.CARRYOUT || cartData.order_type.code == $const.ORDERTYPE.TAKEAWAY">
                                        <!-- {{$t('orderConfirm.takeaway_fee')}} -->
                                        {{ serviceChargeLabel.takeaway }}
                                    </div>
                                    <div class="flex-1 bold " v-else-if="cartData.order_type.code == $const.ORDERTYPE.DINEIN">
                                        <!-- {{$t('orderConfirm.takeaway_fee')}} -->
                                        {{ serviceChargeLabel.dine_in }}
                                    </div>
                                    <div>
                                        {{$t('common.format_currency', {amount: formatCurrency(cartData.sub_takeaway_fee, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                    </div>
                                </div>
                                <div class="flex" v-if="cartData.tax_value > 0">
                                    <div class="flex-1 bold ">
                                        {{$t('orderConfirm.tax_fee')}}
                                    </div>
                                    <div>
                                        {{$t('common.format_currency', {amount: formatCurrency(cartData.tax_value, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                    </div>
                                </div>
                                <div class="flex" >
                                    <div class="flex-1 bold ">
                                        {{$t('orderConfirm.total')}}
                                    </div>
                                    <div>
                                        {{$t('common.format_currency', {amount: formatCurrency(cartData.total, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="md:flex-1 m-10">
                            <div>
                                {{
                               cartData.order_type.code.toLowerCase() == $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase() ? $t('orderConfirm.delivery_to', {storeName : cartData.store.name}) : (cartData.order_type.code.toLowerCase() == $const.ORDERTYPE_MAPAPI.CARRYOUT.toLowerCase() ? $t('orderConfirm.carryout_from', {storeName : cartData.store.name}) : $t('orderConfirm.dinein_at', {storeName : cartData.store.name}))
                                }}
                            </div>
                            <div>
                                {{
                                cartData.order_type.code.toLowerCase() == $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase() ? $t('common.combine_delivery',{streetName: cartData.delivery.address_level_2, houseNo: cartData.delivery.address_level_1, buildingName: cartData.delivery.address_level_0, point: cartData.delivery.address_level_0 ? '.' : '',  postalCode: cartData.delivery.postal_code, state: cartData.delivery.address_level_3}) : cartData.store.localtion
                                }}
                            </div>
                            <div v-if="cartData.order_type.code.toLowerCase() == $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase() && cartData.contact_less == '1'">
                                <strong>{{$t('common.contact_less')}}</strong>
                            </div>
                            <div>
                                <p> <strong class="">{{$t('orderConfirm.payment_method')}}:</strong> {{cartData.payment ? cartData.payment.name: ''}}</p>
                            </div>
                            <div>
                                <div v-html="$t('orderConfirm.confirm_email', {email: cartData.email})"></div>
                            </div>
                            <div>
                                <div><strong>{{$t('orderConfirm.note')}}:</strong> {{cartData.note}}</div>
                            </div>
                            <div class="bg-grey-lightestest p-10 ">
                                {{$t('orderConfirm.question_order')}} {{$const.CONTACT_PHONE}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-component />
    </div>

</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import FooterComponent from '../components/FooterComponent';
  import moment from 'moment-timezone'
  import OrderApi from '../services/order'
  import systemApi from '../services/system'
  import ModalTreasureHut from "@/components/modal/ModalTreasureHut";
  import {mapGetters} from "vuex";
  import types from "@/store/mutationTypes";
  import treasureHutAPI from "@/services/treasurehut";

  export default {
    name: "order-confirmation",
    components: {
      HeaderComponent,
      FooterComponent
    },
    data() {
      return {
        show: true,
        step: {},
        status: 0, // 0 normal, 1 success, 2 error
        uuid: '',
        cartData: {},
        iterValObj: null,
        modeCheckStatus: false,
        isShowTreasureHut: false,
        query: {},
        serviceChargeLabel : {},
      }
    },
    computed: {
      ...mapGetters({
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
        authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      })
    },
    mounted() {
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        "/js/tiktok.js"
      );
      plugin.async = true;
      document.head.appendChild(plugin);
      this.getConfigByKey()
    },
    created() {
      this.uuid = this.$route.params.uuid;
      const vm = this;
      vm.getOrderDetail(vm.uuid, true);
      this.iterValObj = setInterval(function () {
        vm.getOrderDetail(vm.uuid, false, true);
      }, 5000)
    },
    destroyed(){
      clearInterval(this.iterValObj);
    },
    methods: {
      async getConfigByKey(keyName) {
        this.query.key = keyName
        var vm = this;
        await systemApi.getConfig(vm, 
          (response) => {
            this.serviceChargeLabel = response.data.service_charge_label
          },
        )
      },
      stopInterval () {
        if (this.cartData.order_status &&
          (
            this.$const.ORDERSTATUS_MAPPING_CODE.CANCELLED.indexOf(this.cartData.order_status.code) >= 0 ||
            this.$const.ORDERSTATUS_MAPPING_CODE.FINISHED.indexOf(this.cartData.order_status.code) >= 0
          )
        ) {
          clearInterval(this.iterValObj);
        }
      },
      getOrderDetail (uuid, flag = false) {
        const vm = this;
        let payload = {};
        if (flag) {
          payload = {vm: this};
        }
        OrderApi.orderDetail(uuid, payload,
          function (response) {
            if (!vm.modeCheckStatus) {
              vm.modeCheckStatus = true;
              vm.status = 1;
              vm.cartData = response.data;
              if (flag && vm.isFired(vm.cartData.uuid)) {
                vm.eventPurchase(response.data);
                // vm.eventScreenLoad();
                // vm.eventPageView();
                vm.setFired(vm.cartData.uuid);
              }
            } else {
              vm.cartData.order_status = response.data.order_status;
            }
            if (!vm.emptyVariable(response.data.order_status)) {
              vm.step = response.data.order_status.step;
            }
            const orderCode = response.data.code
            if (
                vm.isAuth
                && !vm.isShowTreasureHut
                && (vm.$const.ORDERSTATUS_MAPPING_CODE.FINISHED.indexOf(response.data.order_status.code) >= 0 || response.data.is_paid === 1)) {
              const payload = { vm: vm };
              treasureHutAPI.getRewardDetail(orderCode, payload,
                  function (response) {
                    if (response.data !== null) {
                        vm.showTreasureHUTPopup(response.data)
                    }
                  }, function (data) {
                    vm.notificationError(vm, data.message);
                  }
              )
            }
            vm.stopInterval();
          }, function (data) {
            if (!vm.modeCheckStatus) {
              vm.status = 2;
              vm.notificationError(vm, data.message);
            }
            clearInterval(vm.iterValObj);
          }
        )
      },
      isFired (id) {
        let items = JSON.parse(localStorage.getItem('OrderFired')) || [];
        let hasStorage = !(this.emptyVariable(items) || items.indexOf(id) < 0); // not exist in local storage
        let hasCookie = this.getCookie(id);
        return !(hasStorage || hasCookie);
      },
      setFired (id) {
        let items = JSON.parse(localStorage.getItem('OrderFired')) || [];
        let hasCookie = this.getCookie(id);
        if (!hasCookie) {
          this.setCookie(id, id, 30);
        }
        if (this.emptyVariable(items) || items.indexOf(id) < 0) {
          items.push(id);
          localStorage.setItem('OrderFired', JSON.stringify(items));
        }
      },
      convertShowCollectionTime(dateTime, cart) {
        let result = '';
        let timezone = cart.store.timezone || this.$const.TIMEZONE;
        let today = moment(new Date()).tz(timezone).format('YYYY-MM-DD');// timezone
        // let today2 = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');// client time
        // let today3 = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'); //utc time

        let nextday = moment(new Date()).tz(timezone).add(1, 'days').format('YYYY-MM-DD')
        // let orderTime = moment.tz(cart.collection_time, 'YYYY-MM-DD HH:mm:ss', timezone);
        let orderTime = moment.utc(cart.collection_time, 'YYYY-MM-DD HH:mm:ss').tz(timezone);
        let pickedDay = orderTime.format('YYYY-MM-DD')
        let pickedHours = orderTime.format('HH:mm')

        if (!dateTime) {
          result = this.$t('common.asap');
        } else if (pickedDay == today) {
          result = this.$t('today') + ' ' + pickedHours
        } else if (pickedDay == nextday) {
          result = this.$t('tomorrow') + ' ' + pickedHours
        } else {
          result = pickedDay + ' ' + pickedHours
        }
        return result;
      },
      showTreasureHUTPopup(data){
        this.isShowTreasureHut = true
        this.$modal.show(
            ModalTreasureHut, { data }
        )
      },
    }
  }
</script>
<style scoped>
    .process-bar {
        /*counter-reset: step;*/
    }
    .process-bar li{
       list-style: none;
        float: left;
        position: relative;
        width: 25%;
        text-align: center;
        font: normal normal normal 14px/1 FontAwesome;
    }
    .process-bar li:before {
        content: '';
        width: 20px;
        height: 20px;
        line-height: 20px;
        border: 1px solid #cd2642;
        display: block;
        text-align: center;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        background-color: #cd2642;
        position: absolute;
        bottom: -35px;
        left: 42%;
        z-index: 20;
        opacity: 0.5;
    }
    .process-bar li:after {
       content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #cd2642;
        bottom: -16px;
        left: -50%;
        z-index: 1;
        opacity: 0.1;
    }
    .process-bar li:first-child:after {
        content: none;
    }
    .process-bar li.active {
        color: white;
    }
    .process-bar li.active:before,  .process-bar li.highlight:before {
        color: white;
        background-color: #cd2642;
        content: '\f00c';
        opacity: 1;
    }
    .process-bar li.active:after, .process-bar li.highlight:after  {
        background-color: #cd2642;
        opacity: 1;

    }

    .block-total {
        border-top: 1px solid #e9ebee;
    }
    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all 0.8s cubic-bezier(.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all 0.8s cubic-bezier(.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translate(100%, 0);
        opacity: 0;
    }
    .slide-fade-enter
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translate(-100%, 0);
        opacity: 0;
    }
</style>
