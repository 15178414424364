<template>
  <div class=" en-GB app-bg">
      <div class="container mt-20 mb-40">
          <div class="font-open-sans-bold uppercase text-center relative mb-20">
              <h1 class="px-40">{{title}}</h1>
              <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
          </div>
          <div class="p-20 bg-white">
              <div class="md:flex">
                  <div v-html="htmlStr"></div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import ContentApi from '../services/content';
/* eslint-disable */
export default {
  name: "newsroom-detail",
  components: {
  },
  data() {
    return {
      htmlStr: '',
      title: '',
    }
  },
  computed: {
  },
  mounted() {
    console.log(this.$route.params.slug)
    this.getInfo();
  },
  methods: {
    getInfo () {
      var vm  = this;
      let payload = {
        "slug": vm.$route.params.slug,
        "vm": this
      }
      ContentApi.getContent(payload,
        function (response) {
          vm.htmlStr = response.data.content
          vm.title = response.data.name
        }, function (data) {
          vm.notificationError(vm, data.message);
        }
      )
    }
  }
}
</script>
