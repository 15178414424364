<template>
    <div>
<div class="bg-black">
    <div class="container">
        <div class="flex justify-between py-30 flex-wrap">
            <div class="p-20 pb-0">
                <div class="mb-20 ">
                    <h4 class="text-white mb-5 typography-4 uppercase"><span class=""><span>{{$t('common.footer.about_us')}}</span></span></h4>
                    <router-link :to="{name: 'about',params: {'slug': 'brand-story'}}" class="text-white typography-7 block p-4" target="_self"><span class=""><span>{{$t('common.footer.brand_story')}}</span></span></router-link>
                    <router-link :to="{name: 'certificate'}" class="text-white typography-7 block p-4" target="_self"><span class=""><span>{{$t('common.footer.certificate')}}</span></span></router-link>
                    <router-link :to="{name: 'about',params: {'slug': 'csr'}}" class="text-white typography-7 block p-4" target="_self"><span class=""><span>{{$t('common.footer.csr')}}</span></span></router-link>
                    <!-- <router-link :to="{name: 'digital-menu'}" class="text-white typography-7 block p-4" target="_self"><span class=""><span>{{$t('common.menus')}}</span></span></router-link> -->
                    <router-link :to="{name: 'gift-voucher'}" class="text-white typography-7 block p-4" target="_self"><span class=""><span>{{$t('common.footer.gift_voucher')}}</span></span></router-link>
                    <router-link :to="{name: 'stores'}" class="text-white typography-7 block p-4" target="_self"><span class=""><span>{{$t('common.footer.outlet_location')}}</span></span></router-link>
                    <router-link :to="{name: 'newsroom'}" class="text-white typography-7 block p-4" target="_self"><span class=""><span>{{$t('common.footer.newsroom')}}</span></span></router-link>
                </div>
            </div>
            <div class="p-20 pb-0">
                <div v-if="!isAuth" class="mb-20 ">
                    <h4 class="text-white mb-5 typography-4 uppercase"><span class=""><span>{{$t('common.footer.my_account')}}</span></span></h4>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'ph-reward'}"><span class=""><span>{{$t('common.btn.ph_reward')}}</span></span></router-link>
                    <div @click="showLogin(true)" class="text-white typography-7 block p-4 cursor-pointer" target="_self"><span class=""><span>{{$t('common.sign_up')}}</span></span></div>
                    <div @click="showLogin()" class="text-white typography-7 block p-4 cursor-pointer" target="_self"><span class=""><span>{{$t('common.btn.my_voucher')}}</span></span></div>
                    <div @click="showLogin()" class="text-white typography-7 block p-4 cursor-pointer" target="_self"><span class=""><span>{{$t('common.btn.my_point')}}</span></span></div>
                    <div @click="showLogin()" class="text-white typography-7 block p-4 cursor-pointer" target="_self"><span class=""><span>{{$t('common.btn.save_address')}}</span></span></div>
                    <div @click="showLogin()" class="text-white typography-7 block p-4 cursor-pointer" target="_self"><span class=""><span>{{$t('common.btn.save_outlet')}}</span></span></div>
                </div>
                <div v-else class="mb-20 ">
                    <h4 class="text-white mb-5 typography-4 uppercase"><span class=""><span>{{$t('common.footer.my_account')}}</span></span></h4>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'ph-reward'}"><span class=""><span>{{$t('common.btn.ph_reward')}}</span></span></router-link>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'profile'}"><span class=""><span>{{$t('common.btn.my_account')}}</span></span></router-link>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'vouchers'}"><span class=""><span>{{$t('common.btn.my_voucher')}}</span></span></router-link>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'slices'}"><span class=""><span>{{$t('common.btn.my_point')}}</span></span></router-link>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'profile', params: {'slug': 'address'}}"><span class=""><span>{{$t('common.btn.save_address')}}</span></span></router-link>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'profile', params: {'slug': 'outlet'}}"><span class=""><span>{{$t('common.btn.save_outlet')}}</span></span></router-link>
                </div>
            </div>
            <div class="p-20 pb-0">
                <div class="mb-20 ">
                    <h4 class="text-white mb-5 typography-4 uppercase"><span class=""><span>{{$t('common.footer.help_service_policies')}}</span></span></h4>
                    <router-link class="text-white typography-7 block p-4" :to="{name: 'contact-us'}" target="_self" rel="noopener"><span class=""><span>{{$t('common.footer.contact_us')}}</span></span></router-link>
                    <!-- <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'reservation'}"><span class=""><span>{{$t('common.footer.reservation')}}</span></span></router-link> -->
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'faq'}"><span class=""><span>{{$t('common.footer.faq')}}</span></span></router-link>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'privacy'}"><span class=""><span>{{$t('common.footer.privacy')}}</span></span></router-link>
                    <router-link class="text-white typography-7 block p-4" target="_self" :to="{name: 'terms'}"><span class=""><span>{{$t('common.footer.term')}}</span></span></router-link>
                </div>
            </div>
            <div class="p-20 pb-0">
                <div class="mb-20 ">
                    <h4 class="text-white mb-5 typography-4 uppercase"><span class=""><span>{{$t('common.footer.connect_to_us')}}</span></span></h4>
                    <a class="text-white typography-7 block p-4" target="_blank" rel="noopener" href="https://www.facebook.com/pizzahutindonesiaofficial">
                        <span class="">
                            <img style="width:26px;" width="26" height="26" :alt="$t('common.footer.facebook')" class="fa mr-5" src="../assets/images/facebook.png">
                            <span>{{$t('common.footer.facebook')}}</span>
                        </span>
                    </a>
                    <a class="text-white typography-7 block p-4" target="_blank" rel="noopener" href="https://x.com/Pizza_HutID">
                        <span class="">
                            <img style="width:26px;" width="26" height="26" :alt="$t('common.footer.x')" class="fa mr-5" src="../assets/images/x.png">
                            <span>{{$t('common.footer.x')}}</span>
                        </span>
                    </a>
                    <a class="text-white typography-7 block p-4" target="_blank" rel="noopener" href="https://www.youtube.com/user/PizzaHutIndonesia">
                        <span class="">
                            <img style="width:26px;" width="26" height="26" :alt="$t('common.footer.youtube')" class="fa mr-5" src="../assets/images/youtube.png">
                            <span>{{$t('common.footer.youtube')}}</span>
                        </span>
                    </a>
                    <a class="text-white typography-7 block p-4" target="_blank" rel="noopener" href="https://www.instagram.com/pizzahut.indonesia/">
                        <span class="">
                            <img style="width:26px;" width="26" height="26" :alt="$t('common.footer.instagram')" class="fa mr-5" src="../assets/images/instagram.png">
                            <span>{{$t('common.footer.instagram')}}</span>
                        </span>
                    </a>
                    <a class="text-white typography-7 block p-4" target="_blank" rel="noopener" href="https://www.tiktok.com/@pizzahut.indonesia?">
                        <span class="">
                            <img style="width:26px;" width="26" height="26" :alt="$t('common.footer.tiktok')" class="fa mr-5" src="../assets/images/tiktok_logo.jpg">
                            <span>{{$t('common.footer.tiktok')}}</span>
                        </span>
                    </a>
                    <a class="text-white typography-7 block p-4 " target="_self" href="tel:1500600">
                        <span class="">
                            <img style="width:26px;" width="26" height="26" :alt="$t('common.footer.call_us')" class="fa mr-5" src="../assets/images/phone_icon_gray.png">
                            <span>{{$t('common.footer.call_us')}}</span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <div class="flex-center mb-20 qa-ios">
            <a href="https://itunes.apple.com/us/app/pizza-hut-delivery-indonesia/id1252959337?ls=1&mt=8" target="_blank" rel="noopener" class="p-0 mr-20 screenreader-only text-white typography-7 icon-app-store-ios"><span>Download our iOS app</span></a>
            <a href="https://play.google.com/store/apps/details?id=com.pizzahut.phd" target="_blank" rel="noopener" class="p-0 mr-20 screenreader-only text-white typography-7 icon-app-store-google-play"><span>Download our Google Play app</span></a>
            <router-link :to="{name: 'home'}" rel="noopener" class="p-0 mr-20 screenreader-only text-white typography-7 icon-app-store-phd"><span>PHD</span></router-link>
        </div>
        <div class="text-grey pb-5 text-11 flex flex-center flex-row">
            <router-link :to="{name: 'contact-us'}" class="text-yellow copy-right-item border-r"> {{$t('common.footer.contact_us')}}</router-link>
            <router-link :to="{name: 'privacy'}" class="text-yellow copy-right-item border-r"> {{$t('common.footer.privacy')}}</router-link>
            <router-link :to="{name: 'terms'}" class="text-yellow copy-right-item border-r"> {{$t('common.footer.term')}}</router-link>
            <div class="copy-right-item border-r"> <span>Copyright </span><span role="img" aria-label="Copyright">©</span><span> 2017-2021 Pizza Hut Indonesia. All Rights Reserved.</span></div>
            <div class="copy-right-item border-r-0">Photos are for reference only</div>
        </div>
    </div>
</div>
<mobile-footer-component v-if="isMobile()"></mobile-footer-component>
</div>
</template>
<script>
  import types from '../store/mutationTypes'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import MobileFooterComponent from "./MobileFooterComponent";
  import modalLoginRegister from "@/components/modal/ModalLoginRegister";
  import braze from "@/services/braze"
  export default {
      components: {MobileFooterComponent},
      computed: {
      ...mapGetters({
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      }),
      ...mapState({
        listCategories: 'categories'
      })
    },
    methods: {
      ...mapActions ({
        logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
      }),
      showLogin(isRegister = false) {
        if(this.isMobile()) {
          if (isRegister) {
            this.$router.push({name: 'register'})
          } else {
            this.$router.push({name: 'login'})
          }
        } else {
          const vm = this
          this.$modal.show(
              modalLoginRegister,
              {
                resizable: true,
                clickToClose: true,
                isRegister: isRegister,
                callEvent: function () {
                  return  vm.callEvent
                }
              }
          )
        }

      },
      callEvent(){
        braze.customEvent('login', {})
        return true
      }
    }
  }
</script>
