<template>
    <div>
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.profile')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white ">
                    <div class="contain-form m-auto w-full">
                        <div class="block-section">
                            <div class="flex-row flex flex-center">
                                <div class="flex-1">
                                    <div>{{authUser.name}}</div>
                                    <div>{{authUser.email}}</div>
                                    <div><strong>{{$t('common.point')}}:</strong> {{authUser.point}}</div>
<!--                                    <div class="flex">-->
<!--                                        <img src="../assets/images/calendar_black.png" width="25" class="mr-5">-->
<!--                                        <div v-if="monthBirthday === false">-->
<!--                                            <router-link :to="{name: 'update-profile'}">-->
<!--                                                {{$t('common.can_we_have_your_birthday')}}-->
<!--                                            </router-link>-->
<!--                                        </div>-->
<!--                                        <div v-else-if="monthBirthday > 0">{{$t('common.until_birthday', {amount: monthBirthday})}}</div>-->
<!--                                        <div v-else>{{$t('common.is_your_birthday')}}</div>-->
<!--                                    </div>-->

                                </div>
                                <router-link :to="{name: 'update-profile'}" class="text-blue">{{$t('common.btn.change')}}</router-link>
                            </div>
                            <button v-if="isMobile()" class="button button--red flex-1 " style="text-align: center" @click="logout">
                                <div class="flex w-full flex-col"><b><span class=""><span>{{$t('common.logout')}}</span></span></b>
                                </div>
                            </button>
                        </div>

                        <div class="block-section">
                            <div class="text-center title-line line-header"><strong>{{$t('common.save_locations')}}</strong></div>
                            <div  class="flex flex-row group-tab-location flex-center">
                                <div class="flex-1 text-center bold" :class="{'active': mode === 1}"  @click="changeMode(1)"> {{$t('common.delivery_address')}}</div>
                                <div class="flex-1 text-center bold" :class="{'active': mode === 2}" @click="changeMode(2)"> {{$t('common.collection_store')}}</div>
                            </div>
                            <div class="list-item-history delivery-address" v-if="mode === 1">
                                <div class="info-order" v-for="(location, indexL) in locations" :key="indexL">
                                    <div class="header-order">
                                        <div v-on:click="toggleShowLocation(indexL)" class="title-order">{{location.full_address || $t('common.unknown')}} </div>
                                    </div>
                                    <transition name="fade" v-if="showLocations[indexL]">
                                        <div class="body-order ">
                                            <div class="m-10">
                                                <div class="cursor-pointer underline text-red capitalize" @click="deleteLocation(location)">{{$t('common.btn.delete')}}</div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>

                            </div>
                            <div class="list-item-history collection-store" v-if="mode === 2">
                                <div class="info-order" v-for="(location, indexL) in collections" :key="indexL">
                                    <div class="header-order">
                                        <div v-on:click="toggleShowCollections(indexL)" class="title-order">{{location.name || $t('common.unknown')}} </div>
                                    </div>
                                    <transition name="fade" v-if="showCollections[indexL]">
                                        <div class="body-order ">
                                            <div class="m-10">
                                                <div class="cursor-pointer underline text-red capitalize" @click="deleteLocation(location)">{{$t('common.btn.delete')}}</div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>

                            </div>
                        </div>
                        <div class="block-section">
                            <div class="text-center title-line line-header"><strong>{{$t('common.save_orders')}}</strong></div>
                            <div class="list-item-history">
                                <div class="info-order" v-for="(order, indexO) in orders" :key="indexO">
                                    <div class="header-order">
                                        <div v-on:click="toggleShow(indexO)" class="title-order"> {{order.orderTimeText}}</div>
                                    </div>
                                    <transition name="fade" v-if="shows[indexO]">
                                        <div class="body-order ">
                                            <div class="m-10">
                                                <div class="bold">{{$t('orderConfirm.order', {no: order.code})}}</div>
                                                <div class="text-orange ">
                                                    {{$t('orderConfirm.total')}}:  {{$t('common.format_currency', {amount: formatCurrency(order.total, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                                </div>
                                            </div>
                                            <div class="flex-1 m-10">
                                                <div class="block-cart-detail">
                                                    <div class="block-cart-item " v-for="(item, indext) in order.details" :key="indext">
                                                        <div  v-if="item.is_combo != 1">
                                                            <div class="flex">
                                                                <div class="flex-1 bold text-orange">
                                                                    <span v-if="item.quantity > 1"> {{item.quantity}} x </span> <span v-if="item.product.option_group"> <span v-if="item.product.option_group && item.product.option_group.option"> {{item.product.option_group.option.name}} </span></span> {{item.product.name}}
                                                                </div>
                                                                <!--<div>
                                                                    {{$t('common.format_currency', {amount: formatCurrency(item.sub_price * item.quantity, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                                                </div>-->
                                                            </div>
                                                            <div v-if="item.product.option_group" class="flex ml-10 text-grey">
                                                                <div class="flex-1  bold">
                                                                    <span>{{item.product.option_group.name}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div class="flex">
                                                                <div class="flex-1 bold text-orange">
                                                                    <span v-if="item.quantity > 1"> {{item.quantity}} x </span> {{item.product.name}}
                                                                </div>
                                                                <!--<div>
                                                                    {{$t('common.format_currency', {amount: formatCurrency(item.sub_price * item.quantity, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                                                </div>-->
                                                            </div>
                                                            <div v-for="(menu,indexmenu) in item.combo_products" :key="indexmenu" class="pl-5">
                                                                <div class="flex">
                                                                    <div class="flex-1 bold ">
                                                                        <span v-if="menu.option_group"><span v-if="menu.option_group && menu.option_group.option">{{menu.option_group.option.name}}</span></span> {{menu.name}}
                                                                    </div>
                                                                </div>
                                                                <div v-if="menu.option_group" class="flex ml-10 text-grey">
                                                                    <div class="flex-1  bold">
                                                                        <span>{{menu.option_group.name}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex-1 m-10">
                                                <div class="bold">
                                                    {{
                                                    order.order_type.code.toLowerCase() == $const.ORDERTYPE.DELIVERY.toLowerCase() ? $t('orderConfirm.delivery_to', {storeName : order.store.name}) : $t('orderConfirm.carryout_from', {storeName : order.store.name})
                                                    }}
                                                </div>
                                                <div>
                                                    {{
                                                    order.order_type.code.toLowerCase() == $const.ORDERTYPE.DELIVERY.toLowerCase() ? order.delivery.full_address : order.store.localtion
                                                    }}
                                                </div>
                                                <div v-if="order.order_type.code.toLowerCase() == $const.ORDERTYPE_MAPAPI.DELIVERY.toLowerCase() && order.contact_less == '1'">
                                                    <strong>{{$t('common.contact_less')}}</strong>
                                                </div>
                                            </div>

                                            <div class="flex-1 m-10 flex">
                                                <button class="button button--red m-5 " @click="reorder(order)"><span class="absolute inset-0 flex-center"><span>{{$t('common.btn.reorder')}}</span></span></button>
                                                <router-link class="button button--red m-5" :to="'/order/' + defaultCategory"><span class="absolute inset-0 flex-center"><span>{{$t('common.back_menu')}}</span></span></router-link>
                                            </div>
                                        </div>
                                    </transition>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import CustomerApi from '../services/customer';
  import OrderApi from '../services/order';
  import CartrApi from '../services/cart';
  import types from '../store/mutationTypes'
  import moment from "moment";
  import { mapActions, mapGetters } from 'vuex'

  /* eslint-disable */
  export default {
    name: "profile",
    components: {
      HeaderComponent,
    },
    data() {
     return {
       shows: [],
       showLocations: [],
       showCollections: [],
       orders: [],
       locations: [],
       collections: [],
       mode: 1,
       monthBirthday: false
     }
    },
    computed: {
      ...mapGetters({
        authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
        defaultCategory:  types.GET_DEFAULT_CATEGORY,
      })
    },
    mounted() {
      this.authUser;
      this.getProfileByToken();
      this.ordersHistory();
      this.getLocations();
      this.calculatorBirthday();
      this.getProfileSlug();
    },
    methods: {
      ...mapActions({
        saveLocalisation:  'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
        setCartData:  'moduleCart/' + types.CART_SET_DATA,
        logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
        getProfileByToken: 'moduleAuth/' + types.CUSTOMER_GET_INFO_BY_TOKEN,
      }),
      calculatorBirthday () {
        let date = this.authUser.birthday;
        if (!this.emptyVariable(date) && date !== '0000-00-00') {
          let monthCur = (new moment()).get("month");
          let monthBirth = (new moment(date, 'YYYY-MM-DD')).get("month");
          let year  = (new moment()).get("year");
          let yearBirthday = (monthBirth >= monthCur) ? year : year + 1;
          let DateCur  = moment({ year: year, month: monthCur, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0});
          let birthday  = moment({ year: yearBirthday, month: monthBirth, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0});
          this.monthBirthday = birthday.diff(DateCur, 'months');
          return false;
        }
        this.monthBirthday = false;
      },
      changeMode(type) {
        this.mode = type;
      },
      toggleShow(indexO) {
        this.$set(this.shows, indexO, !this.shows[indexO])
      },
      toggleShowLocation(indexL) {
        this.$set(this.showLocations, indexL, !this.showLocations[indexL])
      },
      toggleShowCollections(indexL) {
        this.$set(this.showCollections, indexL, !this.showCollections[indexL])
      },
      initShowArray () {
        this.shows = [];
        for (let i=0; i< this.orders.length; i++){
          let timezone = this.orders[i].store.timezone || this.$const.TIMEZONE;
          let orderTime = moment.tz(this.orders[i].order_time, 'YYYY-MM-DD HH:mm:ss', timezone);
          this.orders[i].orderTimeText = orderTime.format('YYYY-MM-DD HH:mm');
          this.shows.push(false);
        }
      },
      initShowLocationArray () {
        this.showLocations = [];
        for (let i=0; i< this.locations.length; i++){
          this.showLocations.push(false);
        }
        this.showCollections = [];
        for (let j=0; j< this.collections.length; j++){
          this.showCollections.push(false);
        }
      },
      ordersHistory () {
        var vm = this;
        OrderApi.orders({vm: this}, function (response) {
          vm.orders = response.data;
          vm.initShowArray();
        })
      },
      getLocations () {
        var vm = this;
        CustomerApi.getLocations({vm: this, store_type_id: this.$const.TYPE_HUT.PHD}, function (response) {
          vm.locations = response.data.delivery_addresses;
          vm.collections = response.data.collection_stores;
          vm.initShowLocationArray();
        })
      },
      deleteLocation (location) {
        var vm = this;
        CustomerApi.deleteLocation({vm: this, uuid: location.uuid}, function (response) {
          vm.notificationSuccess(vm, vm.$t('message.success.delete'));
          vm.getLocations();
        }, function (error) {
          vm.notificationError(vm, vm.$t('message.error.delete'));
        })
      },
      reorder (order) {
        var vm = this;
        CartrApi.reorder({vm: this, cart_uuid: order.cart_uuid}, function (response) {
          vm.setCartData({payload: response.data});
          vm.handleLocalisation(order);
        }, function (error) {
          vm.notificationError(vm, error.message);
        })
      },
      handleLocalisation (order) {
        if (order.order_type.code === this.$const.ORDERTYPE.DELIVERY) {
          this.checkDelivery(order);
        } else {
          this.checkCarryout(order);
        }
      },
      checkDelivery (order) {
        let storeDataNew = {
          "clientUUID": order.store.client_uuid || '', //
          "code": order.store.code || '',
          "distance": 0, //
          "lat": order.delivery.lat,
          "location": order.delivery.full_address || '',
          "addressStore": order.store.localtion, // ??
          "long": order.delivery.long,
          "name": order.store.name,
          "tierUUID": order.store.tier_uuid || null, //
          "uuid": order.store.uuid,
          "timezone": order.store.timezone,
          "deliveryInfo": {
            city: '',
            buildingName: order.delivery.address_level_0,
            streetNumber: order.delivery.address_level_1,
            streetName: order.delivery.address_level_2,
            state: order.delivery.address_level_3,
            postalCode: order.delivery.postal_code,
          },
          "businessHours": [],
          "orderType": this.$const.ORDERTYPE.DELIVERY,
          "orderNow": 1,
          "collection_time": '',
          "collectionTime" : ''
        }
        this.saveLocalisation(storeDataNew);
        this.notificationSuccess(this, this.$t('message.success.reorder'));
        this.$router.push('/order/' + this.defaultCategory);
      },
      checkCarryout (order) {
        let storeDataNew = {
          "clientUUID": order.store.client_uuid || '', //
          "code": order.store.code || '',
          "distance": 0, //
          "lat": order.store.lat,
          "location": order.store.localtion || '',
          "addressStore": order.store.localtion, // ??
          "long": order.store.long,
          "name": order.store.name,
          "tierUUID": order.store.tier_uuid || null, //
          "uuid": order.store.uuid,
          "timezone": order.store.timezone,
          "deliveryInfo": {
            city: '',
            buildingName: '',
            streetNumber: '',
            streetName: '',
            state: '',
            postalCode: '',
          },
          "businessHours": [],
          "orderType": this.$const.ORDERTYPE.CARRYOUT,
          "orderNow": 1,
          "collection_time": '',
          "collectionTime" : ''
        }
        this.saveLocalisation(storeDataNew);
        this.notificationSuccess(this, this.$t('message.success.reorder'));
        this.$router.push('/order/' + this.defaultCategory);
      },
        getProfileSlug(){
          if(this.$route.params.slug == 'outlet'){
              this.mode = 2;
          }
        }
    }
  }
</script>
<style>
    .group-tab-location div{
        cursor: pointer;
        color: #31708f;
        background-color: #c0d1da;
        border-color: #c0d1da;
        padding: 5px 15px;
        border-bottom: 1px solid transparent;
        /*border-top-left-radius: 3px;*/
        /*border-top-right-radius: 3px;*/
    }
    .group-tab-location div.active {
        background-color: #a2b7bb;
    }
    .list-item-history .info-order {
        background-color: #fff;
        border: 1px solid #e9ebee;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
        margin-bottom: 0;
        border-radius: 4px;
        margin-top: 10px;
    }
    .list-item-history .body-order {
        padding: 15px;
    }
    .list-item-history .header-order {
        cursor: pointer;
        color: #31708f;
        background-color: #e9ebee;
        border-color: #e9ebee;
        padding: 5px 15px;
        border-bottom: 1px solid transparent;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    .list-item-history .header-order .title-order {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
        color: inherit;
    }

</style>
