/**Localisation type**/
const LOCALISATION_SAVE_STORE = 'LOCALISATION_SAVE_STORE'
const LOCALISATION_RESET_STORE = 'LOCALISATION_RESET_STORE'
const LOCALISATION_GET_STORE_UUID = 'LOCALISATION_GET_STORE_UUID'
const LOCALISATION_GET_STORE_TIMEZONE = 'LOCALISATION_GET_STORE_TIMEZONE'
const LOCALISATION_GET_STORE_NAME = 'LOCALISATION_GET_STORE_NAME'
const LOCALISATION_GET_ORDER_TYPE = 'LOCALISATION_GET_ORDER_TYPE'
const LOCALISATION_GET_TABLE_NUMBER = 'LOCALISATION_GET_TABLE_NUMBER'
const LOCALISATION_GET_ORDER_NOW = 'LOCALISATION_GET_ORDER_NOW'
const LOCALISATION_GET_COLLECTION_TIME = 'LOCALISATION_GET_COLLECTION_TIME'
const LOCALISATION_GET_LOCATION = 'LOCALISATION_GET_LOCATION'
const LOCALISATION_GET_LOCALISATION = 'LOCALISATION_GET_LOCALISATION'
/* Cart type*/
const CART_DETAIL = 'CART_DETAIL'
const CART_RESET_DATA = 'CART_RESET_DATA'
const CART_SET_DATA = 'CART_SET_DATA'
const CART_SAVE_ITEM = 'CART_SAVE_ITEM'
const CART_GET_CART_DETAIL = 'CART_GET_CART_DETAIL'
const CART_GET_CART_CHECKOUT_DETAIL = 'CART_GET_CART_CHECKOUT_DETAIL'
const CART_UPDATE_QUANTITY = 'CART_UPDATE_QUANTITY'
const CART_REMOVE_COUPON = 'CART_REMOVE_COUPON'
const CART_REMOVE_VOUCHER = 'CART_REMOVE_VOUCHER'
const CART_UPDATE_ITEM = 'CART_UPDATE_ITEM'
const CART_UPDATE_CART = 'CART_UPDATE_CART'
const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
const CART_GET_CART_UUID = 'CART_GET_CART_UUID'
const CART_COUNT = 'CART_COUNT'
const CART_BEFORE_CHECKOUT_COUNT = 'CART_BEFORE_CHECKOUT_COUNT'
const CART_GRANT_TOTAL = 'CART_GRANT_TOTAL'
const CART_SET_SUGGEST = 'CART_SET_SUGGEST'
const CART_GET_SUGGESTIONS = 'CART_GET_SUGGESTIONS'
const CART_GET_PROMOTIONS = 'CART_GET_PROMOTIONS'
const CART_GET_TOTAL = 'CART_GET_TOTAL'
const CART_GET_SUBTOTAL = 'CART_GET_SUBTOTAL'

const SET_LANG = 'SET_LANG'

const SET_MENU_CATEGORY_LIST = 'SET_MENU_CATEGORY_LIST'
const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY'
const SET_DEFAULT_CATEGORY = 'SET_DEFAULT_CATEGORY'
const GET_DEFAULT_CATEGORY = 'GET_DEFAULT_CATEGORY'
const GET_CUSTOMER_TEMP = 'GET_CUSTOMER_TEMP'
const SET_CUSTOMER_TEMP = 'SET_CUSTOMER_TEMP'
const GET_VISITOR_ID = 'GET_VISITOR_ID'
const SET_VISITOR_ID = 'SET_VISITOR_ID'
const GET_DEEP_LINK = 'GET_DEEP_LINK'
const SET_DEEP_LINK = 'SET_DEEP_LINK'
const GET_COORDINATES = 'GET_COORDINATES'
const SET_COORDINATES = 'SET_COORDINATES'

const CUSTOMER_SET_INFO = 'CUSTOMER_SET_INFO'
const CUSTOMER_GET_INFO = 'CUSTOMER_GET_INFO'
const CUSTOMER_SET_TOKEN = 'CUSTOMER_SET_TOKEN'
const CUSTOMER_GET_INFO_BY_TOKEN = 'CUSTOMER_GET_INFO_BY_TOKEN'
const CUSTOMER_IS_AUTH = 'CUSTOMER_IS_AUTH'
const CUSTOMER_LOGOUT = 'CUSTOMER_LOGOUT'

const IN_CREATE_LOADING = 'IN_CREATE_LOADING'
const DE_CREATE_LOADING = 'DE_CREATE_LOADING'
const IS_SHOW_LOADING = 'IS_SHOW_LOADING'
const HIDE_LOADING = 'HIDE_LOADING'

/* Order type*/
const ORDER_SAVE_ITEM = 'ORDER_SAVE_ITEM'
const ORDER_RESET_ITEM = 'ORDER_RESET_ITEM'
const ORDER_GET_ORDER_UUID = 'ORDER_GET_ORDER_UUID'
const ORDER_GET_ORDER_CODE = 'ORDER_GET_ORDER_CODE'
const ORDER_GET_ORDER_STATUS_UUID = 'ORDER_GET_ORDER_STATUS_UUID'
const ORDER_GET_ORDER_STATUS_CODE = 'ORDER_GET_ORDER_STATUS_CODE'
const ORDER_GET_COLLECTION_TIME = 'ORDER_GET_COLLECTION_TIME'
const ORDER_GET_ORDER_INFORMATION = 'ORDER_GET_ORDER_INFORMATION'
const ORDER_GET_ORDER_TOTAL = 'ORDER_GET_ORDER_TOTAL'

const SET_CURRENT_KEYWORD_SEARCH = 'SET_CURRENT_KEYWORD_SEARCH'

export default {
    LOCALISATION_SAVE_STORE,
    LOCALISATION_RESET_STORE,
    LOCALISATION_GET_STORE_UUID,
    LOCALISATION_GET_STORE_NAME,
    LOCALISATION_GET_ORDER_TYPE,
    LOCALISATION_GET_TABLE_NUMBER,
    LOCALISATION_GET_ORDER_NOW,
    LOCALISATION_GET_COLLECTION_TIME,
    LOCALISATION_GET_LOCATION,
    LOCALISATION_GET_LOCALISATION,
    LOCALISATION_GET_STORE_TIMEZONE,
    SET_MENU_CATEGORY_LIST,
    SET_CURRENT_CATEGORY,
    SET_LANG,
    CART_SAVE_ITEM,
    CART_SET_DATA,
    CART_DETAIL,
    CART_REMOVE_COUPON,
    CART_REMOVE_VOUCHER,
    CART_GET_CART_DETAIL,
    CART_GET_CART_CHECKOUT_DETAIL,
    CART_REMOVE_ITEM,
    CART_UPDATE_ITEM,
    CART_UPDATE_CART,
    CART_RESET_DATA,
    CART_GET_CART_UUID,
    CART_GRANT_TOTAL,
    CART_COUNT,
    CART_BEFORE_CHECKOUT_COUNT,
    CART_UPDATE_QUANTITY,
    CUSTOMER_SET_INFO,
    CUSTOMER_SET_TOKEN,
    CUSTOMER_IS_AUTH,
    CUSTOMER_LOGOUT,
    CUSTOMER_GET_INFO,
    CUSTOMER_GET_INFO_BY_TOKEN,
    IN_CREATE_LOADING,
    DE_CREATE_LOADING,
    IS_SHOW_LOADING,
    HIDE_LOADING,
    SET_DEFAULT_CATEGORY,
    GET_DEFAULT_CATEGORY,
    SET_CUSTOMER_TEMP,
    GET_CUSTOMER_TEMP,
    GET_VISITOR_ID,
    SET_VISITOR_ID,
    GET_DEEP_LINK,
    SET_DEEP_LINK,
    GET_COORDINATES,
    SET_COORDINATES,
    CART_SET_SUGGEST,
    CART_GET_SUGGESTIONS,
    CART_GET_PROMOTIONS,
    CART_GET_TOTAL,
    CART_GET_SUBTOTAL,
    ORDER_SAVE_ITEM,
    ORDER_RESET_ITEM,
    ORDER_GET_ORDER_UUID,
    ORDER_GET_ORDER_CODE,
    ORDER_GET_ORDER_STATUS_UUID,
    ORDER_GET_ORDER_STATUS_CODE,
    ORDER_GET_COLLECTION_TIME,
    ORDER_GET_ORDER_INFORMATION,
    ORDER_GET_ORDER_TOTAL,
    SET_CURRENT_KEYWORD_SEARCH,
}
