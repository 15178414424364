<template>
    <div
        v-if="isValidImg"
        @click="redeemCoupon"
        class="list-item product-list--deals product-list--coupons"
        :class="cssAddtional"
    >
        <div class="list-item__image "><img :src="coupon.thumbnail" :alt="coupon.name" width="674"></div>
        <p class="typography-4 list-item__name flex-1 ">{{coupon.name}}</p>
        <div class="typography-6 list-item__desc flex-1 pb-10" style="color: #313131">
          <p style="font-size: 12px">{{'Valid until ' + coupon.end_date}}</p>
          <p style="font-size: 12px">{{ this.convertOrderType(coupon.promotion_types) }}</p>
        </div>
<!--        <div v-if="isValidVoucher" class="mx-10 py-10 flex items-baseline justify-end border-t pt-5">-->
<!--            <button class="button button&#45;&#45;red w-full" @click="redeemCoupon">-->
<!--                {{$t('common.use')}}-->
<!--            </button>-->
<!--        </div>-->
    </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import mutationTypes from "@/store/mutationTypes";
  import cartAPI from '@/services/cart';
  import types from "@/store/mutationTypes";
  import moment from "moment-timezone";
  import coupon from '@/services/coupon';

  export default {
    props:["coupon"],
    async created() {
      this.orderTypeID = this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, 0)]
      this.storeTypeID =  this.orderLocation.storeType
      if ((this.orderTypeID > 0 && this.coupon.promotion_types.indexOf(this.orderTypeID) < 0) ||
          (this.storeTypeID > 0 && this.coupon.store_type_ids.indexOf(this.storeTypeID) < 0) || 
          this.coupon.unavailable
      ) {
        this.cssAddtional = ' gray-out'
        this.isValidVoucher = false
      }
      this.coupon.end_date = moment( this.coupon.end_date).format('DD MMM YYYY')
      this.isValidImg = await this.imageExists(this.coupon.thumbnail)
    },
    data () {
      return {
        isValidImg: true,
        storeTypeID: 0,
        orderTypeID: 0,
        isValidVoucher: true,
        cssAddtional: 'cursor-pointer'
      }
    },
    methods: {
      ...mapActions({
        getCartDetail: 'moduleCart/' + mutationTypes.CART_GET_CART_DETAIL,
        setCartDetail: 'moduleCart/' + mutationTypes.CART_SET_DATA,
      }),
      convertOrderType(orderTypes) {
        let str = []
        if (orderTypes.indexOf(this.$const.ORDER_TYPE_MAPPING.DELIVERY) >= 0) {
            str.push('Delivery')
        }
        if ( orderTypes.indexOf(this.$const.ORDER_TYPE_MAPPING.CARRYOUT) >= 0) {
          str.push('Take away')
        }
        if (orderTypes.indexOf(this.$const.ORDER_TYPE_MAPPING.DINEIN) >= 0) {
          str.push('Dine in')
        }
        return str.join(" | ")
      },
      createCart () {
        var vm = this;
        let dataProduct = {
          "cart_uuid": this.cartUuid || '',
          "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
          "items": [],
          "now_order": this.orderNow,
          "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
          "store_uuid": this.storeUuid,
          "loyalty_id": this.visitorID,
        }

        cartAPI.addCart(dataProduct, response => {
          vm.setCartDetail({payload: response.data});
          vm._redeemCoupon(response.data.information.cart_uuid);
        }, error => {
          vm.notificationError(vm, error.message);
        })
      },
      _redeemCoupon ( uuidCart = '') {
        let params = {
          "cart_uuid": uuidCart,
          "code": this.coupon.code || '',
          "vm": this
        }

        if (!this.emptyVariable(this.$route.query) && this.$route.query.percentage_cash == 1) {
          params.is_discount = 1
        }

        if (this.orderUuid) {
          params.order_uuid = this.orderUuid
        }

        var vm = this;
        cartAPI.redeem(params, function (response) {
          if (response.data.status === 0) {
            let proUuid = ''
            let isCombo = false;
            if (response.data.products && response.data.products.length > 0) {
              proUuid = response.data.products[0].uuid;
              if (response.data.products[0].is_combo == 1) {
                isCombo = true;
              }
            }
            if (isCombo) {
              return  vm.$router.push({name: 'combo-detail', params: {uuid: proUuid, code: vm.coupon.code}})
            }
            return vm.$router.push({name: 'redeem', params: {code: vm.coupon.code, uuid: proUuid}});
          }
          vm.notificationSuccess(vm, vm.$t('message.success.redeem_coupon_success'));
          vm.getCartDetail({payload: {uuid: vm.cartUuid}});
          if (vm.$route.query.percentage_cash) {
            return vm.$router.replace({name: 'payment'})
          }
        }, function (error) {
          vm.notificationError(vm, error.message);
        });
      },
      redeemCoupon() {
        if (!this.isValidVoucher) {
          return
        }
        if (!this.storeUuid) {
          this.$notify({
            type: 'warning',
            title: this.$t('message.error.choose_localisation_first')
          });
          let path = this.$route.fullPath.indexOf('/') !== 0 ? this.$route.fullPath : this.$route.fullPath.substr(1);
          this.$router.push({name: 'home', query: {warning: '1', callback: path}});
          return false;
        }
        if (!this.cartUuid) {
          return this.createCart();
        }
        this._redeemCoupon(this.cartUuid);
      }
    },
    computed: {
      ...mapGetters ({
        orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
        orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
        storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
        orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
        timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
        cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
        orderLocation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
        localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
        orderUuid: 'moduleOrder/' + types.ORDER_GET_ORDER_UUID,
        visitorID:  types.GET_VISITOR_ID,
      })
    }
}
</script>

<style scoped>
.gray-out {
  background-color: #9c9fa7;
  opacity: 0.8;
}
</style>

