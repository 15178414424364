import types from '../../mutationTypes'

export default {
    [types.ORDER_GET_ORDER_UUID]: state => state.order.order_uuid,
    [types.ORDER_GET_ORDER_CODE]: state => state.order.order_code,
    [types.ORDER_GET_ORDER_STATUS_CODE]: state => state.order.order_status_code,
    [types.ORDER_GET_ORDER_STATUS_UUID]: state => state.order.order_status_uuid,
    [types.ORDER_GET_COLLECTION_TIME]: state => state.order.collection_time,
    [types.ORDER_GET_ORDER_INFORMATION]: state => state.order.order_information,
    [types.ORDER_GET_ORDER_TOTAL]: state => state.order.order_information.total,
}
