export default {
  init() {
    window.braze.initialize(process.env.VUE_APP_BRAZE_API_KEY, {
      baseUrl: process.env.VUE_APP_BRAZE_ENDPOINT
    })
    window.braze.automaticallyShowInAppMessages()
  },
  changeUser(userIdentifier) {
    window.braze.changeUser(userIdentifier)
    window.braze.openSession()
    if (window.braze.isPushSupported()) {
      window.braze.requestPushPermission()
    }
  },
  setEmail(email) {
    window.braze.getUser().setEmail(email)
  },
  setPhone(phone) {
    window.braze.getUser().setPhoneNumber(`+${phone}`)
  },
  clearData() {
    window.braze.wipeData()
  },
  customEvent(event, payload) {
    window.braze.logCustomEvent(event, payload)
  }
}