<template>
    <div>
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{store ? store.name: ''}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="section-contact-us pb-20">
                        <div class="text-center title-line line-header t17 uppercase"><strong>{{$t('common.contact_us')}}</strong></div>
                        <div class="text-center">
                            <div v-if="store.phone"><strong>{{$t('common.phone')}}:</strong> {{store.phone}}</div>
                            <div><strong>{{$t('common.address')}}:</strong> {{store.location}} </div>
                            <div class="flex flex-wrap flex-center p-10 box-store">
                                <div class="p-10" v-html="$t('common.store_description', {name: store.name})">
                                </div>
                                <router-link class="button button--red flex-1  px-20 " style="line-height: 29px" :to="{name: 'home'}">
                                    <div class="flex w-full flex-col py-10"><b><span class=""><span>{{$t('common.order_now')}}</span></span></b>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="section-product-promotion ">
<!--                        <div class="text-center title-line line-header t17 uppercase"><strong>{{$t('common.popular_deals')}}</strong></div>-->
<!--                        <div class="">-->
<!--                            <carousel-deals v-if="products"-->
<!--                                :items="products"-->
<!--                                :settings="carouselSettings">-->
<!--                            </carousel-deals>-->
<!--                        </div>-->
                        <button class="button button--red text-center mt-10 px-20" style="line-height: 29px" @click="$router.push({name: 'category', params: {slug: defaultCategory}})">
                            <span class="flex w-full flex-col py-10"><b><span class="">{{$t('common.view_all_deals')}}</span></b>
                            </span>
                        </button>
                    </div>
                    <div class="section-opening-hour ">
                        <div class="text-center title-line line-header t17  pt-10 uppercase"><strong>{{$t('common.oping_hour')}}</strong></div>
                        <div class="">
                            <div class=" flex flex-wrap">
                                <div class="hour-item"></div>
                                <div class="hour-item capitalize bold">{{$t('common.pickup')}}</div>
                                <div class="hour-item capitalize bold">{{$t('common.delivery')}}</div>
                            </div>
                            <div v-for="(itemH, indexH) in sortOpeningHours" :key="indexH" class=" flex flex-wrap">
                                <div class="hour-item">{{itemH.name}}</div>
                                <div class="hour-item">{{itemH.carryout}}</div>
                                <div class="hour-item">{{itemH.delivery}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import HeaderComponent from "../components/HeaderComponent";
  import localizationAPI from '../services/localization';
  import menuAPI from '../services/menu';
  import CarouselDeals from '../components/shared/CarouselDeals';
  import {  mapGetters, mapState } from 'vuex'
  import types from '../store/mutationTypes'


  export default {
    name: "store-detail",
    components: {HeaderComponent, CarouselDeals},
    computed: {
      ...mapState({
        listCategories: 'categories'
      }),
      ...mapGetters ({
        orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
        defaultCategory:  types.GET_DEFAULT_CATEGORY,
      }),
      sortOpeningHours: function () {
        let results = []
        let days = ['holiday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        for (let day of days) {
          for (let i of this.openingHours) {
            if (i.name.toLowerCase() === day && (i.delivery != '' || i.carryout != '')) {
              results.push(i);
            }
          }
        }
        return results
      }
    },
    data() {
      return {
        store: {},
        carouselSettings: {
          autoplay: true,
          items_per_set: "3",
          autoplay_speed: "4000",
          items_to_scroll: "1",
          infinite_scrolling: true,
          show_pagination_dots: false,
          show_pagination_arrows: true
        },
        openingHours: [],
        products: []
      }
    },
    mounted() {
      this.getDetail(this.$route.params.slug);
    },
    methods: {
      _getMenuListByCategory(_categories, store) {
        let categoryUuids = [];
        for (let i of _categories) {
          if ([this.$const.CATEGORY.COMBOX, this.$const.CATEGORY.DEAL].indexOf(i.slug) >= 0) {
            categoryUuids.push(i.uuid);
          }
        }

        if (categoryUuids.length <= 0) {
          return;
        }

        this.products = [];
        for (let i of categoryUuids) {
          let params = {
            type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
            category_uuid: i,
            store_uuid: store.uuid,
            vm: this
          }
          this.getProducts(params);
        }
      },
      getMenuListByCategory(store) {
        if (this.emptyVariable(store)) {
          return;
        }
        let _categories = this.listCategories;
        var vm = this;
        if (_categories.length <= 0) {
          let payload = {
            type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
            store_type_id: this.$const.TYPE_HUT.PHD,
          }
          menuAPI.categories(payload, function (response) {
            _categories = response.data;
            vm._getMenuListByCategory(_categories, store);
          }, function (e) {
          });
        } else {
          this._getMenuListByCategory(_categories, store);
        }
      },
      getProducts (params) {
        var vm = this;
        menuAPI.getProducts(params, function (response) {
          if (response.data != null && response.data.length > 0) {
            for (let item of response.data) {
              if (!vm.emptyVariable(item.products) && item.products.length > 0) {
                vm.products = vm.products.concat(item.products);
              }
            }
          }
        })
      },
      getDetail (slug) {
        var vm  = this;
        localizationAPI.storeDetail({uuid: slug, vm: this}, function (response) {
          vm.store = response.data;
          vm.initOpeningHour();
          vm.getMenuListByCategory(vm.store);
        }, function (error) {
          vm.notificationError(vm, error.message);
        });
      },
      initOpeningHour () {
        let hours = [];
        for ( let i in this.store.opening_timings) {
          let item = this.store.opening_timings[i];
          let timezone = this.store.timezone;
          let hourOC = '';
          let hourOD = '';
          let hourCD = '';
          let hourCC = '';
          for (let j in item.open) {
            let open = item.open[j];
            if (open.channel === this.$const.CHANNEL.ONLINE) {
              if (open.type === this.$const.ORDER_TYPE_MAPPING.DELIVERY) {
              hourOD = open.value;
            }else if (open.type === this.$const.ORDER_TYPE_MAPPING.CARRYOUT) {
              hourOC = open.value;
            }
            }
          }
          for (let c in item.close) {
            let close = item.close[c];
            if (close.channel === this.$const.CHANNEL.ONLINE) {
              if (close.type === this.$const.ORDER_TYPE_MAPPING.DELIVERY) {
                hourCD = close.value;
              }else if (close.type === this.$const.ORDER_TYPE_MAPPING.CARRYOUT) {
                hourCC = close.value;
              }
            }
          }
           let day = i == 'hol'? 'mon' : i;
          hourOD = this.getOpeningTime(day, hourOD, timezone);
          hourOC = this.getOpeningTime(day, hourOC, timezone);
          hourCD = this.getOpeningTime(day, hourCD, timezone);
          hourCC = this.getOpeningTime(day, hourCC, timezone);
          let name = '';
          let deliveryText = [];
          let carryoutText = [];
          if (hourOD) {
            name = hourOD.name;
            deliveryText.push(hourOD.value);
          }
          if (hourOC) {
            name = hourOC.name;
            carryoutText.push(hourOC.value);
          }
          if (hourCD) {
            name = hourCD.name;
            deliveryText.push(hourCD.value);
          }
          if (hourCC) {
            name = hourCC.name;
            carryoutText.push(hourCC.value);
          }
          if (i === 'hol') {
            name = this.$t('common.holiday');
          }
          hours.push({
            name: name,
            delivery: deliveryText.join(' - '),
            carryout: carryoutText.join(' - '),
          })
        }
        this.openingHours = hours;
      }
    }
  }
</script>

<style scoped>
    .box-store{
        border: .5px solid #d3d3d3;
        border-radius: 5px;
    }
    .hour-item{
        margin: 6px;
        -ms-flex-preferred-size: calc(33.33% - 12px);
        flex-basis: calc(33.33% - 12px);
        max-width: calc(33.33% - 12px);
    }
</style>
