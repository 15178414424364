<template>
    <div>
        <img src="https://lp.adnear.net/ct.gif?a=landingpage" width="1" height="1" />
        <div class="app en-GB app-bg">
            <div class="order">
                <div class="grid" style="padding-bottom: 125px;">
                    <header-order></header-order>
                    <div class="flex lg:flex-col">
                        <navigation></navigation>
                        <div class="block-combo-contain">
                            <div class="bg-white border-bottom back-to-deal-link">
                                <div class="container p-10">
                                    <router-link :to="{name: 'category', params: {'slug': backCategory}}" class="underline">
                                        <span class="flex-1"><i class="ml-5 icon-caret-left h14 flex-1"></i></span>
                                        <span class="flex-1">{{$t('common.back_to_deals')}}</span>
                                    </router-link>
                                </div>
                            </div>
                            <div class="bg-white">
                                <div class="container p-10 flex ">
                                    <div class="combo-detail-info flex-2">
                                        <div class="product-name">{{product.name}}</div>
                                        <div v-html="product.description"></div>
                                    </div>
                                    <div class="combo-detail-image flex-1">
                                        <img :src="product.thumbnail" :alt="product.name" :title="product.name" class="m-auto">
                                    </div>
                                </div>
                            </div>
                            <div class="container flex flex-col" v-if="groupDetails.length >0">
                                <div class="block-combo-groups">
                                    <div class="block-combo-group animation-pop-in" :class="{'completed': groupDetails[indexg] && !emptyVariable(groupDetails[indexg].info)}" v-for="(group, indexg) in product.groups" :key="indexg">
                                        <div class="block-group-detail-info cursor-pointer" @click="toggleGroup(indexg)">
                                            <div class="group-title">
                                                {{indexg + 1}}. <span> {{group.name}}</span>
                                            </div>
                                            <div class="block-menu-selected flex" v-if="!emptyVariable(groupDetails[indexg].info)">
                                                <div class="menu-name flex-1">
                                                    <span>{{groupDetails[indexg].info.product.name}}</span>
                                                    <span v-if="groupDetails[indexg].info.size && !emptyVariable(groupDetails[indexg].info.size)"> {{groupDetails[indexg].info.size.name}}</span>
                                                    <span v-if="groupDetails[indexg].info.crust && !emptyVariable(groupDetails[indexg].info.crust)"> - {{groupDetails[indexg].info.crust.name}}</span>
                                                </div>
                                                <div class="menu-price flex-1 text-right" v-if="groupDetails[indexg].info.total > 0">{{$t('common.format_currency', {amount: formatCurrency(groupDetails[indexg].info.total, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</div>
                                                <div v-if="!product.is_combo_half"><img alt="" v-if="groupDetails[indexg].info.hasOption" src="../assets/images/icon-remove.svg" class="ml-10" @click="removeItem(indexg)"></div>
                                            </div>
                                            <div class="block-menu-selected flex" v-if="!emptyVariable(groupDetails[indexg].info) && !emptyVariable(groupDetails[indexg].info.hasExtraCheese)">
                                                <div class="menu-name flex-1">
                                                    <span>{{$t('common.add')}} {{groupDetails[indexg].info.hasExtraCheese.name}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-grid pb-10 flex flex-wrap items-center md:pt-5" v-show="groupDetails[indexg].show">
                                            <combo-detail-menu :product="product" :group-index="indexg" :product-point="productPoint" :default-crust="defaultCrust"
                                                               :addToDeal="addToDeal"
                                                               :isSecond ="group.isSecond"
                                                               v-for="(product, indexM) in group.products" :key="indexM">
                                            </combo-detail-menu>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade-button">
            <div class="bg-white block-action-btn side-menu-placeholder" v-if="isEnoughAddCombo">
                <div class="container p-10">
                    <div class="text-center border-button-addto-basket ">
                        <div class="btn button--red button-add-to-basket " @click="addToBasket()" >
                            <span class="flex-1 text-left">{{$t('common.add_deal_to_basket')}}</span>
                            <span class=" flex-1 text-right" v-if="productPoint > 0">+{{$t('common.format_point', {amount: formatCurrency(this.grandPoint, $const.SUFFIX_CURRENCY)})}}</span>
                            <span class=" flex-1 text-right" v-else>+{{$t('common.format_currency', {amount: formatCurrency(this.total, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
  import comboDetailMenu from '../components/partials/comboDetailMenu'
  import '@/assets/css/order.css';
  import '@/assets/css/flag.css';
  import Navigation from '../components/NavigationComponent'
  import HeaderOrder from '../components/HeaderOrderComponent'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import mutationTypes from "../store/mutationTypes"
  import MenuApi from '../services/menu'
  import manthan from "../services/manthan";
  import branch from '../services/branch'


  export default {
    components: {
      'navigation': Navigation,
      'header-order': HeaderOrder,
      'combo-detail-menu': comboDetailMenu,
    },
    computed: {
      ...mapGetters ({
        storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
        orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
        orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
        timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
        orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
        cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
        defaultCategory: mutationTypes.GET_DEFAULT_CATEGORY,
        isAuth: 'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
        localisation: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_LOCALISATION,
        cartCount: 'moduleCart/' + mutationTypes.CART_COUNT,
        cartData: 'moduleCart/' + mutationTypes.CART_DETAIL,
        visitorID:  mutationTypes.GET_VISITOR_ID,
      }),
      ...mapState({
        listCategories: 'categories',
      })

    },
    data() {
      return {
        isEnoughAddCombo: false,
        productPoint: false,
        groupDetails: [],
        total: 0,
        grandPoint: 0,
        product: {},
        backCategory: '',
        codeRedeem: '',
        defaultCrust: {},
        comboHalfGroups:{},
      }
    },
    watch: {
      cartUuid: function (newValue) {
        if (newValue) {
          this.localisation.cartUuid = this.newValue
          this.setStore(this.localisation)
        }
      },
    },
    mounted() {
      if (this.emptyVariable(this.storeUuid)) {
          this.$root.$router.push({name: 'home', query: { warning: '1', callback: 'combo/' + this.$route.params.uuid }})
          return;
      }
      this.codeRedeem = this.$route.params.code || '';
      this.backCategory = this.defaultCategory;
      this.getComboDetail(this.$route.params.uuid);
    },
    methods: {
      ...mapActions({
        addToCart: 'moduleCart/' + mutationTypes.CART_SAVE_ITEM,
        setCurrentCategory: mutationTypes.SET_CURRENT_CATEGORY,
        setStore: 'moduleLocalisation/' + mutationTypes.LOCALISATION_SAVE_STORE,
      }),
      defineBackCategory() {
        if (!this.emptyVariable(this.listCategories) && !this.emptyVariable(this.product) && this.product.category) {
          for (let item of this.listCategories) {
            if (item.uuid == this.product.category.uuid ) {
              this.backCategory = item.slug;
              this.setCurrentCategory(item);
            }
          }
        }
      },
      checkAuthentPoint () {
        if (this.product.point > 0 && !this.isAuth) {
          this.$notify({
            type: 'warning',
            title: this.$t('message.error.login_to_redeem')
          });
          this.$router.push({name: 'login'});
        }
      },
      removeItem (groupIndex){
        this.groupDetails[groupIndex].info = {};
        this.checkShowButtonSave();
        this.calculatorTotal();
      },
      getComboDetail (uuid) {
        var vm = this;
        let payload = {
          type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
          store_uuid: this.storeUuid,
          vm: this,
        }
        MenuApi.getComboDetail(uuid, payload, function (response) {
          vm.product = response.data;
          if (vm.product.point > 0) {
            vm.productPoint = true;
          }
          if(vm.product.is_combo_half){
              vm.comboHalfGroups.first = vm.product.groups[0].products[0].option_groups
              vm.comboHalfGroups.second = vm.product.groups[1].products[0].option_groups
              vm.product.groups[1].isSecond = true;
          }
          vm.defineBackCategory();
          vm.checkAuthentPoint();
          vm.initGroupsData();
          vm.autoAddItemIntoGroup();
          vm.openNextGroup();
        }, function (error) {
          vm.notificationError(vm, error.message);
        })
      },
      autoAddItemIntoGroup(){
        for (let i in this.product.groups) {
          let group = this.product.groups[i];
          if(!this.emptyVariable(group.products)
              && group.products.length === 1
              && group.min > 0
              && group.products[0].sub_price <= 0
              && !this.hasSelection(group.products[0])
              && this.emptyVariable(group.products[0].toppings.additional_toppings)
          ) {
            let selectedCrustItem = {};
            let selectedSizeItem = {};
            let price = group.products[0].sub_price;
            let totalPoint = group.products[0].point || 0;
            if (group.products[0].option_groups && group.products[0].option_groups.length > 0) {
              selectedCrustItem = group.products[0].option_groups[0];
              price += selectedCrustItem.sub_price;
              totalPoint += selectedCrustItem.point || 0;
              if (selectedCrustItem.options.length > 0) {
                selectedSizeItem = selectedCrustItem.options[0];
                price += selectedSizeItem.sub_price;
                totalPoint += selectedSizeItem.point || 0;
              }
            }
            let dataProduct = {
              "crust": selectedCrustItem,
              "size": selectedSizeItem,
              "product": group.products[0],
              "quantity": 1,
              "hasExtraCheese": {},
              "total": price,
              "totalPoint": totalPoint,
              "hasOption": false,
              "toppings": {
                "minus": [],
                "plus": []
              }
            }
            this.addToDeal(dataProduct, i * 1, false)
          }
        }
      },
      hasSelection (item) {
        if (item && item.option_groups && item.option_groups != undefined) {
          return item.option_groups.length > 1 || ( item.option_groups.length == 1 && item.option_groups[0].options.length > 1)
        }
        return false
      },
      calculatorTotal () {
          this.total = this.product.sub_price;
          this.grandPoint = this.product.point || 0;
          for (let i in this.groupDetails) {
            if (!this.emptyVariable(this.groupDetails[i].info)) {
              this.total += this.groupDetails[i].info.total;
              this.grandPoint += this.groupDetails[i].info.totalPoint || 0;
            }
          }
      },
      initGroupsData () {
        this.groupDetails = [];
        for (let i in this.product.groups) {
          this.groupDetails.push({
            "max": this.product.groups[i].max,
            "min": this.product.groups[i].min,
            "name": this.product.groups[i].name,
            "uuid": this.product.groups[i].uuid,
            "show": false,
            "info": {

            }
          })
        }
      },
      toggleGroup(groupIndex) {
        this.groupDetails[groupIndex].show = !this.groupDetails[groupIndex].show;
        if (this.groupDetails[groupIndex].show) {
          this.eventProductView(this.product.groups[groupIndex].products, 'deal-group-detail');
        }
      },
      checkShowButtonSave () {
        let count = 0
        for (let i in this.groupDetails) {
          if (!this.emptyVariable(this.groupDetails[i].info) || this.groupDetails[i].min === 0) {
            count++;
          }
        }
        this.isEnoughAddCombo = count === this.groupDetails.length;
      },
      openNextGroup (){
        for (let i in this.groupDetails) {
          if (this.emptyVariable(this.groupDetails[i].info)) {
            this.groupDetails[i].show = true;
            this.eventProductView(this.product.groups[i].products, 'deal-group-detail');
            break;
          }
        }
      },
      addToBasket() {
        let dataProduct = {
          "cart_uuid": this.cartUuid || '',
          "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
          "items": [
            {
              "combo_uuid": this.product.uuid,
              "is_point": this.product.point > 0 ? 1 : 0,
              "promotion_code": this.codeRedeem || '',
              "products": [

              ],
              "quantity": 1,
              "type": "combo",
              "voucher_code": ""
            }
          ],
          "now_order": this.orderNow,
          "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
          "store_uuid": this.storeUuid,
          "loyalty_id": this.visitorID,
        }

        // add table_number only for dine in
        if (this.localisation.orderType === this.$const.ORDERTYPE.DINEIN) {
          dataProduct.table_number = this.localisation.tableNumber
        }

        for (let detail of this.groupDetails) {
          let plus = [];
          if ( !this.emptyVariable(detail.info.hasExtraCheese)) {
            plus.push({
              quantity: 1,
              uuid: detail.info.hasExtraCheese.uuid,
            });
          }
          if(detail.info.product){
            dataProduct.items[0].products.push({
              "category_uuid": detail.info.product.category.uuid,
              "combo_group_uuid": detail.uuid,
              "option_uuid": detail.info.size && !this.emptyVariable(detail.info.size) ? detail.info.size.uuid : '',
              "option_group_uuid": detail.info.crust && !this.emptyVariable(detail.info.crust) ? detail.info.crust.uuid : '',
              "product_uuid": detail.info.product.uuid,
              "quantity": 1,
              "toppings": {
                "removal": [],
                "addition": plus
              }
            })
          }
        }
        this.handleEventAddToCart();
        this.addToCart({vm: this, payload: dataProduct}).then((response) => {
          this._eventAddToCart();
          this.localisation.cartUuid = response.information.cart_uuid
          this.setStore(this.localisation)
        });
        this.$router.push({name: 'category', params: {slug: this.backCategory}})
      },
      addToDeal (menu, groupIndex, next = true) {
        if (groupIndex > -1) {
          this.groupDetails[groupIndex].info = menu;
          this.groupDetails[groupIndex].show = false;
        }
        this.checkShowButtonSave();
        this.calculatorTotal();
        if (next) {
            if(this.product.is_combo_half){
                if(groupIndex === 1){
                    this.defaultCrust = this.comboHalfGroups.first.find(crust => crust.uuid == menu.crust.uuid)
                } else {
                    this.defaultCrust = this.comboHalfGroups.second.find(crust => crust.uuid == menu.crust.uuid)
                }
            }
          this.openNextGroup();
        }
      },
      handleEventAddToCart() {
        let dataPro = {
          'name': this.product.name,
          'id': this.product.uuid,
          'variant': '',
          'price': this.total,
          'category': this.product.category && this.product.category.name ? this.product.category.name : '',
        };
        this.eventAddToCart(dataPro);
      },
      _eventAddToCart() {
        //function for manthan
        const dataManthanCart = {
          cartId: this.cartUuid || '',
          productId: this.product.uuid,
          categoryId: this.product.category && this.product.category.uuid ? this.product.category.uuid : '',
          brandId: this.localisation.storeType === this.$const.TYPE_HUT.PHD ? 'PHD' : 'PHR',
          price: this.product.sub_price,
          quantity: 1,
          totalQuantity: this.cartCount,
          shippingCost: this.cartData.information.delivery_fee > 0 ? this.cartData.information.delivery_fee : 0,
          tax: this.product.tax
        };
        manthan.execute(dataManthanCart, "eventAddToCart")
        branch.branchLog('ADD_TO_CART',[this.product])
      }
    }

  }
</script>
<style>

</style>
