<template>
    <div class="bg-white relative" :class="{'z-600': !$route.query.warning}" style="box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px 0px;">
      <div class="container flex flex-center justify-between relative h-full">
          <div v-if="!isMobile()" class="header-logo container py-15 pl-5 block">
            <router-link :to="'/'">
                <p class="logo-full screenreader-only" style="font-weight: 700;font-size: 2.4rem;" v-if="$route.name == 'certificate'">Order Pizza for Delivery from PHD Indonesia</p>
                <h1 class="logo-full screenreader-only" v-else>Order Pizza for Delivery from PHD Indonesia</h1>
            </router-link>
          </div>
          <div v-else class="relative z-500">
              <router-link :to="'/'" class="header-logo block lg:pt-15 lg:py-15 lg:pl-30 bg-white lg:bg-transparent rounded-full" >
                  <span class="logo"></span>
              </router-link>
          </div>
        <div class="menu-header flex items-center flex-wrap justify-between" :class="menuOpen ? 'block slide-left': 'hidden-1024'">
            <a class="close-sidebar lg:hidden"
               @click.prevent.stop="toggleMenu()">
                <img src="../assets/images/icon-close--white.svg"
                     class="">
            </a>
          <div class="md:pr-0 body regular-400 header-font">
              <div class="flex whitespace-no-wrap mobile-flex-column mobile-full-width">
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px">
                  <router-link :to="{name: 'home'}" v-bind:class="{'active': this.$route.name == 'home'}">Home</router-link>
                </div>
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px" >
                    <router-link :to="{name: 'order-tracking'}" v-bind:class="{'active': this.$route.name == 'order-tracking'}"> {{$t('common.order_tracker')}}</router-link>
                </div>
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px" >
                  <router-link :to="{name: 'ph-reward'}" v-bind:class="{'active': this.$route.name == 'ph-reward'}">My Rewards</router-link>
                </div>
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px" >
                    <button class="button button--red" style="min-height: 32px; font-size: 14px" v-if="!isAuth" @click="showLogin()"> Sign Up /Login</button>
                    <div v-else class="header-menu">
                        <router-link class="w-full" :to="{name: 'profile'}" v-bind:class="{'active': this.$route.name == 'profile'}">{{$t('common.my_account')}}</router-link>
                        <div class="header-menu-content">
                            <router-link class="header-menu-item" :to="'#'" >Saved address</router-link>
                            <router-link class="header-menu-item" :to="'#'" >Saved outlet</router-link>
                            <span class="header-menu-item" :to="'#'" @click="logout">{{$t('common.logout')}}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!isMobile() && isAuth" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px">
                  <img src="../assets/images/hut_reward_circle.png" alt=""><div class="px-10" style="color: #3E4043;">{{authUser.point}}</div>
                </div>
              </div>
          </div>
        </div>
          <div class="overlay-bg z-700"></div>
      </div>
      <div class="block hidden absolute button-toggle-menu" :class="{'z-1000 bg-white': $route.query.warning}" style="right: 5px; top: 50%; transform: translateY(-50%);">
          <button class="flex items-center p-10">
              <router-link :to="{name: 'status'}" class="flex typography-5 header-font" v-if="localisation.orderType === $const.ORDERTYPE.DINEIN && order_code">
                <img src="../assets/images/icon-qr-code.png" /> Dine-in 
              </router-link>
              <router-link :to="{name: 'category'}" class="flex typography-5 header-font" v-else-if="localisation.tableNumber">
                <img src="../assets/images/icon-qr-code.png" /> Dine-in 
              </router-link>
              <a class="flex typography-5 header-font" v-else-if="localisation.orderType && order_code == ''" @click="setModalClearCart(true, false)">
                <img src="../assets/images/icon-qr-code.png" /> Dine-in 
              </a>
              <router-link :to="{name: 'qr-scanner'}" class="flex typography-5 header-font" v-else>
                <img src="../assets/images/icon-qr-code.png" /> Dine-in 
              </router-link>
          </button>
      </div>
      <ModalClearCart v-if="isShowModal" @valueModal="setModalClearCart"></ModalClearCart>
    </div>
</template>

<script>
  import types from '../store/mutationTypes'

  import { mapActions, mapGetters } from 'vuex'
  import modalLoginRegister from "@/components/modal/ModalLoginRegister.vue"
  import ModalClearCart from "../components/modal/ModalClearCart"
  import braze from "@/services/braze"
  import { orderMixins } from '../mixins/order'
  export default {
  mixins: [orderMixins],
  components: {
    ModalClearCart,
  },
  data() {
    return {
      centeredActionsOpen: false,
      menuOpen: false,
      store_uuid: '',
      order_code: '',
      goToConfirmPage: false,
      lat: null,
      long: null,
      isShowModal: false
    }
  },
  destroyed() {
    document.body.classList.remove('sidebar-open')
  },
  computed: {
  ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
      defaultCategory:  types.GET_DEFAULT_CATEGORY,
      orderCode: 'moduleOrder/' + types.ORDER_GET_ORDER_CODE,
      orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
    }),
  },
  mounted() {
      this.store_uuid = this.storeUuid
      this.order_code = this.orderCode
      if (this.orderType === this.$const.ORDERTYPE.DINEIN) {
        this.checkLatestDineInOrder()
      }
  },
  created() {
    this.getLatLong()
  },
  watch: {
      storeUuid: function(){
          this.store_uuid = this.storeUuid
      },
      orderCode: function(){
          this.order_code = this.orderCode
      }
  },
  methods: {
  ...mapActions ({
    logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
    saveOrder: 'moduleOrder/' + types.ORDER_SAVE_ITEM,
    resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
    resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
    resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
  }),
  setModalClearCart(value, hasStore) {
    if (hasStore == true) {
      // access property of LocalisationBox and set false
      this.$parent.$children[1].$refs.localisationBox.hasStore = false
    }
    this.isShowModal = value
  },
  toggleMenu() {
    this.menuOpen = !this.menuOpen
    if (this.menuOpen){
      document.body.classList.add('sidebar-open')
    } else {
      document.body.classList.remove('sidebar-open')
    }
  },
  routeMenu(){
      if (this.storeUuid) {
          this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
      } else {
          this.$router.push({name: 'home', query: {warning: '1', callback:this.defaultCategory}});
          return false;
      }
  },
    showLogin() {
      const vm = this
      this.$modal.show(
          modalLoginRegister,
          {
            resizable: true,
            clickToClose: true,
            callEvent: function () {
              return  vm.callEvent
            }
          }
      )
    },
    callEvent(){
      braze.customEvent('login', {})
      return true
    },
    getLatLong() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
        }, () => {
          // console.log(error);
        });
      } else {
        // console.log("Geolocation is not supported by this browser.");
      }
    },
    // async checkOrderTable() {
    //   let vm = this
    //   let data = {vm}
      
    //   let payloadOrderTable = {
    //     ...data,
    //     store_code:this.localisation.code,
    //     table_number: this.localisation.tableNumber,
    //     lat: this.lat,
    //     long: this.long,
    //     loader: false
    //   }
    //   await OrderApi.validateTable(payloadOrderTable, 
    //   (response) => {
    //     if (!this.invalidStatusCodes.includes(response.data.order_status.name)) {
    //       this.saveOrder({
    //         order_code: response.data.code,
    //         order_uuid: response.data.uuid,
    //         order_status_code: response.data.order_status.code, 
    //         order_status_uuid: response.data.order_status.uuid,
    //         collection_time: response.data.collection_time,
    //         order_information: response.data
    //       })
    //     } else {
    //       this.orderIsFinished()
    //     }
    //   }, 
    //   () => {
    //     this.orderIsFinished()
    //   })
    // },
    // async checkOrderTableGuest() {
    //   let vm = this
    //   let data = {vm}
      
    //   let payloadOrderTable = {
    //     ...data,
    //     store_code:this.localisation.code,
    //     table_number: this.localisation.tableNumber,
    //     lat: this.lat,
    //     long: this.long,
    //     loader: false
    //   }
    //   await OrderApi.validateTableGuest(payloadOrderTable, 
    //   (response) => {
    //     if (!this.invalidStatusCodes.includes(response.data.order_status.name)) {
    //     this.saveOrder({
    //       order_code: response.data.code,
    //       order_uuid: response.data.uuid,
    //       order_status_code: response.data.order_status.code, 
    //       order_status_uuid: response.data.order_status.uuid,
    //       collection_time: response.data.collection_time,
    //       order_information: response.data
    //     })
    //     } else {
    //       this.orderIsFinished()
    //     }
    //   }, 
    //   () => {
    //     this.orderIsFinished()
    //   })
    // },
    // orderIsFinished() {
    //   if (this.invalidRoute.includes(this.$route.name)) {
    //     if (this.orderCode) {
    //       this.notificationSuccess(this, this.$t('message.success.order_finished'))
    //     }
    //     clearInterval(this.$parent.statusInterval)
    //     this.resetOrder()
    //     this.resetLocalisation()
    //     this.resetCart()
    //   }
    // }
  }
}
</script>
<style>
  .header-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #3E4043;
  }
  .header-font .active {
    color: #C8102E;
  }
  .header-font img {
    width: 15px;
    height: 15px;
    margin: auto;
    margin-right: 3px;
  }
</style>