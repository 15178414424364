import Api from '@/services/api'
import types from "../store/mutationTypes";
const url = '/promotion/v1'
export default {
    getCoupons(data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/promotions', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getTreasures(data, success, error ) {
        let mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get('/promotion/v2/promotion-treasure', {params: data})
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    claimTreasure(uuid, data, success, error ) {
        let mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get('/promotion/v2/promotion-treasure/claim/'+ uuid, {params: data})
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    getTreasureDetail(code, data, success, error ) {
        let mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get('/promotion/v2/promotion-treasure/'+ code, {params: data})
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    updateReceipt(data, success, error ) {
        let mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post('/promotion/v2/promotion-treasure/update-receipt', data)
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    checkSuggestPromo(data, success, error){
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/suggest-promotion', {params: data})
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    getPromotionMaster(data, success, error){
      var mainApp = null;
      if (data.vm) {
          mainApp = data.vm;
          mainApp.$store.dispatch(types.IN_CREATE_LOADING);
          delete data['vm']
      }
      return Api().get(url + '/promotion-user/get-promotion-master', {params: data})
          .then(response => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (success != undefined) {
                  success(response)
              }
          }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (error != undefined) {
                  error(errorData)
              }
          })
  }
}