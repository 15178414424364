import types from '../../mutationTypes'
import cartAPI from '../../../services/cart'

export default {
    [types.CART_SAVE_ITEM]({commit}, payload) {
      return new Promise((resolve, reject) => {
          // call api
          if (payload.hasOwnProperty('vm')) {
              payload.payload.vm = payload.vm;
          }
          cartAPI.addCart(payload.payload, function (response) {
              payload.vm.$notify({
                  type: 'success',
                  title: 'Penambahan item ke keranjang telah sukses',
              })

              let is_checkout = false
              const obj = {
                total: 0,
                sub_total: 0,
                before_tax: 0,
                sub_takeaway_fee: 0,
                sub_delivery_fee: 0,
                takeaway_fee: 0,
                delivery_fee: 0,
              }

              obj.sub_takeaway_fee = response.data.information.sub_takeaway_fee
              obj.sub_delivery_fee = response.data.information.sub_delivery_fee
              obj.takeaway_fee = response.data.information.takeaway_fee
              obj.delivery_fee = response.data.information.delivery_fee
              obj.total = response.data.information.total
              obj.sub_total = response.data.information.sub_total
              obj.before_tax = response.data.information.tax

              response.data.products.forEach( e => {
                if (e.is_checkout == 1) {
                  is_checkout = true
                }
              })

              if ( is_checkout === true) {
                obj.total = response.data.information.sub_info.total
                obj.sub_total = response.data.information.sub_info.sub_total
                obj.before_tax = response.data.information.sub_info.tax
              }

              response.data.all_total = obj
              commit(types.CART_SET_DATA, response.data)
              resolve(response.data); // Mengembalikan respons sukses
          }, function (error) {
              payload.vm.$notify({
                  type: 'error',
                  title: error.message,
              })
              reject(error); // Mengembalikan kesalahan
          })
      });
    },
    [types.CART_GET_CART_DETAIL]({commit}, payload) {
        // call api
        cartAPI.getDetail(payload.payload, function (response) {
          let is_checkout = false
          const obj = {
            total: 0,
            sub_total: 0,
            before_tax: 0,
            sub_takeaway_fee: 0,
            sub_delivery_fee: 0,
            takeaway_fee: 0,
            delivery_fee: 0,
          }

          obj.sub_takeaway_fee = response.data.information.sub_takeaway_fee
          obj.sub_delivery_fee = response.data.information.sub_delivery_fee
          obj.takeaway_fee = response.data.information.takeaway_fee
          obj.delivery_fee = response.data.information.delivery_fee
          obj.total = response.data.information.total
          obj.sub_total = response.data.information.sub_total
          obj.before_tax = response.data.information.tax

          response.data.products.forEach( e => {
            if (e.is_checkout == 1) {
              is_checkout = true
            }
          })
          
          if (is_checkout === true) {
            obj.total = response.data.information.sub_info.total
            obj.sub_total = response.data.information.sub_info.sub_total
            obj.before_tax = response.data.information.sub_info.tax
          }

          response.data.all_total = obj

            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
        })
    },
    [types.CART_GET_CART_CHECKOUT_DETAIL]({commit}, payload) {
        // call api
        cartAPI.getCheckoutDetail(payload.payload, function (response) {
          let is_checkout = false
          const obj = {
            total: 0,
            sub_total: 0,
            before_tax: 0,
            sub_takeaway_fee: 0,
            sub_delivery_fee: 0,
            takeaway_fee: 0,
            delivery_fee: 0,
          }

          obj.sub_takeaway_fee = response.data.information.sub_takeaway_fee
          obj.sub_delivery_fee = response.data.information.sub_delivery_fee
          obj.takeaway_fee = response.data.information.takeaway_fee
          obj.delivery_fee = response.data.information.delivery_fee
          obj.total = response.data.information.total
          obj.sub_total = response.data.information.sub_total
          obj.before_tax = response.data.information.tax

          response.data.products.forEach( e => {
            if (e.is_checkout == 1) {
              is_checkout = true
            }
          })
          
          if (is_checkout === true) {
            obj.total = response.data.information.sub_info.total
            obj.sub_total = response.data.information.sub_info.sub_total
            obj.before_tax = response.data.information.sub_info.tax
          }

          response.data.all_total = obj

            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
        })
    },
    [types.CART_UPDATE_QUANTITY]({commit}, payload) {
        // call api
        if (payload.hasOwnProperty('vm')) {
            payload.payload.vm = payload.vm;
        }
        cartAPI.updateQuantity(payload.payload, function (response) {
            if(response.data.products.length == 0){
                commit(types.CART_SET_SUGGEST, [])
                response.data.all_total = {
                  before_tax: 0,
                  total: 0,
                  sub_total: 0,
                }
            } else {
              let is_checkout = false
              const obj = {
                total: 0,
                sub_total: 0,
                before_tax: 0,
                sub_takeaway_fee: 0,
                sub_delivery_fee: 0,
                takeaway_fee: 0,
                delivery_fee: 0
              }

              obj.sub_takeaway_fee = response.data.information.sub_takeaway_fee
              obj.sub_delivery_fee = response.data.information.sub_delivery_fee
              obj.takeaway_fee = response.data.information.takeaway_fee
              obj.delivery_fee = response.data.information.delivery_fee
              obj.total = response.data.information.total
              obj.sub_total = response.data.information.sub_total
              obj.before_tax = response.data.information.tax

              response.data.products.forEach( e => {
                if (e.is_checkout == 1) {
                  is_checkout = true
                }
              })

              if ( is_checkout === true) {
                obj.total = response.data.information.sub_info.total
                obj.sub_total = response.data.information.sub_info.sub_total
                obj.before_tax = response.data.information.sub_info.tax
              }

              response.data.all_total = obj
            }
            if (!payload.silent) {
                payload.vm.$notify({
                    type: 'success',
                    title: payload.vm.$t('cart.update_quantity_success'),
                })
            }
            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
            payload.vm.$notify({
                type: 'error',
                title: error.message,
            })
        })
    },
    async [types.CART_REMOVE_COUPON]({commit}, payload) {
        // call api
        if (payload.hasOwnProperty('vm')) {
            payload.payload.vm = payload.vm;
        }
        await cartAPI.removeCoupon(payload.payload, function (response) {
            payload.vm.$notify({
                type: 'success',
                title: payload.vm.$t('cart.remove_coupon_success'),
            })
            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
            payload.vm.$notify({
                type: 'error',
                title: error.message,
            })
        })
    },
    [types.CART_REMOVE_VOUCHER]({commit}, payload) {
        // call api
        if (payload.hasOwnProperty('vm')) {
            payload.payload.vm = payload.vm;
        }
        cartAPI.removeVoucher(payload.payload, function (response) {
            payload.vm.$notify({
                type: 'success',
                title: payload.vm.$t('cart.remove_coupon_success'),
            })
            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
            payload.vm.$notify({
                type: 'error',
                title: error.message,
            })
        })
    },
    [types.CART_UPDATE_CART]({commit}, payload) {
        // call api
        if (payload.hasOwnProperty('vm')) {
            payload.payload.vm = payload.vm;
        }
        cartAPI.update(payload.payload, function (response) {
            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
        })
    },
    [types.CART_SET_DATA]({commit}, payload) {
        commit(types.CART_SET_DATA, payload.payload)
    },
    [types.CART_RESET_DATA]({commit}) {
        commit(types.CART_SET_DATA, {
            all_total: {},
            information: {},
            products: [],
            promotions: [],
        })
    },

    [types.CART_SET_SUGGEST]({commit}, payload) {
        commit(types.CART_SET_SUGGEST, payload.payload)
    },

}