<template>
  <div class="ml-15 mr-15">
    <div class="flex-row flex flex-center px-5" v-if="list.length > 0">
      <h2 v-if="!isMobile()"><span>My Voucher</span></h2>
      <div class="flex-1 font-open-sans-bold">
        <div v-if="isMobile()">My Vouchers</div>
      </div>
      <router-link v-if="isAuth" :to="{name: 'vouchers'}" class="text-red">see all</router-link>
    </div>
    <div v-if="list.length > 0 && isAuth">
      <div v-if="isMobile()" class="list-item product-list--deals product-list--coupons border-none" style="gap: 8px; border: 0 !important;" >
        <div class="list-item__image" v-for="(item, index) in list" :key="index" style="border-radius: 2px;">
          <voucher-singles :item="item" :is-img="true" @chosen-store="setIsOpenModal"/>
        </div>
      </div>
      <div v-else class="grid-container ml-5 mr-5">
        <div class="grid-item" v-for="(item, index) in list" :key="index">
          <voucher-singles :item="item" :is-img="true" @chosen-store="setIsOpenModal"/>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-center" :style="!isAuth ? 'padding-bottom:200px' : ''">
      <img src="../../assets/images/empty_voucher.jpg" style="max-height: 300px; width: auto">
    </div>
    <modal-voucher :is-open="isOpenModal" :voucher-data="voucherChosen" @close-modal="isOpenModal = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mutationTypes from "@/store/mutationTypes";
import couponAPI from "@/services/coupon";
import ModalVoucher from "@/components/modal/ModalVoucher";
import VoucherSingles from "@/components/rewards/VoucherSingles";

export default {
  name: "MyVoucherComponent",
  components: {VoucherSingles, ModalVoucher},
  props: {
    isAuth: {
      type: Boolean,
      default: false,
    },
    storeUuid: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      list: [],
      limitShowMobile: 1, // 2 item
      limitShowDesktop: 7, // 8 item
      isOpenModal: false,
      voucherChosen: {},
    }
  },
   created() {
    this.initData()
  },
  computed: {
    ...mapGetters({
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
      orderLocation: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_LOCALISATION,
    }),
  },
  methods: {
    initData(){
      const vm = this;
      let params = {
        store_uuid: this.storeUuid,
        vm: vm
      };
      vm.list = [];
      couponAPI.getCoupons(params,  async (response) => {
        const data = response.data
        this.orderTypeID = this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, 0)]
        this.storeTypeID =  this.orderLocation.storeType
        for (const [key, item] of Object.entries(data)) {
          if (await this.imageExists(item.thumbnail)) {
            if (this.isMobile() && vm.list.length > this.limitShowMobile) {
              break
            }
            if (!this.isMobile() && vm.list.length > this.limitShowDesktop) {
              break
            }
            if (!((this.orderTypeID > 0 && !(item.promotion_types.indexOf(this.orderTypeID) >=0)) ||
            (this.storeTypeID > 0 && !(item.store_type_ids.indexOf(this.storeTypeID) >=0)))
            ) {
              if (this.orderType === vm.$const.ORDERTYPE.DINEIN && [1, 2].indexOf(item.discount_type_id) === -1) {
                vm.list.push(item)
              } else if (this.orderType !== vm.$const.ORDERTYPE.DINEIN) {
                vm.list.push(item)
              }
            }
          }
        }
      })
    },
    setVoucher(voucher) {
      if(this.storeUuid != undefined && this.storeUuid !== '') {
        this.$router.push({name: 'category', params: {'slug' : 'promotions'}})
      } else {
        this.voucherChosen = voucher
        this.isOpenModal = true
      }
    },
    setIsOpenModal(voucher) {
      this.voucherChosen = voucher
      this.isOpenModal = true
    },
  }
}
</script>

<style scoped>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.24);
    text-align: center;
    border-radius: 4px 4px 4px 4px;
  }
  img {
    height: 206px;
  }
  .list-item__image > img {
    min-height: 150px;
    height: auto;
  }
</style>
