<template>
    <div class="w-full mt-100" v-if="!listRequired">
        <div class="product-grid list side-menu-placeholder list__group px-5 pb-10 flex flex-wrap items-center md:pt-5">
            <singles :product="item" :code-redeem="couponCode" v-for="(item, indexp) in products" :key="indexp" :slug="$route.params.slug"  v-if="!item.is_combo "></singles>
            <combos :product="item" :code-redeem="couponCode" :slug="$route.params.slug" v-else></combos>
            <div class="footer relative lg:hidden">
                <div class="footer__fixed bg-white shadow-up fixed bottom-0 left-0 right-0 z-100 md:z-100 side-menu-placeholder">
                    <div class="container flex flex-col">
                        <navigation v-if="!currentKeyword || (currentKeyword && currentKeyword.length === 0)"></navigation>
                        <div class="md:hidden menus-fixed-top-position">
                            <div class="bg-white relative overflow-auto text-center whitespace-no-wrap border-top border-bottom border-grey-light">
                                <router-link class="menu-link capitalize bold inline-block side-menu__link text-grey border-0 px-15 pb-5"
                                             v-for="(item, indexC) in listCategories" :key="indexC" v-if="item.slug !== $const.CATEGORY.COUPON || (isAuth) "
                                             :class="{'text-red side-menu__link--active': item.slug == currentCategory.slug } "
                                             aria-current="page" :to="'/order/' + item.slug" style="padding-left: 8px; padding-right: 8px;">
                                    <span>{{item.name}}</span></router-link>
                            </div>
                        </div>
                        <div class="bg-white p-10 relative z-200">
                            <button class="flex button button--red" :to="'#basket'" @click="showBasket()">
                         <span class="shopping-items" :style="`background-image: url('${CDN_URL}/icons/icon-shopping-cart.png')`">
                                    <span class="count-items" v-if="cartCount > 0">{{cartCount}}</span>
                                </span>
                                <span class="absolute inset-0 flex-center">
                            <span>{{$t('common.view_basket')}}</span>
                        </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="w-full">
            <div  v-for="(productTag, indenxPItem) in products" :key="indenxPItem">
                <h2 class="watermark cond single-tag caps xs-my-20 xs-mx-10 xs-text-center heading-hr clearfix pt-10" v-if="productTag.name || productTag.image" :class="{'margin-top-container': indenxPItem === 0  }">
                    <img :src="productTag.image" v-if="productTag.image">
                    <span v-else class="t24 bold">
                       {{productTag.name}}
                   </span>
                </h2>
                <div class="product-grid list side-menu-placeholder container list__group px-5 pb-10 flex flex-wrap items-center md:pt-5" :class="{'margin-top-container': indenxPItem === 0 && !productTag.name && !productTag.image}">
                    <singles :product="item" :is-required="couponCode"  :slug="$route.params.slug" v-if="!item.is_combo " v-for="(item, indexp) in productTag.products" :key="indexp"></singles>
                    <combos :product="item" :slug="$route.params.slug" v-else></combos>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  /* eslint-disable */
import Singles from '@/components/partials/singles'
import Combos from '@/components/partials/combos'
import Navigation from '@/components/NavigationComponent'

import { mapState, mapGetters } from 'vuex'
import mutationTypes from "@/store/mutationTypes"
import menuAPI from '@/services/menu'
import braze from '@/services/braze'

const CDN_URL = process.env.VUE_APP_CDN_URL

export default {
  components: {
    'singles': Singles,
    'combos': Combos,
    Navigation
  },
  mounted () {
    if (!this.isAuth) {
      this.$root.$router.push({name: 'login', query: { callback: 'order/' + this.$const.COUPON_CAT.slug }});
      return;
    }
    this.couponCode = this.$route.params.code;
    this.uuidProduct = this.$route.params.uuid;
    this.listRequired = this.$route.params.listRequired;
    this.getMenuListByCouponCode();
  },
  data () {
    return {
        CDN_URL: CDN_URL,
        couponCode: '',
        uuidProduct: '',
        couponMessage: '',
        products: [],
        listRequired: ''
     }
  },
  methods: {
    showBasket (){
      document.body.classList.add('basket-open');
      braze.customEvent('page_view', {
          screen_name: 'Cart',
      })
    },
    getMenuListByCouponCode () {
      let params = {
        type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        store_uuid: this.storeUuid,
        uuids : this.listRequired,
        vm: this
      };
      var vm = this;
      vm.products = [];
      if (this.listRequired){
          menuAPI.getProducts(params, function (response) {
              vm.products = response.data;
              vm._sortTagProduct(response.data);
          })
      } else {
          menuAPI.getProductDetail(this.uuidProduct || '', params, function (response) {
              vm.products = [response.data];
              vm.eventProductsImpression(vm.products, 'main-list');
              vm.eventProductView(vm.products, 'main-list');
          })
      }
    },
      _sortTagProduct (dataProducts) {
          let _productFirst = [];
          for (let item of dataProducts) {
              if (!this.emptyVariable(item.products) && item.products.length > 0) {
                  _productFirst = _productFirst.concat(item.products);
              }
          }
          this.eventProductsImpression(_productFirst, 'main-list');
          this.eventProductView(_productFirst, 'main-list');
      },
  },
  watch: {
  },

  computed: {
    ...mapGetters ({
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
      isAuth:  'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
      cartCount: 'moduleCart/' + mutationTypes.CART_COUNT,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,


    }),
    ...mapState({
      currentCategory: 'currentCategory',
      listCategories: 'categories'
    })
  }
}
</script>
