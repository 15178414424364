<template>
  <div class="inset-0 bg-white flex flex-col" :class="{'fixed': isMobile()}">
    <header-component ref="headerComponent" />
    <div id="my-order" class="my-order-container overflow-auto w-full z-300 md:z-200 lg:shadow-left lg:border-l bg-white ml-auto flex-1 pb-5">
      <div class="font-open-sans-bold uppercase text-center relative py-15" :style="{'background-image': 'url(' + bgImage + ')'}">
        <h1 class="px-40">{{$t('my_order.my_order')}}</h1>
        <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
      </div>
      <div class="flex text-glitch-fix tab-contain">
          <div class="flex-center flex-auto min-w-0 self-end p-10 font-bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-7 align-tab-text text-grey-darker border-solid border-b-2 border-white shadow-down-2" :class="{'border-red text-red': currentPage === 'onGoing'}" role="button" tabindex="0" @click="currentPage = 'onGoing'">
            <span class="capitalize">{{$t('my_order.on_going')}}</span>
          </div>
          <div class="flex-center flex-auto min-w-0 self-end p-10 font-bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-7 align-tab-text text-grey-darker border-solid border-b-2 border-white shadow-down-2" :class="{'border-red text-red': currentPage === 'history'}" role="button" tabindex="0" @click="currentPage = 'history'">
            <span class="capitalize">{{$t('my_order.history')}}</span>
          </div>
      </div>
      <on-going-component v-if="currentPage === 'onGoing'" />  
      <history-component :orderCode="orderCode" v-if="currentPage === 'history'" />  
    </div>

    <div class="my-order-footer bg-white lg:shadow-up-white ml-auto w-full mt-auto" :class="{'mobile-bottom': this.isMobile()}" v-if="currentPage
     === 'onGoing'">
      <template v-if="orderCode && orderType == $const.ORDERTYPE.DINEIN">
        <div class="flex">
          <div class="flex flex-row mb-5 px-15 pt-15 w-full" style="gap: 5px;">
            <button class="button button--white-red w-p50 typography-6" :disabled="orderStatusCode == $const.ORDERSTATUS.PENDINGCREDITCARD.toUpperCase()" @click="addMore">
              {{$t('common.btn.add_more')}}
            </button>
            <router-link :to="{name: 'status'}" class="button button--white-red w-p50 typography-6">
              {{$t('common.btn.status')}}
            </router-link>
            <!-- <button class="button button--white-red w-p50 typography-6" @click="viewStatus">
              {{$t('common.btn.status')}}
            </button> -->
          </div>
        </div>
        
        <div class="flex">
          <div class="flex flex-row mb-5 px-15 pb-15 w-full" style="gap: 5px;">
            <router-link :to="{name: 'payment'}" class="button button--red w-full typography-6" :disabled="$const.ORDERSTATUS_DINEIN_MAPPING_CODE.SERVED.indexOf(orderStatusCode) == -1 || unableToPay()">
              {{$t('common.btn.pay')}}
            </router-link>
          </div>
        </div>
      </template>
      <template v-else-if="!orderCode">
        <div class="flex">
          <div class="flex flex-col items-center mb-15 px-15 pt-15 w-full">
            <button class="button button--white-red w-p50 typography-6" @click="$router.push({name: 'home'})">
              {{$t('common.btn.lets_order')}}
            </button>
          </div>
        </div>
      </template>
    </div>
    <div class="my-order-footer bg-white lg:shadow-up-white ml-auto w-full mt-auto" :class="{'mobile-bottom': this.isMobile()}" v-else>
    </div>
    <mobile-footer-component v-if="isMobile()" />
  </div>
</template>
<script>
import { filter } from 'lodash'
import HeaderComponent from '@/components/HeaderComponent';
import OrderApi from '@/services/order';
import MobileFooterComponent from "@/components/MobileFooterComponent";
import { mapActions, mapGetters } from 'vuex';
import mutationTypes from "@/store/mutationTypes";
import bgImage from '@/assets/images/bg-app.jpg'
import OnGoingComponent from '@/components/myOrder/OnGoing'
import HistoryComponent from '@/components/myOrder/History'
import treasureHutAPI from "@/services/treasurehut"
import ModalTreasureHut from "@/components/modal/ModalTreasureHut";
import { orderMixins } from '../mixins/order'

export default {
  mixins: [orderMixins],
  name: "order-tracker",
  components: {
    MobileFooterComponent,
    HeaderComponent,
    OnGoingComponent,
    HistoryComponent,
  },
  data() {
    return {
      currentPage: 'onGoing',
      orderNumber: '',
      errors: {
        isValid: false,
        orderNumber: '',
      },
      bgImage: bgImage,
      statusInterval: null,
      isShowTreasureHut: false
    }
  },
  computed: {
    ...mapGetters ({
        storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
        orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
        isAuth:  'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
        cartCount: 'moduleCart/' + mutationTypes.CART_COUNT,
        cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
        cartTotal: 'moduleCart/' + mutationTypes.CART_GET_TOTAL,
        cartSuggest: 'moduleCart/' + mutationTypes.CART_GET_SUGGESTIONS,
        cartPromo: 'moduleCart/' + mutationTypes.CART_GET_PROMOTIONS,
        orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
        orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
        timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
        localisation: 'moduleLocalisation/' +  mutationTypes.LOCALISATION_GET_LOCALISATION,
        defaultCategory:  mutationTypes.GET_DEFAULT_CATEGORY,
        cartData: 'moduleCart/' + mutationTypes.CART_DETAIL,
        orderStatusCode: 'moduleOrder/' + mutationTypes.ORDER_GET_ORDER_STATUS_CODE,
        orderCode: 'moduleOrder/' + mutationTypes.ORDER_GET_ORDER_CODE,
        orderUuid: 'moduleOrder/' + mutationTypes.ORDER_GET_ORDER_UUID,
    }),
  },
  watch: {
    'orderNumber': function (newValue) {
      this.errors.orderNumber = false;
      if (!newValue) {
        this.errors.orderNumber = this.$t('message.error.order_number');
      }
    }
  },
  mounted () {
    this.setCustomerTemp({})
    if (this.orderType == this.$const.ORDERTYPE.DINEIN) {
      // this.$refs.headerComponent.checkLatestDineInOrder(false)
      if (!this.emptyVariable(this.localisation.code) && !this.emptyVariable(this.localisation.tableNumber)) {
        this.checkLatestDineInOrder(false)
      }
    } else {
      // this.$refs.headerComponent.checkLatestOrder(false)
      this.checkLatestOrder(false)
    }
    this.statusInterval = setInterval(() => {
      if (this.orderType == this.$const.ORDERTYPE.DINEIN) {
        // this.$refs.headerComponent.checkLatestDineInOrder(false)
        if (!this.emptyVariable(this.localisation.code) && !this.emptyVariable(this.localisation.tableNumber)) {
          this.checkLatestDineInOrder(false)
        }
      } else {
        // this.$refs.headerComponent.checkLatestOrder(false)
        this.checkLatestOrder(false)
      }
    }, 10000)
    if (!this.emptyVariable(this.orderUuid)) {
      this.getOrderDetail(this.orderUuid, true)
    }
  },
  destroyed() {
    clearInterval(this.statusInterval)
  },
  methods: {
    ...mapActions({
      setCustomerTemp: mutationTypes.SET_CUSTOMER_TEMP,
    }),
    unableToPay() {
      const productList = this.cartData.products ? filter(this.cartData.products, o => o.is_checkout === 1) : []
      return !this.cartUuid || (this.cartUuid && (productList.length === 0 && (this.cartData.promotions && this.cartData.promotions.length === 0)))
    },
    addMore() {
      // if (this.localisation.cartUuid) {
        this.viewAllDeal()
      // }
    },
    viewStatus() {
      this.$router.push({name: 'status'});
    },
    viewAllDeal(){
        if (this.storeUuid) {
            this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
        } else {
            this.$router.push({name: 'home', query: {warning: '1', callback:'order/hot-promo'}});
            return false;
        }
    },
    initErrorMessage (mode) {
      if (mode && mode === 'inputPass') {
        this.errors = {
          isValid: false,
          orderNumber: false,
        }
      } else {
        this.errors = {
          isValid: false,
          orderNumber: false,
        }
      }
    },
    checkForm(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();

      if (!this.orderNumber) {
        this.errors.orderNumber = this.$t('message.error.order_number');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      }

      if (isValidForm) {
        this.searchOrderLatest(this.orderNumber);
      }
    },
    searchOrderLatest (orderNumber) {
      var vm = this;
      OrderApi.orderLatest({vm: this, order_number: orderNumber},  function (response) {
        vm.$router.push({name:'order-confirm', params: {uuid: response.data.uuid}});
      } ,function (error) {
        vm.notificationError(vm, error.message);
      })
    },
    getOrderDetail (uuid, flag = false) {
      const vm = this;
      let payload = {};
      if (flag) {
        payload = {vm: this};
      }
      OrderApi.orderDetail(uuid, payload,
        function (response) {
          const order_code = response.data.code
          if (flag && vm.isFired(vm.cartData.uuid)) {
            vm.eventPurchase(response.data);
            vm.setFired(vm.cartData.uuid);
          }
          if (
              vm.isAuth
              && !vm.isShowTreasureHut
              && (vm.$const.ORDERSTATUS_MAPPING_CODE.FINISHED.indexOf(response.data.order_status.code) >= 0 || response.data.is_paid === 1)) {
            const payload = { vm: vm };
            treasureHutAPI.getRewardDetail(order_code, payload,
                function (response) {
                  if (response.data !== null) {
                    vm.showTreasureHUTPopup(response.data)
                  }
                }, function (data) {
                  vm.notificationError(vm, data.message);
                }
            )
          }
        }
      )
    },
    showTreasureHUTPopup(data){
      this.isShowTreasureHut = true
      this.$modal.show(
          ModalTreasureHut, { data }
      )
    },
    isFired (id) {
      let items = JSON.parse(localStorage.getItem('OrderFired')) || [];
      let hasStorage = !(this.emptyVariable(items) || items.indexOf(id) < 0); // not exist in local storage
      let hasCookie = this.getCookie(id);
      return !(hasStorage || hasCookie);
    },
    setFired (id) {
      let items = JSON.parse(localStorage.getItem('OrderFired')) || [];
      let hasCookie = this.getCookie(id);
      if (!hasCookie) {
        this.setCookie(id, id, 30);
      }
      if (this.emptyVariable(items) || items.indexOf(id) < 0) {
        items.push(id);
        localStorage.setItem('OrderFired', JSON.stringify(items));
      }
    },
  }
}
</script>
<style scoped>
  .mobile-bottom{margin-bottom: 60px}
  .my-order-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      max-height: 100vh
  }
</style>