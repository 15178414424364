<template>
    <div>
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.dinein_reservation')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="md:flex" style="flex-direction: column; padding: 20px;" >
                        <!-- <p><span>Berlaku selama bulan Ramadhan di seluruh Restaurant Pizza Hut Indonesia</span></p>
                        <p><span><b>Khusus Reservari hari ini, booking dapat dilakukan 3 jam sebelum waktu kedatangan.</b></span></p> -->
                    </div>
                    <div class="md:flex">
                        <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm" >
                          <div class="block-section">
                              <div class="form-field flex-1 ml-5 mr-5 ">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0">{{$t('common.reservation_date')}}
                                          <span class="text-red">{{ $t('customer.required') }}</span>
                                          <span class="float-right text-red" v-if="errors.bookingDate">{{errors.bookingDate}}</span>
                                      </label>
                                  </div>
                                  <!-- <input
                                    v-model="bookingDate"
                                    class="input xs-mb-10 input-date-custom"
                                    placeholder="Select Your Booking Date"
                                    maxlength="255"
                                    :min="startDate" 
                                    :max="endDate"
                                    :type="showBookingDate ? 'date' : 'text'"
                                    @click="() => {!showBookingDate ? showBookingDate = true: ''}
                                  "> -->
                                  <div class="relative">
                                    <input
                                    v-model="bookingDate"
                                    class="input xs-mb-10 input-date-custom"
                                    placeholder="dd/mm/yyyy"
                                    maxlength="255"
                                    :min="startDate" 
                                    :max="endDate"
                                    :type="'date'"
                                    @keydown.prevent="disableArrowKeys">
                                    <p v-if="!bookingDate" class="text-grey absolute top-layer">DD/MM/YYYY</p>
                                    <p v-else="!bookingDate" class="absolute top-layer">{{ formattedDate }}</p>
                                  </div>
                              </div>
                              <div class="form-field" v-show="bookingDate">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="store">{{$t('common.title_input_search')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.storeName">{{errors.storeName}}</span>
                                        </label>
                                    </div>
                                    <div id="maps" style="height: 500px" v-show="isShowMap"></div>
                                    <div class="flex border border-gray-light rounded relative z-300 bg-white flex-col">
                                      <div class="flex items-stretch relative" >
                                        <div v-show="storeName !== ''">
                                          <p class="text-15 text-red font-bold">{{ storeName }}</p>
                                        </div>
                                        <vue-google-autocomplete
                                            ref="addressCollection"
                                            id="mapCollection"
                                            className="typography-5 search search--hut input border-0 py-15 px-10 height-49"
                                            :placeholder="$t('common.placeholder_input_carryout')"
                                            v-on:placechanged="getAddressData"
                                            v-on:inputChangeAutoComplete="changeCollection"
                                            :allowFields="['place_id', 'geometry', 'address_components']"
                                            :country="$const.COUNTRY.CODE"
                                            v-show="storeName == ''"
                                        >
                                        </vue-google-autocomplete>
                                        <div @click="resetAddressAutoComplete()"  v-if="modelDeliveryCarryout"
                                            class=" flex flex-row cursor-pointer flex-center btn--remove-auto-address "
                                            style="width: 40px; border-radius-right-width: 4px">
                                            <img src="../../assets/images/icon-remove.svg" alt="remove"/>
                                        </div>
                                      </div>
                                      <div class="store-list-contain"  v-if="stores.length > 0">
                                        <div class="store-item p-5 border-t overflow-scrolling-touch cursor-pointer" v-for="(item, indexS) in stores" :key="indexS"  @click="choseStore(item)">
                                          <div style="margin-bottom: 5px;"><img :src="item.logo" class="store_logo_carryout" style="width: 180px;"><strong> {{item.name}}</strong> ({{formatKm(item.distance, 2)}} {{$t('km')}})</div>
                                          <div>{{item.location}}</div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="form-field flex-1 ml-5 mr-5" v-show="storeName">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="pickedTime">{{$t('common.reservation_time')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.pickedTime">{{errors.pickedTime}}</span>
                                        </label>
                                        <div class="mt-10"></div>
                                        <div class="flex items-center h-full cursor-pointer w-full" @click="showAsapPopup">
                                          <i class="icon-clock mr-5"></i>
                                          <button class="border-solid border-grey-lightest border-b  border-l-0 border-t-0 border-r-0 text-black semi-bold" type="button" v-if="pickedTime"><span class="uppercase">{{pickedTime}}</span></button>
                                          <button class="border-solid border-grey-lightest border-b  border-l-0 border-t-0 border-r-0 text-black semi-bold text-grey" type="button" v-else><span class="">Pilih Waktu Kedatangan </span></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-field flex-1 ml-5 mr-5 " v-show="pickedTime !== ''">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="seatCapacity">{{$t('common.seat_capacity')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.seatCapacity">{{errors.seatCapacity}}</span>
                                        </label>
                                    </div>
                                    <select class="custom-dropdown-select p-10 w-full typography-6 bold mb-10" v-model="seatCapacity">
                                      <option v-for="item in configSeatCapacity" :value="item" :key="item">{{ item }}</option>
                                    </select>
                                </div>
                              <div class="form-field flex-1 ml-5 mr-5 " v-show="seatCapacity">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0" for="name">{{$t('customer.name')}}
                                          <span class="text-red">{{ $t('customer.required') }}</span>
                                          <span class="float-right text-red" v-if="errors.name">{{errors.name}}</span>
                                        </label>
                                    </div>
                                    <input id="name" class="input xs-mb-10" ref="name" v-bind:class="{'has-error': errors.name, 'has-success': errors.name === false}"
                                          name="name" v-model="name" placeholder="Masukkan nama kamu"
                                          maxlength="255" type="text">
                                </div>
                                <div class="form-field" v-show="name">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="phone">{{$t('customer.phone')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.phone">{{errors.phone}}</span>
                                        </label>
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-append" ><span class="input-group-text" >{{$const.PREFIX_PHONE}}</span></div>
                                        <input id="phone" class="input xs-mb-10 form-control" ref="phone" v-bind:class="{'has-error': errors.phone, 'has-success': errors.phone === false}"
                                              name="phone" v-model="phone" placeholder="Masukkan nomor hp kamu"
                                              maxlength="30" type="number">
                                    </div>
                                </div>

                                <div class="form-field" v-show="phone">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="email">{{$t('customer.email')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.email">{{errors.email}}</span>
                                        </label>
                                    </div>
                                    <input id="email" class="input xs-mb-10" ref="email" v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                                          name="email" v-model="email" placeholder="Masukkan email kamu"
                                          maxlength="255" type="email">
                                </div>
                                <div class="form-field" v-show="email">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="message">{{$t('common.message')}}
                                            <span style="color: #b6b6b6"> (Optional)</span>
                                        </label>

                                    </div>
                                    <textarea v-model="message"
                                              id="message"
                                              ref="message" class="input xs-mb-10"
                                              placeholder="Smoking / Not Smoking"
                                              name="message"
                                              rows="3"></textarea>
                                </div>
                            </div>

                            <div class="block-section">
                                <div>
                                    <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.submit')}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import types from '../../store/mutationTypes';
  import { mapActions, mapGetters } from 'vuex';
  import NotificationApi from '../../services/notification';
  import localizationAPI from '../../services/localization';
  import SystemApi from '../../services/system'
  import ModalOrderTime from '../../components/modal/ModalOrderTime';
  import ModalNotifyBlack from '../../components/modal/ModalNotifyBlack';
  import LocalisationBox from '../../components/shared/LocalisationBox';
  import VueGoogleAutocomplete from '../../components/shared/GoogleAutocompleteCustom';
  import moment from 'moment';
  /* eslint-disable */
  export default {
    name: "reservation",
    components: {
      vSelect,
      LocalisationBox,
      'vue-google-autocomplete': VueGoogleAutocomplete,
      ModalNotifyBlack,
    },
    data() {
    return {
      query: {},
      pickedTime : '',
      modelDeliveryCarryout: '',
      configSeatCapacity: {},
      email: '',
      name: '',
      phone: '',
      message: '',
      showBookingDate: true,
      bookingDate: '',
      startDate: '',
      endDate: '',
      configBusinessHours: {},
      businessHours: [],
      businessHoursCustom: [],
      seatCapacity: undefined,
      errors: {
        isValid: false,
        name: '',
        phone: '',
        email: '',
        storeName: '',
        seatCapacity: '',
        bookingDate: '',
        pickedTime: '',
      },
      latlngCarryout: {
        lat: null,
        lng: null
      },
      isShowMap: false,
      stores: []
    }
    },
    computed: {
      ...mapGetters({
          isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
          localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
          collection_time: 'moduleLocalisation/' +  types.LOCALISATION_GET_COLLECTION_TIME,
          storeName:  'moduleLocalisation/' + types.LOCALISATION_GET_STORE_NAME,
          isShowLoading:  types.IS_SHOW_LOADING,
          defaultCategory:  types.GET_DEFAULT_CATEGORY,
      }),
      formattedDate() {
        if (!this.bookingDate) return '';
        const [year, month, day] = this.bookingDate.split('-');
        return `${day}/${month}/${year}`; // mengubah format menjadi dd/mm/yyyy
      },
    },
    async mounted() {
      this.$router.push({name: 'error-404'});
      this.resetLocalisation()
      await this.getConfigByKey("reservation_event")
      await this.getConfigByKey("business_hours")
      await this.getConfigByKey("seat_capacity")
      this.$root.$on('collectionTimeUpdate', () => {
        this.pickedTime = this.getTime(this);
      });
      this.pickedTime = this.getTime(this)
      var vm = this;
      window.initMap = function() {
          vm.available = true;
          vm.initMapGoogle();
      }
      this.loadMapApi()
    },
    watch: {
      'phone': function (newValue) {
        this.errors.phone = false;
        if (!newValue) {
          this.errors.phone = this.$t('message.error.phone');
        }else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX});
        }
      },
      'email': function (newValue) {
        this.errors.email = false;
        if (!newValue) {
          this.errors.email = this.$t('message.error.email');
        }else if (!this.validEmail(newValue)) {
          this.errors.email = this.$t('message.error.invalid_email');
        }
      },
      'name': function (newValue) {
        this.errors.name = false;
        if (!newValue) {
          this.errors.name = this.$t('message.error.name');
        }
      },
      'message': function (newValue) {
        this.errors.message = false;
        if (!newValue) {
          this.errors.message = this.$t('message.error.message');
        }
      },
      'seatCapacity': function (newValue) {
        this.errors.seatCapacity = false;
        if (!newValue) {
          this.errors.seatCapacity = this.$t('message.error.seat_capacity');
        }
      },
      'bookingDate': function (newValue) {
        this.errors.bookingDate = false;
        if (!newValue) {
          this.errors.bookingDate = this.$t('message.error.booking_date');
        } else {
          this.resetAddressAutoComplete()
        }
      },
      'store_name': function (newValue) {
        this.errors.store_name = false;
        if (!newValue) {
          this.errors.store_name = this.$t('message.error.store_name');
        }
      },
      'pickedTime': function (newValue) {
        this.errors.pickedTime = false;
        if (!newValue) {
          this.errors.pickedTime = this.$t('message.error.reservation_time');
        }
      },
    },
    methods: {
      ...mapActions({
          saveLocalisation:  'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
          resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      }),
      initErrorMessage () {
        this.errors = {
          isValid: false,
          name: false,
          phone: false,
          email: false,
          storeName: false,
          bookingDate: false,
          seatCapacity: false,
          pickedTime: false,
        }
      },
      loadMapApi() {
          (function (d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0]
              if (d.getElementById(id)){initMap();return}
              js = d.createElement(s);
              js.id = id
              js.async = true
              // js.defer = true
              js.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyApvJen7vuzOfRjJioNaQkZLqenwEPaV9s&libraries=places&region=id&callback=initMap'
              fjs.parentNode.insertBefore(js, fjs)
          }(document, 'script', 'google-maps-jssdk12'))
      },
      initMapGoogle() {
          this.mapRoot = new google.maps.Map(document.getElementById('maps'), {
              center: {lat: this.$const.LOCATION.LAT, lng: this.$const.LOCATION.LNG},
              zoom: 15,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
          })
          let myLatlng = new google.maps.LatLng(this.$const.LOCATION.LAT, this.$const.LOCATION.LNG);
          // this.initMaker(myLatlng);
      },
      showAsapPopup(){
        this.$modal.show(
          ModalOrderTime,
          {
            businessHoursCustom: this.businessHoursCustom,
            isReservation: true
          },
          {
            classes:(this.isMobile()) ? 'modal-order-time' : ''
          }
        )
      },
      async getConfigByKey(keyName) {
        this.query.key = keyName
        await SystemApi.getConfig({vm: this}, 
          (response) => {
            const result = response.data
            if (keyName == 'business_hours') {
              this.businessHours = this.generateTimeSlots(result.business_hours)
              this.configBusinessHours = result.business_hours
            } else if (keyName == 'seat_capacity') {
              this.configSeatCapacity = parseInt(result.seat_capacity.max)
            } else if (keyName == 'reservation_event') {
              const today = new Date().toISOString().split('T')[0];
              if (result.reservation_event.start_date > today) {
                this.startDate = result.reservation_event.start_date
              } else {
                this.startDate = today
              }
              this.endDate = result.reservation_event.end_date
            }
          },
        )
      },
      getAddressData: function (addressData, placeResultData, id) {
        if (this.orderType == this.$const.ORDERTYPE.DELIVERY) {
          this.latlng = new google.maps.LatLng(addressData.latitude, addressData.longitude);
          this.showMapDelivery(addressData.latitude, addressData.longitude)
        } else {
          this.latlngCarryout.lat = addressData.latitude;
          this.latlngCarryout.lng = addressData.longitude;
          this.getStoreCollection(addressData.latitude, addressData.longitude)
        }
      },
      changeCollection(data){
        this.modelDeliveryCarryout = data.newVal;
        if (this.emptyVariable(data.newVal)) {
          this.stores = []
        }
      },
      resetAddressAutoComplete() {
        this.$refs.addressCollection.update( '' );
        this.resetLocalisation()
      },
      choseStore(item) {
        if (this.emptyVariable(item)) {
          return false;
        }
        this.beforeHandleSaveStoreCarryout(item);
      },
      beforeHandleSaveStoreCarryout (data)  {
        const vm = this
        // no need check available key for carryout
        if (this.emptyVariable(data.business_hours) || this.checkClosedStore(data.business_hours)) {
          this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.$t('message.error.reservation_closed')},
            {resizable: false, clickToClose: true,
              classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
          return false;
        }

        vm.stores = []
        this.handleSaveStore(data);
      },
      handleSaveStore (data) {
        let storeData = this.localisation;
        let business_hours = data.business_hours[0]
        const currentDate = moment().format('YYYY-MM-DD');
        let threeHoursLater
        let filteredTimeSlot = this.businessHours

        if (moment(currentDate).isSame(this.bookingDate)) {
          threeHoursLater = moment().tz('Asia/Jakarta').add(3, 'hours').format('HH:mm:ss');
          filteredTimeSlot = filteredTimeSlot.filter(time => time > threeHoursLater);
        }
        const twoHoursBeforeClosing = moment.tz(this.configBusinessHours.closing_time, "HH:mm:ss", 'Asia/Jakarta').subtract(2, 'hours').format('HH:mm:ss');
        filteredTimeSlot = filteredTimeSlot.filter(time => time <= twoHoursBeforeClosing);
        filteredTimeSlot = filteredTimeSlot.map(item => this.bookingDate + item)

        this.businessHoursCustom = [
          {
            stack: filteredTimeSlot,
            start_at: business_hours.start_at,
            waiting_time: business_hours.waiting_time
          }
        ]
        let storeDataNew = {
          "note": data.hasOwnProperty('note') ? data.note : '',
          "clientUUID": data.client_uuid || '',
          "code": data.code || '',
          "distance": data.distance || 0,
          "lat": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lat : data.lat,
          "location": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.deliveryAddress.replace(', Indonesia', '') : '',
          "addressStore": data.location,
          "long": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lng : data.long,
          "name": data.name,
          "tierUUID": data.tier_uuid || null,
          "uuid": data.uuid,
          "timezone": data.timezone,
          "deliveryInfo": {},
          "businessHours": data.business_hours,
          "orderType": this.orderType,
          "storeType": data.store_type_id,
          "storeBranch": data.store_branch
        }
        this.saveLocalisation({...storeData, ...storeDataNew});
      },
      getStoreCollection (lat, long) {
        let params = {
          lat: lat,
          long: long,
          now_order: this.orderNow,
          // store_type_id: this.$const.TYPE_HUT.PHR,
          // datetime: this.convertDateTimeFromTimezoneToUTC(this.timezone, moment().format('YYYY-MM-DD HH:mm:ss')),
          datetime: moment(this.bookingDate).add(3, 'hours').tz('Asia/Jakarta').format('YYYY-MM-DD HH:mm:ss'),
          vm: this
        }
        var vm = this;
        let phr_branch = [2,3,7]
        vm.stores = [];
        localizationAPI.getStoreCollection(params, function (response) {
          if (response.code == 200) {
            vm.stores = [];
            if (response.data.length <= 0) {
              vm.notificationError(vm, vm.$t('stores.not_found'));
            } else {
              for (let i in response.data) {
                if (phr_branch.includes(response.data[i].store_type_id)) {
                  if ( vm.stores.length <= 4) {
                    vm.stores.push(response.data[i]);
                  } else {
                    break;
                  }
                }
              }
            }
          }
        }, function (error) {
          vm.$modal.show(ModalNotification, {hideOK: true, contain: error.message}, {resizable: false, clickToClose: true, classes: 'modal-notification-custom'});
        })
      },
      getTime(vm) {
        let result = ''
        if(vm.localisation.collectionTime && vm.localisation.collectionTime.pickedHours != ''){
          let now = moment()
          let tomorrow = moment().add(1, 'days')
          if(vm.localisation.collectionTime.pickedDay == now.format('YYYY-MM-DD')){
            result = vm.localisation.collectionTime.pickedHours
          }else if(vm.localisation.collectionTime.pickedDay == tomorrow.format('YYYY-MM-DD')){
            result = vm.localisation.collectionTime.pickedHours
          }else {
            result = vm.localisation.collectionTime.pickedDay + ' ' + vm.localisation.collectionTime.pickedHours
          }
        }else{
          result = ""
        }

        if (result.trim() === '') {
            result = ""
        }
        return result
      },
      resetForm() {
        this.email = ''
        this.name = ''
        this.bookingDate = ''
        this.message = ''
        this.phone = ''
        this.seatCapacity = ''
        this.pickedTime = ''
      },
      disableArrowKeys(event) {
        if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(event.key)) {
          event.preventDefault();
        }
      },
      checkForm(e) {
        e.preventDefault();
        let isValidForm = true;
        this.initErrorMessage();

        if (!this.name) {
            this.errors.name = this.$t('message.error.name');
            if (isValidForm) {
              this.$refs.name.focus();
            }
            isValidForm = false;
        }

        if (!this.phone) {
          this.errors.phone = this.$t('message.error.phone');
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        } else if (this.phone.length < this.$const.PHONE.MIN || this.phone.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX})
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        }

        if (!this.email) {
          this.errors.email = this.$t('message.error.email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        } else if (!this.validEmail(this.email)) {
          this.errors.email = this.$t('message.error.invalid_email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }
        if (!this.seatCapacity) {
            this.errors.seatCapacity = this.$t('message.error.seat_capacity');
            if (isValidForm) {
              this.$refs.seatCapacity.focus();
            }
            isValidForm = false;
        }
        if (!this.bookingDate) {
            this.errors.bookingDate = this.$t('message.error.booking_date');
            if (isValidForm) {
              this.$refs.bookingDate.focus();
            }
            isValidForm = false;
        }
        if (!this.storeName) {
            this.errors.storeName = this.$t('message.error.store');
            if (isValidForm) {
              this.$refs.mapCollection.focus();
            }
            isValidForm = false;
        }
        if (!this.pickedTime) {
            this.errors.pickedTime = this.$t('message.error.reservation_time');
            if (isValidForm) {
              this.$refs.pickedTime.focus();
            }
            isValidForm = false;
        }

        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm () {
        var vm  = this;
        let payload = {
          "client_uuid": this.$const.CLIENT_UUID,
          "store_type_id": this.$const.TYPE_HUT.PHR,
          "name": this.name,
          "phone": this.setPhonePrefix(this.phone, this.$const.PREFIX_PHONE),
          "email": this.email,
          "cc": this.$const.EMAIL_CSC,
          "store": this.storeName,
          "store_code": this.localisation.code,
          "store_address": this.localisation.addressStore,
          "date": this.formattedDate,
          "time": this.pickedTime,
          "seat_capacity": this.seatCapacity,
          "comment": this.message,
          "template_code": this.$const.EMAIL_TEMPLATE.RESERVATION,
          "subject": "RESERVATION",
          "vm": this
        }
        NotificationApi.sentEmail(payload,
          function (response) {
            vm.notificationSuccess(vm, vm.$t('message.success.send_contact'));
            if(response.code == 200) {
                vm.$router.push({name: 'home'})
            }
          }, function (data) {
            vm.notificationError(vm, data.message);
          }
        )
      }
    }
  }
  </script>

  <style scoped>
    .input-date-custom::-webkit-datetime-edit, .input-date-custom::-webkit-inner-spin-button, .input-date-custom::-webkit-clear-button {
      color: rgba(0, 0, 0, 0);
      opacity: 0;
    }

    .input-date-custom::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
    }

    .input-date-custom::placeholder {
      color: rgb(0, 0, 0);
      opacity: 1;
    }

    .top-layer {
      top: 50%;
      left: 3%;
      transform: translateY(-50%);
    }
  </style>
