import types from '../store/mutationTypes'
import { mapActions, mapGetters } from 'vuex'
import OrderApi from '../services/order'
import StoreApi from '../services/store'

// export default {
export const orderMixins = {
  data: function () {
    return {
        invalidStatusCodes: [this.$const.ORDERSTATUS.FINISHED, this.$const.ORDERSTATUS.CANCELED, this.$const.ORDERSTATUS.REJECTED],
        invalidRoute: ['my-order', 'status', 'home', 'category'],
        segment: undefined,
        codeStore: undefined,
        tableNum: undefined,
    }
  },
  mounted() {
    if (this.orderDetails) {
      if (this.orderDetails.order_type || this.orderDetails.store) {
        this.segment = this.orderDetails.order_type.code === this.$const.ORDERTYPE.TAKEAWAY ? this.$const.ORDERTYPE.CARRYOUT : this.orderDetails.order_type.code 
        this.codeStore = this.orderDetails.store.code
      }
    }
    this.changeOrderType(this.segment)
  },
  computed: {
    ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      cartData: 'moduleCart/' + types.CART_DETAIL,
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
      defaultCategory:  types.GET_DEFAULT_CATEGORY,
      orderCode: 'moduleOrder/' + types.ORDER_GET_ORDER_CODE,
      orderStatusCode: 'moduleOrder/' + types.ORDER_GET_ORDER_STATUS_CODE,
      orderDetails: 'moduleOrder/' + types.ORDER_GET_ORDER_INFORMATION,
      localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      visitorID:  types.GET_VISITOR_ID,
      deepLink:  types.GET_DEEP_LINK,
      coordinates:  types.GET_COORDINATES,
    }),
  },
  methods: {
    ...mapActions ({
      logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
      saveOrder: 'moduleOrder/' + types.ORDER_SAVE_ITEM,
      resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
      resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
      setStore: 'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
      getCartDetail: 'moduleCart/' + types.CART_GET_CART_DETAIL,
      getCartCheckout: 'moduleCart/' + types.CART_GET_CART_CHECKOUT_DETAIL,
    }),
    changeHasStore(value) {
      this.$root.$emit('hasStore', value)
    },
    changeOrderType(value) {
      if (value == undefined) {
        if(!this.emptyVariable(this.localisation.orderType)) {
          value = this.localisation.orderType
        } else {
          value = this.$const.ORDERTYPE.CARRYOUT
        }
      }
      this.$root.$emit('orderType', value)
    },
    async checkLatestDineInOrder(loader = true, tryToReset = false){
      let vm = this
      let dateNow = Date.now()
      let data = {vm}

      let payloadOrderLatestDineIn = {
        ...data,
        loader: loader,
        v: dateNow,
      }
      if (this.orderCode) {
        payloadOrderLatestDineIn.order_number = this.orderCode
      }

      if (this.isAuth) {
        await OrderApi.orderLatestDineIn(payloadOrderLatestDineIn, 
        (response) => {
          this.goToConfirmPage = true
          if (!this.invalidStatusCodes.includes(response.data.order_status.name)) {
            this.saveOrder({
              order_code: response.data.code, 
              order_uuid: response.data.uuid, 
              order_status_code: response.data.order_status.code, 
              order_status_uuid: response.data.order_status.uuid, 
              collection_time: response.data.collection_time,
              order_information: response.data
            })
            this.segment = response.data.order_type.code
            this.codeStore = response.data.store.code
            this.tableNum = response.data.table_number
            if (!this.emptyVariable(response.data.cart_uuid)){
              if (this.$route.name == 'payment' && this.localisation.orderType == this.$const.ORDERTYPE.DINEIN) {
                this.getCartCheckout({payload: {uuid: response.data.cart_uuid, loader: loader}});
              } else if (this.$route.name == 'payment' && this.localisation.orderType != this.$const.ORDERTYPE.DINEIN) {
                this.getCartDetail({payload: {uuid: response.data.cart_uuid, loader: loader}});
              }
            }
            this.setLocalisation()
            this.changeOrderType(this.segment)
          } else {
            this.orderIsFinished()
          }
        },
        (response) => {
          if (response.code == 6102) {
            if (!this.emptyVariable(this.localisation.code) && !this.emptyVariable(this.localisation.tableNumber)) {
              this.checkOrderTable(tryToReset)
            }
          }
        })
      } else {
        await OrderApi.orderLatest(payloadOrderLatestDineIn, 
        (response) => {
          this.goToConfirmPage = true
          if (!this.invalidStatusCodes.includes(response.data.order_status.name)) {
            this.saveOrder({
              order_code: response.data.code, 
              order_uuid: response.data.uuid, 
              order_status_code: response.data.order_status.code, 
              order_status_uuid: response.data.order_status.uuid, 
              collection_time: response.data.collection_time,
              order_information: response.data
            })
            this.segment = response.data.order_type.code
            this.codeStore = response.data.store.code
            this.tableNum = response.data.table_number
            if (!this.emptyVariable(this.cartData.information.cart_uuid)) {
              if (this.$route.name == 'payment' && this.localisation.orderType == this.$const.ORDERTYPE.DINEIN) {
                this.getCartCheckout({payload: {uuid: response.data.cart_uuid, loader: loader}});
              } else if (this.$route.name == 'payment' && this.localisation.orderType != this.$const.ORDERTYPE.DINEIN) {
                this.getCartDetail({payload: {uuid: response.data.cart_uuid, loader: loader}});
              }
            }
            this.setLocalisation()
            this.changeOrderType(this.segment)
          } else {
            this.orderIsFinished()
          }
        },
        (response) => {
          if (response.code == 6102 || response.code == 6103) {
            if (!this.emptyVariable(this.localisation.code) && !this.emptyVariable(this.localisation.tableNumber)) {
              this.checkOrderTableGuest()
            }
          }
        })
      }
    },
    async checkLatestOrder(loader = true, showNotif = false, tryToReset = false){
      let vm = this
      let dateNow = Date.now()
      let data = {vm}

      let payloadOrderLatest = {
        ...data,
        loader: loader,
        v: dateNow,
      }
      if (vm.orderCode) {
        payloadOrderLatest.order_number = vm.orderCode
      }

      await OrderApi.orderLatest(payloadOrderLatest, 
      (response) => {
        if (!this.invalidStatusCodes.includes(response.data.order_status.name)) {
          this.saveOrder({
            order_code: response.data.code, 
            order_uuid: response.data.uuid, 
            order_status_code: response.data.order_status.code, 
            order_status_uuid: response.data.order_status.uuid, 
            collection_time: response.data.collection_time,
            order_information: response.data
          })
          if (showNotif == true) {
            vm.notificationError(vm, vm.$t('message.error.complete_order'))
          }
          this.segment = response.data.order_type.code
          this.codeStore = response.data.store.code
          this.tableNum = response.data.table_number
          if (!this.emptyVariable(this.cartData.information.cart_uuid)) {
            if (this.$route.name == 'payment' && this.localisation.orderType == this.$const.ORDERTYPE.DINEIN) {
              this.getCartCheckout({payload: {uuid: response.data.cart_uuid, loader: loader}});
            } else if (this.$route.name == 'payment' && this.localisation.orderType != this.$const.ORDERTYPE.DINEIN) {
              this.getCartDetail({payload: {uuid: response.data.cart_uuid, loader: loader}});
            }
          }
          this.setLocalisation()
          this.changeOrderType(this.segment)
        } else {
          this.orderIsFinished()
        }
      },
      (response) => {
        if (response.code == 6102 || response.code == 6103) {
          if (!this.emptyVariable(this.localisation.code) && !this.emptyVariable(this.localisation.tableNumber)) {
            this.checkOrderTableGuest()
          }
        }
      })
    },
    async checkOrderTable(tryToReset = false) {
      let vm = this
      let data = {vm}
      
      let payloadOrderTable = {
        ...data,
        store_code:this.localisation.code,
        table_number: this.localisation.tableNumber,
        lat: this.coordinates.lat,
        long: this.coordinates.long,
        loader: false,
        device_id: this.visitorID,
        deep_link: this.deepLink
      }
      await OrderApi.validateTable(payloadOrderTable, 
      (response) => {
        if (!this.invalidStatusCodes.includes(response.data.order_status.name)) {
          this.saveOrder({
            order_code: response.data.code,
            order_uuid: response.data.uuid,
            order_status_code: response.data.order_status.code, 
            order_status_uuid: response.data.order_status.uuid,
            collection_time: response.data.collection_time,
            order_information: response.data
          })
        } else {
          this.orderIsFinished()
        }
      }, 
      () => {
        if (tryToReset) {
          this.orderIsFinished()
        }
        if (this.orderCode && !this.$route.query.confirm_order) {
          this.orderIsFinished()
          this.$router.push({name: 'home'})
        }
      })
    },
    async checkOrderTableGuest(tryToReset = false) {
      let vm = this
      let data = {vm}
      
      let payloadOrderTable = {
        ...data,
        store_code:this.localisation.code,
        table_number: this.localisation.tableNumber,
        lat: this.coordinates.lat,
        long: this.coordinates.long,
        loader: false,
        device_id: this.visitorID,
        deep_link: this.deepLink
      }
      await OrderApi.validateTableGuest(payloadOrderTable, 
      (response) => {
        if (!this.invalidStatusCodes.includes(response.data.order_status.name)) {
          this.saveOrder({
            order_code: response.data.code,
            order_uuid: response.data.uuid,
            order_status_code: response.data.order_status.code, 
            order_status_uuid: response.data.order_status.uuid,
            collection_time: response.data.collection_time,
            order_information: response.data
          })
        } else {
          this.orderIsFinished()
        }
      }, 
      () => {
        if (tryToReset) {
          this.orderIsFinished()
        }
        if (this.orderCode && !this.$route.query.confirm_order) {
          this.orderIsFinished()
          this.$router.push({name: 'home'})
        }
      })
    },
    orderIsFinished() {
      if (this.invalidRoute.includes(this.$route.name)) {
        if (this.orderCode) {
          this.notificationSuccess(this, this.$t('message.success.order_finished'))
          if (this.$route.name == this.$const.ROUTE.CATEGORY) {
            this.$router.push({name: 'home'})
          }
        }
        this.changeHasStore(false)
        clearInterval(this.$parent.statusInterval)
        this.resetOrder()
        this.resetLocalisation()
        this.resetCart()
      }
    },
    async setLocalisation(loader = true) {
      if (this.segment === this.$const.ORDERTYPE.TAKEAWAY) {
        this.segment = this.$const.ORDERTYPE.CARRYOUT
      }
      let vm = this
      let data = {...vm, loader: loader}
      await StoreApi.searchByCode(this.codeStore, data, 
        (response) => {
          let store = response.data
          let storeDataNew = {
            "note": store.hasOwnProperty('note') ? store.note : '',
            "clientUUID": store.client_uuid || '',
            "code": store.code || '',
            "distance": store.distance || 0,
            "lat": this.segment === this.$const.ORDERTYPE.DELIVERY && this.latlngDelivery ? this.latlngDelivery.lat : store.lat,
            "location": this.segment === this.$const.ORDERTYPE.DELIVERY && this.deliveryAddress ? this.deliveryAddress.replace(', Indonesia', '') : '',
            "addressStore": store.location,
            "long": this.segment === this.$const.ORDERTYPE.DELIVERY && this.latlngDelivery ? this.latlngDelivery.lng : store.long,
            "name": store.name,
            "tierUUID": store.tier_uuid || null,
            "uuid": store.uuid,
            "timezone": store.timezone,
            "deliveryInfo": {},
            "businessHours": store.business_hours,
            "orderNow": this.orderNow,
            "orderType": this.segment,
            "storeType": store.store_type_id,
            "storeBranch": store.store_branch,
            "tableNumber": this.tableNum ? this.tableNum : null
          }
          this.setStore(storeDataNew)
          this.changeHasStore(true)
        }
      )
    }
  }
}