<template>
    <div>
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.outlet_location')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="p-20">
                        <div>{{$t('common.store_directory_description')}}</div>
                        <input class="input border border-grey-lightest  rounded  focus:border-grey-lightest typography-5" name="filter" id="filter" v-model="filter">
                    </div>
                    <div class="flex flex-wrap ">
                        <div v-for="(store, indexS) in filterStore" :key="indexS" class="store-item">
                            <div>
                                <div class=""> <router-link class="bold t17 underline" :to="{name: 'store-detail', params: {slug: store.slug, uuid: store.uuid}}"> {{store.name}}</router-link></div>
                                <div class=""> {{store.location}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import HeaderComponent from "../components/HeaderComponent";
  import localizationAPI from '../services/localization';

  export default {
    name: "stores",
    components: {HeaderComponent},
    computed: {
      filterStore: function () {
        if (this.filter === '') {
          return this.stores;
        }
        var filter = this.filter;
        return _.filter(
            this.stores,
            function(o) {
                return o.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || o.location.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            });
      }
    },
    data() {
      return {
        filter: '',
        stores: []
      }
    },
    mounted() {
      this.getStores();
    },
    methods: {
      getStores () {
        var vm = this;
        localizationAPI.stores({vm: this}, function (response) {
          const stores = response.data.map(row => {
            if (row.name.toLowerCase().indexOf('test') >= 0){
              return {}
            }
            const originName = row.name
            row.name = row.name.replace('PHD', '')
            row.name = row.name.replace('Pizza Hut', '')
            row.name = row.name.trim()
            return {
              uuid: row.uuid,
              name: originName,
              name_short: row.name,
              location: row.location,
              slug: row.slug,
            }
          })
          vm.stores = stores.filter(element => {
            if (Object.keys(element).length !== 0) {
              return true;
            }
            return false;
          });
          vm.stores.sort((a, b) => (a.name_short > b.name_short ? 1 : -1))
        })
      },
    }
  }
</script>

<style scoped>
    .store-item{
        margin: 6px;
        -ms-flex-preferred-size: calc(50% - 12px);
        flex-basis: calc(50% - 12px);
        max-width: calc(50% - 12px);
    }
</style>
