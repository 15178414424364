<template>
    <div :class="{'map-open': isShowMap}">
        <div class="is-login home-localise-product-promo flex justify-start items-center flex-col pt-200 _lg:pt-250" id="home-localise-product-promo" :style="`background-image: ${url}`" ref="homeBanner">
            <div class="home-localise-content mb-30 pb-10 z-200 w-full" :class="{'z-index-1000-important': $route.query.warning && $route.query.warning == '1'}">
                <div class="localisation-form relative mx-auto z-400 rounded-b" style="box-shadow: rgba(202, 73, 73, 0.3) 0px 20px 22px 0px;" v-bind:class="{ 'is-desktop': !isMobile(), 'is-mobile': isMobile()}">
                    <div class="bg-white border-rounded" v-if="hasStore">
                        <div  class="p-10">
                            <p ><span  class="order-type-text border-b t14">
                            <span v-if="orderType == $const.ORDERTYPE.DELIVERY">{{$t('orders.delivery')}} </span>
                            <span v-else-if="orderType == $const.ORDERTYPE.CARRYOUT || orderType == $const.ORDERTYPE.TAKEAWAY">{{$t('common.ordering_for')}}  {{$t('orders.collection')}} </span>
                            <span v-else-if="orderType == $const.ORDERTYPE.DINEIN">{{$t('common.dinein_at')}} </span>
                            </span>
                                <span class="t14" v-if="orderType == $const.ORDERTYPE.DELIVERY">{{$t('common.to')}} </span>
                                <span class="t14" v-else-if="orderType == $const.ORDERTYPE.CARRYOUT || orderType == $const.ORDERTYPE.TAKEAWAY">{{$t('common.from')}} </span>
                                <span class="t14" v-else-if="orderType == $const.ORDERTYPE.DINEIN">{{$t('common.at')}} </span>
                                <span class="border-b" v-if="orderType == $const.ORDERTYPE.DELIVERY">
                              {{storeLocation}}
                            </span>
                                <span class="border-b" v-else>
                              {{storeName}}
                            </span>
                            </p>
                            <div  class="p-10 pb-0" v-if="this.localisationInfo.is_qr_code != 1">
                                <button class="btn btn-default w-full bg" style="min-height: 42px; background-color: rgba(204, 204, 204, 0.27);" @click="changeLocalization"> {{$t('common.btn.change')}}</button>
                            </div>
                            <div class="p-10">
                                <button class="button button--red flex-1  px-20" style="min-height: 42px;" @click="$router.push('/order/' + defaultCategory)"> {{$t('common.btn.view_all_menu')}}</button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="flex text-glitch-fix tab-contain" v-if="orderType == $const.ORDERTYPE.DELIVERY">
                            <div class="flex-center flex-auto min-w-0 self-end p-10 bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-4 align-tab-text bg-white text-red relative z-200 py-15" role="button" tabindex="0" style="margin-right: 2px;"><i class="icon-delivery mr-5 hidden md:block"></i><span class="capitalize">{{$t('common.delivery')}}</span></div>
                            <div class="flex-center flex-auto min-w-0 self-end p-10 bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-4 align-tab-text bg-grey-lighter text-grey-darker grayscale" v-on:click="changeMenu($const.ORDERTYPE.CARRYOUT)" role="button" tabindex="0"><i class="icon-collection mr-5 hidden md:block"></i><span class="capitalize">{{$t('common.collection')}}</span></div>
                        </div>
                        <div class="title-map hidden">
                            <i class="ml-5 p-10 icon-caret-left cursor-pointer"  @click="isShowMap = false" ></i>
                            <div class="flex-1">{{$t('common.address_search')}}</div>
                        </div>

                        <div class="flex text-glitch-fix" v-if="orderType == $const.ORDERTYPE.CARRYOUT">
                            <div class="flex-center flex-auto min-w-0 self-end p-10 bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-4 align-tab-text bg-grey-lighter text-grey-darker grayscale" v-on:click="changeMenu($const.ORDERTYPE.DELIVERY)" role="button" tabindex="0" style="margin-right: 2px;"><i class="icon-delivery mr-5 hidden md:block"></i><span class="capitalize">{{$t('common.delivery')}}</span></div>
                            <div class="flex-center flex-auto min-w-0 self-end p-10 bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-4 align-tab-text bg-white text-red relative z-200 py-15" role="button" tabindex="0"><i class="icon-collection mr-5 hidden md:block"></i><span class="capitalize">{{$t('common.collection')}}</span></div>
                        </div>

                        <div class="flex text-glitch-fix" v-if="orderType == $const.ORDERTYPE.DINEIN">
                            <div class="flex-center flex-auto min-w-0 self-end p-10 bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-4 align-tab-text bg-grey-lighter text-grey-darker grayscale" v-on:click="changeMenu($const.ORDERTYPE.DELIVERY)" role="button" tabindex="0" style="margin-right: 2px;"><i class="icon-delivery mr-5 hidden md:block"></i><span class="capitalize">{{$t('common.delivery')}}</span></div>
                            <div class="flex-center flex-auto min-w-0 self-end p-10 bold rounded-t no-outline whitespace-no-wrap text-glitch-fix typography-4 align-tab-text bg-white text-red relative z-200 py-15" role="button" tabindex="0"><i class="icon-collection mr-5 hidden md:block"></i><span class="capitalize">{{$t('common.collection')}}</span></div>
                        </div>

                        <div class="localisation bg-white p-20 rounded-b" v-show="orderType == $const.ORDERTYPE.DELIVERY">
                            <div class="background-fed03b arrow-bottom-yellow" v-if="$route.query.warning && $route.query.warning == '1' && this.showWarning" >{{$t('common.missing_localisation')}}</div>
                            <v-select  v-if="savedLocations.length > 0"
                                       :options="savedLocations" v-model="selectedSavedLocation"  class="w-full typography-6 bold mb-10" name="SavedLocations">
                            </v-select>
                            <div class="title-search-localization" :class="{'hidden': $route.query.warning && $route.query.warning == '1'}" v-if="available">{{$t('common.title_input_search')}}</div>
                            <div class="flex items-stretch relative  rounded border border-grey-lightest focus:border-grey-lightest" v-if="available">
                                <vue-google-autocomplete
                                    ref="addressDelivery"
                                    id="mapDelivery"
                                    className="input typography-5 height-49 border-0 "
                                    :placeholder="$t('common.placeholder_input_delivery')"
                                    v-on:placechanged="getAddressData"
                                    v-on:inputChangeAutoComplete="mappingAddressInput"
                                    :allowFields="['place_id', 'geometry', 'address_components', 'name', 'types']"
                                    :country="$const.COUNTRY.CODE"
                                >
                                </vue-google-autocomplete>
                                <div @click="resetAddressAutoComplete()"  v-if="modelDeliveryAddress"
                                     class=" flex flex-row cursor-pointer flex-center btn--remove-auto-address "
                                     style="width: 40px; border-radius-right-width: 4px">
                                    <img src="../../assets/images/icon-remove.svg" alt="remove"/>
                                </div>
                                <button class="button button--red w-auto btn--search-address" type="button" v-if="!modelDeliveryAddress" v-on:click="searchLocation">Search</button>
                            </div>
                            <div class="pt-5 pb-5 overflow-scrolling-touch border border-gray-light rounded rounded-t-r-none-important rounded-t-l-none-important border-t-0 flex  flex-center" style="max-height: 350px; overflow-y: auto;">
                                <div class="flex flex-col flex-1">
                                    <button v-on:click="getGPSLocation" class="typography-7 bg-white p-10 text-red" type="button"><span class="flex items-center"><i class="icon-geo mr-10"></i><div>{{$t('common.use_my_location')}}</div></span></button>
                                </div>
                                <div class=" button-delivery-search pr-5 " v-if="isShowMap">
                                    <div class="hidden flex-1 map-description">{{$t('common.map_pin_description')}}</div>
                                    <div class="button button--red flex-1  px-20 " v-on:click="submitAddress()">
                                        <div class="flex w-full flex-col py-10"><b><span class=""><span>{{$t('common.order_now')}}</span></span></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="localisation bg-white p-20 rounded-b" v-show="orderType == $const.ORDERTYPE.CARRYOUT">
                            <div class="background-fed03b arrow-bottom-yellow" v-if="$route.query.warning && $route.query.warning == '1'" >{{$t('common.missing_localisation')}}</div>

                            <v-select  v-if="savedCollections.length > 0"
                                      :options="savedCollections" v-model="selectedSavedStore"  class="w-full typography-6 bold mb-10" name="SavedStore">
                            </v-select>
                            <div class="title-search-localization" :class="{'hidden': $route.query.warning && $route.query.warning == '1'}" v-if="available">{{$t('common.title_input_search')}}</div>
                            <div class="flex border border-gray-light rounded relative z-300 bg-white flex-col" v-if="available">
                                <div class="flex items-stretch relative" >
                                    <vue-google-autocomplete
                                        ref="addressCollection"
                                        id="mapCollection"
                                        className="typography-5 search search--hut input border-0 py-15 px-10 height-49"
                                        :placeholder="$t('common.placeholder_input_carryout')"
                                        v-on:placechanged="getAddressData"
                                        v-on:inputChangeAutoComplete="changeCollection"
                                        :allowFields="['place_id', 'geometry', 'address_components']"
                                        :country="$const.COUNTRY.CODE"
                                    >
                                    </vue-google-autocomplete>
                                    <div @click="resetAddressAutoComplete()"  v-if="modelDeliveryCarryout"
                                        class=" flex flex-row cursor-pointer flex-center btn--remove-auto-address "
                                        style="width: 40px; border-radius-right-width: 4px">
                                        <img src="../../assets/images/icon-remove.svg" alt="remove"/>
                                    </div>
                                    <button class="button button--red w-auto btn--search-address" v-if="!modelDeliveryCarryout" type="button" v-on:click="searchLocation">Search</button>
                                </div>
                                <div class="pt-5 border-t overflow-scrolling-touch flex" style="max-height: 350px; overflow-y: auto;">
                                    <div class="flex flex-col" >
                                        <button v-on:click="getGPSLocation" class="typography-7 bg-white p-10 text-red" type="button"><span class="flex items-center"><i class="icon-geo mr-10"></i><div>{{$t('common.find_nearest_hut')}}</div></span></button>
                                    </div>
                                    <div class="flex flex-row-reverse flex-1" v-if="stores.length > 0 ">
                                        <button v-on:click="openMapCarryout" class="typography-7 bg-white p-10 text-red" type="button"><span class="flex items-center"><div>{{$t('common.open_maps')}}</div></span></button>
                                    </div>
                                </div>
                                <div class="store-list-contain"  v-if="stores.length > 0">
                                    <div class="store-item p-5 border-t overflow-scrolling-touch cursor-pointer" v-for="(item, indexS) in stores" :key="indexS"  @click="choseStore(item)">
                                        <div style="margin-bottom: 5px"><img :src="item.logo" class="store_logo_carryout"><strong> {{item.name}}</strong> ({{formatKm(item.distance, 2)}} {{$t('km')}})</div>
                                        <div>{{item.location}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="localisation bg-white p-20 rounded-b" v-show="orderType == $const.ORDERTYPE.DINEIN">
                            <div class="background-fed03b arrow-bottom-yellow" v-if="$route.query.warning && $route.query.warning == '1'" >{{$t('common.missing_localisation')}}</div>

                            <v-select  v-if="savedCollections.length > 0"
                                      :options="savedCollections" v-model="selectedSavedStore"  class="w-full typography-6 bold mb-10" name="SavedStore">
                            </v-select>
                            <div class="title-search-localization" :class="{'hidden': $route.query.warning && $route.query.warning == '1'}" v-if="available">{{$t('common.title_input_search')}}</div>
                            <div class="flex border border-gray-light rounded relative z-300 bg-white flex-col" v-if="available">
                                <div class="flex items-stretch relative" >
                                    <vue-google-autocomplete
                                        ref="addressCollection"
                                        id="mapCollection"
                                        className="typography-5 search search--hut input border-0 py-15 px-10 height-49"
                                        :placeholder="$t('common.placeholder_input_carryout')"
                                        v-on:placechanged="getAddressData"
                                        v-on:inputChangeAutoComplete="changeCollection"
                                        :allowFields="['place_id', 'geometry', 'address_components']"
                                        :country="$const.COUNTRY.CODE"
                                    >
                                    </vue-google-autocomplete>
                                    <div @click="resetAddressAutoComplete()"  v-if="modelDeliveryCarryout"
                                        class=" flex flex-row cursor-pointer flex-center btn--remove-auto-address "
                                        style="width: 40px; border-radius-right-width: 4px">
                                        <img src="../../assets/images/icon-remove.svg" alt="remove"/>
                                    </div>
                                    <button class="button button--red w-auto btn--search-address" v-if="!modelDeliveryCarryout" type="button" v-on:click="searchLocation">Search</button>
                                </div>
                                <div class="pt-5 border-t overflow-scrolling-touch flex" style="max-height: 350px; overflow-y: auto;">
                                    <div class="flex flex-col" >
                                        <button v-on:click="getGPSLocation" class="typography-7 bg-white p-10 text-red" type="button"><span class="flex items-center"><i class="icon-geo mr-10"></i><div>{{$t('common.find_nearest_hut')}}</div></span></button>
                                    </div>
                                    <div class="flex flex-row-reverse flex-1" v-if="stores.length > 0 ">
                                        <button v-on:click="openMapCarryout" class="typography-7 bg-white p-10 text-red" type="button"><span class="flex items-center"><div>{{$t('common.open_maps')}}</div></span></button>
                                    </div>
                                </div>
                                <div class="store-list-contain"  v-if="stores.length > 0">
                                    <div class="store-item p-5 border-t overflow-scrolling-touch cursor-pointer" v-for="(item, indexS) in stores" :key="indexS"  @click="choseStore(item)">
                                        <div style="margin-bottom: 5px"><img :src="item.logo" class="store_logo_carryout"><strong> {{item.name}}</strong> ({{formatKm(item.distance, 2)}} {{$t('km')}})</div>
                                        <div>{{item.location}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="maps" style="height: 500px" v-show="isShowMap"></div>
                </div>
            </div>
        </div>
        <div v-if="$route.query.warning && $route.query.warning == '1'" class="modal-backdrop"></div>
    </div>
</template>

<script>
    import VueGoogleAutocomplete from '../shared/GoogleAutocompleteCustom'
    import localizationAPI from '../../services/localization'
    import types from '../../store/mutationTypes'
    import ModalNotification from '../modal/ModalNotify'
    import ModalMapCarryout from '../modal/ModalMapCarryout'
    import ModalStoreCarryout from '../modal/ModalStoreCarryout'
    // import ModalASAP from '../modal/ModalASAP'
    import CustomerApi from '../../services/customer';
    import LocalizationApi from '../../services/localization';

    import 'lazysizes';
    import 'lazysizes/plugins/parent-fit/ls.parent-fit';
    import vSelect from 'vue-select';
    import 'vue-select/dist/vue-select.css';

    import ModalDeliveryOrCollection from '../modal/ModalDeliveryOrCollection'
    import { mapActions, mapGetters } from 'vuex'
    import ModalNotifyBlack from "../modal/ModalNotifyBlack";
    import ModalStoreDelivery from "../modal/ModalStoreDelivery";
    import ModalOrderTime from "../modal/ModalOrderTime";
    import OrderApi from '../../services/order'
    import { orderMixins } from '../../mixins/order'
  
    /* eslint-disable */
    export default {
        mixins: [orderMixins],
        components: {
          'vue-google-autocomplete': VueGoogleAutocomplete,
          vSelect
        },
        name: 'LocalisationBox',
        props: {
            url: {
                type: String,
                default: ''
            }
        },
        data: function() {
            return {
                flagDrag: false,
                isShowMap: false,
                latlng: null,
                available: false,
                deliveryInfo: {
                    houseNo: '',
                    buildingName: '',
                    streetName: '',
                    streetNumber: '',
                    postalCode: '',
                    address: '',
                    fullAddress: '',
                },
                latlngCarryout: {
                    lat: null,
                    lng: null
                },
                latlngDelivery: {
                    lat: null,
                    lng: null
                },
                deliveryAddress: '',
                modelDeliveryAddress: '',
                modelDeliveryCarryout: '',
                orderType: this.$const.ORDERTYPE.DELIVERY,
                stores: [],
                hasStore: false,
                mapRoot: null,
                markersRoot: [],
                savedLocations: [],
                savedCollections: [],
                selectedSavedLocation: {},
                selectedSavedStore: {},
                showWarning: true,
                isDragging: undefined,
                invalidStatusCodes: [this.$const.ORDERSTATUS.FINISHED, this.$const.ORDERSTATUS.CANCELED, this.$const.ORDERSTATUS.REJECTED],
            }
        },
        created () {
          this.$root.$on('hasStore', (newValue) => {
            this.hasStore = newValue;
          });
          this.$root.$on('orderType', (newValue) => {
            this.orderType = newValue || this.$const.ORDERTYPE.DELIVERY;
          });
        },
        mounted() {
            var vm = this;
            window.infoWindowClick = function() {
                vm.infoWindowClick();
            }
            window.initMap = function() {
                vm.available = true;
                vm.initMapGoogle();
            }
            this.loadMapApi();
            this.hasStore = !this.emptyVariable(this.storeUuid);
            this.orderType = this.StoreOrderType || this.$const.ORDERTYPE.DELIVERY;
            this.$root.$on('triggerCheckLocalisation', (store) => {
                vm.handleSaveStore(store);
            })
            if (this.isAuth) {
                this.getSavedLocations();
                this.getProfileByToken();
            }

        },
        destroyed() {
            this.$root.$off('triggerCheckLocalisation');
        },
        watch: {
            selectedSavedLocation: function(newVal, oldVal) {
                if (newVal && !this.emptyVariable(newVal.item)) {
                    let info = newVal.item;
                    this.deliveryInfo = {
                        note: info.note,
                        city: '',
                        buildingName: info.address_level_0,
                        streetNumber: info.address_level_1,
                        streetName: info.address_level_2,
                        state: info.address_level_3,
                        postalCode: info.postal_code,
                    }
                    this.deliveryAddress = info.full_address;
                    this.checkDelivery(info.lat, info.long);
                }
            },
            selectedSavedStore: function(newVal, oldVal) {
                if (newVal && !this.emptyVariable(newVal.item)) {
                    let info = newVal.item;
                    let store = {
                        "note": info.note,
                        "client_uuid": '',
                        "code": info.code || '',
                        "distance": info.distance || 0,
                        "lat": info.lat,
                        "location": info.location,
                        "long": info.long,
                        "name": info.name,
                        "tier_uuid": info.tier_uuid || null,
                        "uuid": info.store_uuid,
                        "business_hours": [],
                        "store_type_id": info.store_type_id
                    }
                    var vm = this;
                    let params = {
                        store_uuid: info.store_uuid ,
                        store_type_id: this.$const.TYPE_HUT.PHD,
                        disposition: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)]
                    }
                    LocalizationApi.storeAdvanceTime(params, function (response) {
                        store.timezone = response.data.timezone;
                        store.business_hours = response.data.business_hours;

                        vm.beforeHandleSaveStoreCarryout(store);
                    });
                }
            }
        },
        computed: {
            ...mapGetters({
                isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
                collection_time: 'moduleLocalisation/' +  types.LOCALISATION_GET_COLLECTION_TIME,
                timezone: 'moduleLocalisation/' +  types.LOCALISATION_GET_STORE_TIMEZONE,
                orderNow:  'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_NOW,
                storeUuid: 'moduleLocalisation/' +  types.LOCALISATION_GET_STORE_UUID,
                StoreOrderType:  'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
                storeName:  'moduleLocalisation/' + types.LOCALISATION_GET_STORE_NAME,
                storeLocation:  'moduleLocalisation/' + types.LOCALISATION_GET_LOCATION,
                isShowLoading:  types.IS_SHOW_LOADING,
                localisationInfo: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
                defaultCategory:  types.GET_DEFAULT_CATEGORY,
                orderStatusCode: 'moduleOrder/' + types.ORDER_GET_ORDER_STATUS_CODE,
                orderCode: 'moduleOrder/' + types.ORDER_GET_ORDER_CODE,
                cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
            }),
        },
        methods: {
            ...mapActions({
                getProfileByToken: 'moduleAuth/' + types.CUSTOMER_GET_INFO_BY_TOKEN,
                saveLocalisation:  'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
                saveOrder: 'moduleOrder/' + types.ORDER_SAVE_ITEM,
                resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
                resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
                resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
            }),
            resetAddressAutoComplete() {
                if (this.orderType === this.$const.ORDERTYPE.DELIVERY && this.$refs.addressDelivery) {
                    this.$refs.addressDelivery.update( '' );
                    this.deliveryAddress = '';
                    this.latlng = null;
                }
                if (this.orderType === this.$const.ORDERTYPE.CARRYOUT) {
                    this.$refs.addressCollection.update( '' );
                }
            },
            infoWindowClick() {
                this.submitAddress();
            },
            getSavedLocations () {
                var vm = this;
                CustomerApi.getLocations({vm: this, store_type_id: this.$const.TYPE_HUT.PHD}, function (response) {
                    let dataSaveLocations = response.data.delivery_addresses;//
                    if (dataSaveLocations.length > 0) {
                        vm.savedLocations.push({
                            label: vm.$t('common.selected_saved_location'),
                            item: {}
                        });
                        for (let i in dataSaveLocations) {
                            vm.savedLocations.push({
                                label: dataSaveLocations[i].address_level_1 + ', ' + dataSaveLocations[i].address_level_2 ,
                                item: dataSaveLocations[i]
                            });
                        }
                        vm.selectedSavedLocation = vm.savedLocations[0];
                    }
                    let dataSavedCollections = response.data.collection_stores;

                    if (dataSavedCollections.length > 0) {
                        vm.savedCollections.push({
                            label: vm.$t('common.selected_saved_collection'),
                            item: {}
                        });
                        for (let i in dataSavedCollections) {
                            vm.savedCollections.push({
                                label: dataSavedCollections[i].name,
                                item: dataSavedCollections[i]
                            });
                        }
                        vm.selectedSavedStore = vm.savedCollections[0];
                    }


                })
            },
            resetDeliveryInfo () {
                this.deliveryInfo = {
                    buildingName: '',
                    city: '',
                    streetNumber: '',
                    streetName: '',
                    state: '',
                    postalCode: '',
                }
            },
            saveDeliveryInfo (data) {
                if (this.orderType === this.$const.ORDERTYPE.CARRYOUT) {
                    return this.resetDeliveryInfo();
                }

                let vm = this;
                let fields = {
                    building_name: '',
                    street_number: '',
                    route: '',
                    administrative_area_level_3: '',
                    administrative_area_level_1: '',
                    postal_code: ''
                };
                // let subLocal = '';
                if (data && data.hasOwnProperty('address_components')) {
                    fields.building_name = data.name
                    for (let i = 0; i < data.address_components.length; i++) {
                        let addressType = data.address_components[i].types[0];
                        if (addressType == 'street_number' ) {
                            fields.street_number = data.address_components[i].short_name;
                        } else if (addressType == 'route' ) {
                            fields.route = data.address_components[i].long_name;
                        } else if (addressType == 'administrative_area_level_1' ) {
                            fields.administrative_area_level_1 = data.address_components[i].short_name;
                        } else if (addressType == 'administrative_area_level_3' ) {
                            fields.administrative_area_level_3 = data.address_components[i].long_name;
                        } else if (addressType == 'postal_code' ) {
                            fields.postal_code = data.address_components[i].short_name;
                        }
                        // else if (addressType == 'political' && data.address_components[i].types.length >= 3 && data.address_components[i].types[2] == 'sublocality_level_1') {
                        //   subLocal = data.address_components[i].long_name;
                        // }
                    }
                }
                let location = fields.route;
                if(fields.street_number.length > 0 && location.length > 0) {
                    location = fields.street_number + ', ' + location;
                }
                if(fields.administrative_area_level_3.length > 0 && location.length > 0) {
                    location = location + ', ' + fields.administrative_area_level_3;
                }
                // if (fields.route !== '' && subLocal.length > 0) {
                //   fields.route += ', ' + subLocal;
                // }
                vm.deliveryAddress = location;
                vm.deliveryInfo = {
                    buildingName: fields.building_name ? fields.building_name : vm.deliveryInfo.buildingName,
                    city: fields.administrative_area_level_3,
                    streetNumber: '', //fields.street_number,
                    streetName: fields.route ? fields.route : vm.deliveryInfo.streetName,
                    state: fields.administrative_area_level_1,
                    postalCode: fields.postal_code,
                    location: location
                }
            },
            /*For current location*/
            initLatLngMaker (lat, lng) {
                if (!lat || !lng) return false;
                this.latlng = new google.maps.LatLng(lat, lng);
            },
            initMapGoogle() {
                this.mapRoot = new google.maps.Map(document.getElementById('maps'), {
                    center: {lat: this.$const.LOCATION.LAT, lng: this.$const.LOCATION.LNG},
                    zoom: 15,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                })
                let myLatlng = new google.maps.LatLng(this.$const.LOCATION.LAT, this.$const.LOCATION.LNG);
                this.initMaker(myLatlng);
            },
            mappingAddressInput (value) {
                this.showWarning = (value != '') ? false : true;
                this.deliveryAddress = value.newVal;
                this.modelDeliveryAddress = value.newVal;
            },
            handleUpdateInput (input, ignoreCaryout) {
                if (this.orderType == this.$const.ORDERTYPE.CARRYOUT) {
                    if (!ignoreCaryout && this.$refs.addressCollection) {
                        this.$refs.addressCollection.update( input );
                    }
                } else {
                    this.flagDrag = true;
                    if (this.isShowMap && this.$refs.addressDelivery) {
                        this.$refs.addressDelivery.update( input );
                    }
                }
            },
            handleLocationChangeManual (lat ,lng) {
                this.initLatLngMaker(lat, lng);
                this.findAddressByLatLng({lat: lat, lng: lng});
            },
            initMaker (latlng) {
                var vm = this;
                if (this.markersRoot.length > 0 ) {
                    for (let i in this.markersRoot) {
                        this.markersRoot[i].setMap(null);
                    }
                }
                latlng = latlng ? latlng : this.latlng;
                this.markersRoot = [];
                const infowindow =
                    this.orderType === this.$const.ORDERTYPE.DELIVERY
                        ? new google.maps.InfoWindow({
                            content:
                                '<div class="address-confirmation-container p-15 cond" data-synth="address-confirmation-container">' +
                                '<div><div class="typo-l6 text-center mb-15"><span>'+ `${vm.$t("common.please_select_your_address")}`+ '</span></div>' +
                                '<p class="typo-l6 text-grey mb-20 mt-20"><span>' + `${vm.$t("common.map_description")}` +'</span></p>' +
                                '<button class="button button--red confirm-delivery" data-synth="confirm-delivery" onclick="infoWindowClick()"><span>' +
                                this.$t('common.confirm') +
                                '</span></button></div></div>',
                            maxWidth: 270
                        })
                        : undefined
                var marker = new google.maps.Marker({
                    position: latlng,
                    map: this.mapRoot,
                    icon: 'https://static.pizzahut.co.id/images/icons/pin.png',
                    draggable: false,
                    infowindow
                });
                this.markersRoot.push(marker);
                this.mapRoot.setCenter(latlng)
                if (infowindow) {
                    infowindow.open(this.mapRoot, marker)
                }
                
                google.maps.event.addListener(this.mapRoot, 'dragstart', function() {
                  vm.isDragging = true
                });

                google.maps.event.addListener(this.mapRoot, 'drag', function() {
                  marker.setPosition(this.getCenter());
                })
                
                const dragZoomEnd = function() {
                    marker.setPosition(this.getCenter());
                    vm.$root.$emit('dragged', true)
                    if (vm.isDragging) {
                      vm.handleLocationChangeManual(
                        parseFloat(marker.getPosition().lat()),
                        parseFloat(marker.getPosition().lng())
                      );
                    }
                }

                google.maps.event.addListener(this.mapRoot, 'dragend', () => {
                  dragZoomEnd.call(this.mapRoot)
                  vm.isDragging = false
                });

                google.maps.event.addListener(this.mapRoot, 'zoom_changed', dragZoomEnd);

                let bounds = new google.maps.LatLngBounds();
                bounds.extend(
                    new google.maps.LatLng(
                        this.markersRoot[0].getPosition().lat(),
                        this.markersRoot[0].getPosition().lng()
                    )
                )
                this.mapRoot.fitBounds(bounds);
                this.mapRoot.setZoom(15);

            },
            /*update input autocomplete*/
            findAddressByLatLng (latLong) {
                let geocoder = new google.maps.Geocoder()
                var vm = this;
                let address
                const regex = /^[A-Z0-9]{4}\+[A-Z0-9]{2,}$/
                geocoder.geocode({'location': latLong}, function (results, status) {
                    if (status === 'OK') {
                        if (results[0]) {
                            const { formatted_address, address_components } = results[0]
                            address = formatted_address
                            const building_name = vm.returnDataByKey(address_components, 'premise').short_name
                            const plus_code = vm.returnDataByKey(address_components, 'plus_code').short_name

                            if (address.includes(plus_code)) {
                              address = formatted_address.replace(plus_code, '').trim();
                              address = address.replace(',', '').trim();
                            }

                            if (address && building_name && address.includes(building_name)) {
                              address = building_name + ', ' + address
                            }
                            
                            vm.handleUpdateInput(address || formatted_address)
                            vm.saveDeliveryInfo(results[0])
                        }
                    }
                })
            },
            findLatLngByAddress () {
                var vm = this;
                let geocoder = new google.maps.Geocoder()
                geocoder.geocode({'address': vm.deliveryAddress}, function (results, status) {
                    if (status === 'OK' && results.length > 0) {
                        let lat = results[0].geometry.location.lat();
                        let lng = results[0].geometry.location.lng();
                        let myLatlng = new google.maps.LatLng(parseFloat(lat),parseFloat(lng));
                        vm.initMaker(myLatlng);
                        vm.findAddressByLatLng({lat: parseFloat(lat), lng: parseFloat(lng)});
                        vm.resetDeliveryInfo();
                        if (vm.orderType == vm.$const.ORDERTYPE.DELIVERY) {
                            vm.showMapDelivery(parseFloat(lat), parseFloat(lng))
                        } else {
                            vm.getStoreCollection( parseFloat(lat),  parseFloat(lng));
                        }
                    } else {
                        if (vm.orderType == vm.$const.ORDERTYPE.DELIVERY) {
                            vm.notificationError(vm, vm.$t('stores.delivery_not_found',{phone: vm.$const.CONTACT_PHONE}));
                        } else {
                            vm.notificationError(vm, vm.$t('stores.not_found'))
                        }
                    }
                })
            },
            searchLocation() {
                let address = this.orderType == this.$const.ORDERTYPE.DELIVERY ? this.$refs.addressDelivery.autocompleteText : this.$refs.addressCollection.autocompleteText;
                if (address) {
                    return this.findLatLngByAddress();
                }
                return this.getGPSLocation();
            },
            getGPSLocation() {
                this.$store.dispatch(types.IN_CREATE_LOADING);
                navigator.geolocation.getCurrentPosition(location => {
                    let myLatlng = new google.maps.LatLng(parseFloat(location.coords.latitude),parseFloat(location.coords.longitude));
                    this.initMaker(myLatlng);
                    this.findAddressByLatLng({lat: parseFloat(location.coords.latitude), lng: parseFloat(location.coords.longitude)});
                    this.resetDeliveryInfo();
                    if (this.orderType == this.$const.ORDERTYPE.DELIVERY) {
                        this.showMapDelivery(parseFloat(location.coords.latitude), parseFloat(location.coords.longitude))
                    } else {
                        this.getStoreCollection( parseFloat(location.coords.latitude),  parseFloat(location.coords.longitude));
                    }
                    this.$store.dispatch(types.DE_CREATE_LOADING);
                }, error => {
                    let message = ''
                    let warnTxt = ''
                    switch(error.code) {
                        case error.PERMISSION_DENIED:
                            message = "User denied the request for Geolocation."
                            warnTxt = this.$t('warn.enable_location_permission')
                            break;
                        case error.POSITION_UNAVAILABLE:
                            message = "Location information is unavailable."
                            warnTxt =  this.$t('warn.cant_get_address')
                            break;
                        case error.TIMEOUT:
                            message = "The request to get user location timed out."
                            warnTxt =  this.$t('warn.cant_get_address')
                            break;
                    }
                    this.notificationError(this, warnTxt);
                    this.$store.dispatch(types.DE_CREATE_LOADING);
                    console.log(message)
                    console.log(error)
                }, {enableHighAccuracy: true});
            },
            changeMenu: function(type) {
                this.orderType = type;
                if (type == this.$const.ORDERTYPE.CARRYOUT) {
                    this.isShowMap = false;
                }
            },
            getAddressData: function (addressData, placeResultData, id) {
                if (this.orderType == this.$const.ORDERTYPE.DELIVERY) {
                    this.latlng = new google.maps.LatLng(addressData.latitude, addressData.longitude);
                    this.saveDeliveryInfo(placeResultData);
                    this.showMapDelivery(addressData.latitude, addressData.longitude)
                } else {
                    this.latlngCarryout.lat = addressData.latitude;
                    this.latlngCarryout.lng = addressData.longitude;
                    this.getStoreCollection(addressData.latitude, addressData.longitude)
                }
            },
            async changeLocalization() {
              if (this.StoreOrderType == this.$const.ORDERTYPE.DINEIN && this.cartUuid) {
                this.checkLatestOrder(false, true, true)
              } else {
                this.resetStore();
                this.hasStore = false;
              }
            },
            resetStore () {
                if (this.orderCode && this.cartUuid) {
                  this.notificationSuccess(this, this.$t('message.success.order_finished'))
                }
                this.resetLocalisation();
                this.resetCart();
                this.resetOrder();
                this.changeMenu(this.$const.ORDERTYPE.CARRYOUT)
            },
            saveStore (store) {
                let storeData = this.localisationInfo;
                let storeDataNew = {
                    "note": store.hasOwnProperty('note') ? store.note : '',
                    "clientUUID": store.client_uuid || '',
                    "code": store.code || '',
                    "distance": store.distance || 0,
                    "lat": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lat : store.lat,
                    "location": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.deliveryAddress.replace(', Indonesia', '') : '',
                    "addressStore": store.location,
                    "long": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lng : store.long,
                    "name": store.name,
                    "tierUUID": store.tier_uuid || null,
                    "uuid": store.uuid,
                    "timezone": store.timezone,
                    "deliveryInfo": {},
                    "businessHours": store.business_hours,
                    "orderType": this.orderType,
                    "storeType": store.store_type_id,
                    "storeBranch": store.store_branch
                }
                if (this.orderType === this.$const.ORDERTYPE.DELIVERY) {
                    storeDataNew.deliveryInfo = this.deliveryInfo;
                    storeDataNew.note = this.deliveryInfo.hasOwnProperty('note') ? this.deliveryInfo.note : '';
                }
                this.saveLocalisation({...storeData, ...storeDataNew});
                this.resetCart();
            },
            showMapDelivery (lat, long) {
                this.isShowMap = true;
                this.initLatLngMaker(lat, long);
                this.initMaker(this.latlng);
            },
            checkDelivery (lat, long) {
                let params = {
                    lat: lat,
                    long: long,
                    now_order: this.orderNow,
                    //store_type_id: this.$const.TYPE_HUT.PHD,
                    datetime: this.convertDateTimeFromTimezoneToUTC(this.timezone, this.collection_time),
                    vm: this
                }
                this.latlngDelivery.lat = lat;
                this.latlngDelivery.lng = long;
                var vm = this;
                localizationAPI.checkDelivery(params, function (response) {
                    if (response.code == 200) {
                        if (response.data.length > 0) {
                            vm.$modal.show(
                                ModalStoreDelivery,
                                {
                                    stores: response.data,
                                    chosenStore: function (store) {
                                        this.$emit('close')
                                        //if (store.available == 1) {
                                            this.$modal.show(ModalOrderTime,
                                                {
                                                    businessHoursCustom: store.business_hours,
                                                    timezoneHut: store.timezone,
                                                    triggerCheckLocalisationData: store,
                                                    chosenTime: function () {
                                                        vm.handleSaveStore(store);
                                                    }
                                                },
                                                {
                                                classes:(this.isMobile()) ? 'modal-order-time' : ''
                                            });
                                    },
                                },
                                {
                                    classes: 'modal-choose-store-carryout-custom back-ground-black-transparent'
                                },
                                {
                                    'opened': function () {
                                        document.body.classList.add('modal-open')
                                    },
                                    'before-close': function () {
                                        document.body.classList.remove('modal-open')
                                    }
                                }
                            );
                        }
                    }
                }, function (dataError) {
                    // call get stores ModalStoreCarryout
                    vm.latlng = null;
                    localizationAPI.getStoreCollection(params, function (response) {
                        if (response.code == 200 && response.data.length > 0) {
                            vm.$modal.show(ModalStoreCarryout,
                                {
                                    stores: response.data,
                                    chosenStore: function (store) {
                                        vm.changeMenu(vm.$const.ORDERTYPE.CARRYOUT)
                                        vm.choseStore(store)
                                    }
                                },
                                {resizable: false, clickToClose: false, classes: 'modal-choose-store-carryout-custom back-ground-black-transparent'},
                                {
                                    'opened': function () {
                                        document.body.classList.add('modal-open')
                                    },
                                    'before-close': function () {
                                        document.body.classList.remove('modal-open')
                                    }
                                }
                            );
                        } else {
                            vm.warningModalDeliveryOrCollection(lat, long);
                        }
                    }, function (error) {
                        vm.warningModalDeliveryOrCollection(lat, long);
                    })
                })
            },
            warningModalDeliveryOrCollection(lat, long) {
                var vm = this;
                vm.$modal.show(ModalDeliveryOrCollection,
                    {
                        contain: vm.$t('stores.delivery_not_found',{phone: vm.$const.CONTACT_PHONE}),
                        chooseAnotherDelivery: function () {
                            vm.isShowMap = true;
                            vm.initLatLngMaker(lat, long);
                        },
                        chooseCollection: function () {
                            vm.changeMenu(vm.$const.ORDERTYPE.CARRYOUT);
                            vm.$refs.addressCollection.update( vm.$refs.addressDelivery.$el.value );
                            vm.getStoreCollection(lat, long);
                        }
                    },
                    {resizable: false, clickToClose: false, classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'}
                );
            },
            getStoreCollection (lat, long) {
                let params = {
                    lat: lat,
                    long: long,
                    now_order: this.orderNow,
                    //store_type_id: this.$const.TYPE_HUT.PHD,
                    datetime: this.convertDateTimeFromTimezoneToUTC(this.timezone, this.collection_time),
                    vm: this
                }
                var vm = this;
                vm.stores = [];
                localizationAPI.getStoreCollection(params, function (response) {
                    if (response.code == 200) {
                        vm.stores = [];
                        if (response.data.length <= 0) {
                            vm.notificationError(vm, vm.$t('stores.not_found'));
                        } else {
                            for (let i in response.data) {
                                if ( i < 5) {
                                    vm.stores.push(response.data[i]);
                                } else {
                                    break;
                                }
                            }
                        }
                    }
                }, function (error) {
                    vm.$modal.show(ModalNotification, {hideOK: true, contain: error.message}, {resizable: false, clickToClose: true, classes: 'modal-notification-custom'});
                })
            },
            choseStore(item) {
                if (this.emptyVariable(item)) {
                    return false;
                }
                this.beforeHandleSaveStoreCarryout(item);

            },
            beforeHandleSaveStoreCarryout (data)  {
                // no need check available key for carryout
                if (this.emptyVariable(data.business_hours) || this.checkClosedStore(data.business_hours)) {
                    this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.$t('message.error.store_closed')},
                        {resizable: false, clickToClose: true,
                            classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
                    return false;
                }
                if (data.available != 1) {
                    // return this.$modal.show(ModalASAP,{
                    //     businessHoursCustom: data.business_hours,
                    //     timezoneHut: data.timezone,
                    //     triggerCheckLocalisationData: data,
                    // })

                    return this.$modal.show(ModalOrderTime,
                        {
                            businessHoursCustom: data.business_hours,
                            timezoneHut: data.timezone,
                            triggerCheckLocalisationData: data,
                            chosenTime: function () {
                                vm.handleSaveStore(data);
                            }
                        },
                        {
                            classes:(this.isMobile()) ? 'modal-order-time' : ''
                        });
                }

                this.$root.$emit('orderType', this.$const.ORDERTYPE.CARRYOUT)
                // call ajax check store valid or invalid
                this.handleSaveStore(data);
            },
            handleSaveStore (data) {
                this.saveStore(data);
                if (this.$route.query.callback && this.$route.query.callback.length > 0) {
                    this.$root.$emit('triggerInitCategories', {callback: this.$route.query.callback});
                } else {
                    this.$root.$emit('triggerInitCategories', {goDeal: true});
                }
            },
            submitAddress: function () {
                if (this.orderType == this.$const.ORDERTYPE.DELIVERY) {
                    if (!this.latlng) {
                        this.isShowMap = true;
                        this.notificationError(this, this.$t('common.change_another_address'));
                    } else {
                        this.checkDelivery(this.latlng.lat(), this.latlng.lng())
                    }
                }
            },
            changeCollection(data){
                this.modelDeliveryCarryout = data.newVal;
                if (this.emptyVariable(data.newVal)) {
                    this.stores = []
                }
            },
            openMapCarryout () {
                var vm = this;
                this.$modal.show(ModalMapCarryout, {stores: this.stores, lat: this.latlngCarryout.lat, lng: this.latlngCarryout.lng, chosenStore: function (store) {
                        vm.choseStore(store)
                    }}, {height: 'auto', classes: "modal-block-map-carryout"}, {
                    'opened': this.beforeOpen,
                    'before-close': this.beforeClose
                })
            },
            beforeOpen (event) {
                document.body.classList.add('modal-open-carryout')
            },
            /**
             * Trigger before close modal popup
             * */
            beforeClose (event) {
                document.body.classList.remove('modal-open-carryout')
            },
            loadMapApi() {
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0]
                    if (d.getElementById(id)){initMap();return}
                    js = d.createElement(s);
                    js.id = id
                    js.async = true
                    // js.defer = true
                    js.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyApvJen7vuzOfRjJioNaQkZLqenwEPaV9s&libraries=places&region=id&callback=initMap'
                    fjs.parentNode.insertBefore(js, fjs)
                }(document, 'script', 'google-maps-jssdk12'))
            },
            returnDataByKey(data, key) {
              return data.find(ite => ite.types.includes(key)) || {}
            },
        }
    }
</script>
<style scoped>
    .store_logo_carryout{
        float: left;
        width: 100px;
        height: auto;
    }
</style>
