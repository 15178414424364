import Api from '@/services/api'
import types from "../store/mutationTypes";
const url = '/store/v1'
export default {
    searchByCode (code, data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            if (data.loader || data.loader === undefined) {
                mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            }
            delete data['vm']
        }
        return Api().get(url + '/search-by-code/' + code)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
}