

export default {
  async generate() {    
    var a, b, c, d;
    var time = Math.round(new Date().getTime() / 1000);
    var version = window.navigator.appVersion;
    var href = window.location.href;
    
    // a - unix timestamp
    a = time.toString(16).substring(0, 8);

    // b - browser
    var match = version.match(/\d+/g);
    if (!match) {
      throw 'Invalid browser version string';
    }
    var sum = 0;
    for (var i = 0, ii = match.length; i < ii; ++i) {
      sum += parseInt(match[i], 10);
    }
    b = (sum * sum * sum).toString(16).substring(0, 6);

    // c - url
    c = (href.length * href.length * href.length).toString(16).substring(0, 4);

    // d - random
    d = Math.random().toString().substring(2);
    d = parseInt(d, 10);
    d = d.toString(16).substring(0, 6);

    return [a, b, c, d].join('-');
  }
}