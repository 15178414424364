<template>
  <div class="en-GB app-bg" style="background-color: #fff !important;">
    <router-link :to="{name: 'ph-reward'}">
      <img v-if="!isMobile()" src="../assets/images/login_desktop_20221115.jpg">
      <img v-else :src="backgroundUrl">
    </router-link>
    <div class="p-20 bg-white">
      <div class="md:flex">
        <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm">
          <div>
            <h1 class="text-left">Welcome back</h1>
            <span class="text-grey">A slice, anytime anywhere</span></div>
          <div class="form-field">
            <input id="email" class="input xs-mb-10" ref="email"
                   v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                   name="email" v-model="email" placeholder="Email"
                   maxlength="255" type="text">
            <span class="float-left text-red" v-if="errors.email">{{ errors.email }}</span>
          </div>
          <div class="form-field">
            <input id="password" class="input xs-mb-10" ref="password"
                   v-bind:class="{'has-error': errors.password, 'has-success': errors.password === false}"
                   name="password" v-model="password" placeholder="Password"
                   maxlength="255" type="password">
            <span class="float-left text-red" v-if="errors.password">{{ errors.password }}</span>
          </div>
          <div class="text-right p-10">
            <router-link class="text-red t15" :to="{name: 'forgot-password'}">{{ $t('common.forgot_password') }}
            </router-link>
          </div>
          <div>
            <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;">
              {{ $t('common.btn.sign_in') }}
            </button>
          </div>
          <div class="text-center" style="padding-top: 20px">
            <router-link :to="{name: 'register'}"><span class="text-red t15 cursor-pointer">Register Now </span>and
            enjoy free Pizza today</router-link>
          </div>
          <google-login-btn @handleUserInfo="signInGoogleV2"/>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import CustomerApi from '../services/customer'
  import types from '../store/mutationTypes'
  import { createNamespacedHelpers, mapGetters } from 'vuex'
  const { mapActions } = createNamespacedHelpers('moduleAuth')
  import ModalRegister from '../components/modal/ModalRegister'
  import braze from "@/services/braze"
  import ModalVerifyToken from "../components/modal/ModalVerifyToken"
  import ModalSendOTP from "../components/modal/ModalSendOTP"
  import ContentApi from "@/services/content"
  import GoogleLoginBtn  from "@/components/modal/GoogleLoginBtn"
  import CartApi from '@/services/cart'

  export default {
    name: "login",
    components: {
      GoogleLoginBtn,
    },
    data() {
     return {
       keyToken: '',
       email: '',
       password: '',
       errors: {
         isValid: false,
         password: '',
         email: '',
       },
       otpType: 1,
       topBackground: [],
       backgroundUrl: '',
     }
    },
    created() {
     this.checkMobile()
     this._getLoginBanners()
    },
    computed: {
      ...mapGetters({
        cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
      }),
    },
    mounted() {
    },
    watch: {
      'email': function (newValue) {
        this.errors.email = false;
        if (!newValue) {
          this.errors.email = this.$t('message.error.email');
        }else if (!this.validEmail(newValue)) {
          this.errors.email = this.$t('message.error.invalid_email');
        }
      },
      'password': function (newValue) {
        this.errors.password = false;
        if (!newValue) {
          this.errors.password = this.$t('message.error.password');
        }
      }
    },
    methods: {
      ...mapActions({
        setToken: types.CUSTOMER_SET_TOKEN,
        getProfileByToken: types.CUSTOMER_GET_INFO_BY_TOKEN,
      }),
      checkMobile() {
        if (!this.isMobile()) {
          this.$router.push({name: 'home'});
        }
      },
      signInGoogle () {
        var vm = this;
        this.$gAuth.signIn()
          .then(GoogleUser => {
            // On success do something, refer to https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetid
            // console.log('user', GoogleUser.getBasicProfile())
            // console.log('getAuthResponse', GoogleUser.getAuthResponse())
            // GoogleUser.getId() : Get the user's unique ID string.
            // GoogleUser.getBasicProfile() : Get the user's basic profile information.
            // GoogleUser.getAuthResponse() : Get the response object from the user's auth session. access_token and so on
            let dataCustomer = GoogleUser.getBasicProfile();
            let payload = {
              "first_name": dataCustomer.ET,
              "last_name": dataCustomer.GR,
              "email": dataCustomer.zt,
              "token": GoogleUser.getAuthResponse().id_token,
              "keep_otp": 1
            };
            vm.loginSocial(payload);
          })
          .catch(error  => {
            vm.notificationError(vm, error.message);
          })
      },
      signInGoogleV2(data) {
        const vm = this
        data.then(payload => {
          this.loginSocial(payload)
        }).catch (error => {
          this.notificationError(vm, error.message)
        })
      },
      loginSocial (payload) {
        var vm = this;
        payload.vm = this;
        CustomerApi.loginSocial(payload, function (response) {

          vm.verifyTokenSocial(response.data);
        }, function (error) {
          if (vm.$const.ERROR_CODE.LOGIN.SOCIAL_MISSING.indexOf(error.code) >= 0) {
            vm.showModalRegisterForm(payload);
          } else {
            vm.notificationError(vm, error.message);
          }
        })
      },

      verifyTokenSocial (data) {
        var vm = this;
        let mode = this;
        if (data.is_new == 0) {
          mode = vm.$const.VERIFY_TOKEN_METHOD.LOGIN;
        } else if (data.is_new == 1) {
          mode = vm.$const.VERIFY_TOKEN_METHOD.REGISTER;
        }
        let payload = {
            "type" : 1,
            "key": data.key,
            "vm" : vm,
        }
        this.otpType = 1;
        CustomerApi.sendOTP(payload, function () {
            vm.$modal.show(ModalVerifyToken, {
                    keyToken: data.key,
                    mode: mode,
                    otpType: vm.otpType,
                    callbackFunc: function (xToken) {
                        if (xToken) {
                            vm.notificationSuccess(vm, vm.$t('message.success.login'))
                        }
                        if (xToken !== true) {
                            vm.handleXToken(xToken)
                        }
                    }
                },)
        }, function (error) {
            vm.notificationError(vm, error.message);
        });
          // this.$modal.show(ModalSendOTP,
          //     {
          //         keyToken: data.key,
          //         sendOTP: function(type){
          //             let payload = {
          //                 "type" : type,
          //                 "key": data.key,
          //                 "vm" : vm,
          //             }
          //             this.otpType = type;
          //             CustomerApi.sendOTP(payload, function () {
          //                 vm.$modal.show(ModalVerifyToken, {
          //                         keyToken: data.key,
          //                         mode: mode,
          //                         otpType: vm.otpType,
          //                         callbackFunc: function (xToken) {
          //                             if (xToken) {
          //                                 vm.notificationSuccess(vm, vm.$t('message.success.login'))
          //                             }
          //                             if (xToken !== true) {
          //                                 vm.handleXToken(xToken)
          //                             }
          //                         }
          //                     },
          //                     {
          //                         'class': 'modal-register-form'
          //                     },
          //                     {
          //                         'before-open': function () {
          //                         },
          //                         'before-close': function () {
          //                         }
          //                     })
          //             }, function (error) {
          //                 vm.notificationError(vm, error.message);
          //             });
          //         }
          //     }
          // );
      },
      verifyToken (message = null) {
        var vm = this;
        this.$modal.show(ModalVerifyToken, {
            errorMessage: message,
            keyToken: vm.keyToken,
            mode: vm.$const.VERIFY_TOKEN_METHOD.LOGIN,
            otpType: vm.otpType,
            callbackFunc: function (xToken) {
              if (xToken) {
                vm.notificationSuccess(vm, vm.$t('message.success.login'))
                vm.handleXToken(xToken)
              }
            }
          },
          {
            'class': 'modal-register-form'
          },
          {
            'before-open': function () {
            },
            'before-close': function () {
            }
          })
      },
      showModalRegisterForm (payload) {
        var vm = this;
        this.$modal.show(ModalRegister, {
            save: function (data) {
              payload.phone = data.phone;
              payload.gender = data.gender;
              payload.birthday = data.birthday;
              payload.first_name = data.first_name;
              payload.last_name = data.last_name;
              vm.loginSocial(payload);
            }
          },
          {
            'class': 'modal-register-form modal-register-form-custom'
          },
          {
            'before-open': function () {
            },
            'before-close': function () {
            }
          })
      },
      checkForm(e) {
        e.preventDefault();
        let isValidForm = true;
        this.initErrorMessage();

        if (!this.email) {
          this.errors.email = this.$t('message.error.email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        } else if (!this.validEmail(this.email)) {
          this.errors.email = this.$t('message.error.invalid_email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }
        if (!this.password) {
          this.errors.password = this.$t('message.error.password');
          if (isValidForm) {
            this.$refs.password.focus();
          }
          isValidForm = false;
        }

        if (isValidForm) {
          this.login();
        }
      },
      initErrorMessage () {
        this.errors = {
          password: false,
          email: false
        }
      },
      login (){
        var vm = this;
        let payload = {
          "email": this.email,
          "password": this.password,
          "keep_otp": 1,
          "vm": this
        };
        CustomerApi.login(payload, function (response) {
            // vm.notificationSuccess(vm, vm.$t('message.success.login'));
            // vm.handleXToken(response.data['x-token']);
          vm.keyToken = response.data.key;
          let payloadOTP = {
            "type" : 1,
            "key": response.data.key,
            "vm" : vm,
          }
          // vm.otpType = type
          vm.otpType = 1
          CustomerApi.sendOTP(payloadOTP, function (responseOTP) {

              if(responseOTP.code == '200'){
                  vm.verifyToken();
              }
          }, function (error) {
              if (error.code == vm.$const.ERROR_CODE.LOGIN.MAX_OTP){
                  vm.verifyToken(error.message);
              } else {
                  vm.notificationError(vm, error.message);
              }
          });
            // vm.$modal.show(ModalSendOTP, {
            //         keyToken: response.data.key,
            //         sendOTP: function(type){
            //             let payload = {
            //                 "type" : type,
            //                 "key": response.data.key,
            //                 "vm" : vm,
            //             }
            //             vm.otpType = type
            //             CustomerApi.sendOTP(payload, function (responseOTP) {

            //                 if(responseOTP.code == '200'){
            //                     vm.verifyToken();
            //                 }
            //             }, function (error) {
            //                 if (error.code == vm.$const.ERROR_CODE.LOGIN.MAX_OTP){
            //                     vm.verifyToken(error.message);
            //                 } else {
            //                     vm.notificationError(vm, error.message);
            //                 }
            //             });
            //         }
            //     },
            //     {},
            //     {
            //         'verifyToken': function () {
            //         },
            //         'before-close': function () {
            //         }
            //     })
        }, function (error) {
            if (error.code == vm.$const.ERROR_CODE.LOGIN.MAX_OTP){
                vm.verifyToken(error.message);
            } else {
              vm.errors.email = 'Please input correct email or/and password';
              vm.errors.password = 'Please input correct email or/and password';
            }
        })
      },
      handleXToken (xToken) {
        this.setToken (xToken);
        this.getProfileByToken();
        braze.customEvent('login', {})
        if (this.cartUuid) {
          let payload = {
            "uuid": this.cartUuid,
          }
          CartApi.updateCustomer(payload)
        }
        if (this.$route.query && this.$route.query.callback) {
            if(this.$route.query.callback === 'checkout'){
                this.$router.push({name: 'checkout'});
                return;
            }
          return this.redirectInternal(this, this.$route.query.callback);
        }
        this.$router.push({name: 'home'});
        return;
      },
      _getLoginBanners() {
        const vm = this;
        ContentApi.getHomeLogo({}, function (response) {
          vm.topBackground = response.data;
          if (vm.topBackground && vm.topBackground.length > 0) {
            // const widthsize = window.innerWidth
            // vm.backgroundUrl =`${vm.$const.LINK_STATIC_FLY}width=${widthsize}/${vm.topBackground[0].small_image}`
            vm.backgroundUrl =`${vm.topBackground[0].small_image}`
            if (window.matchMedia('(min-width: 640px)').matches) {
              // vm.backgroundUrl =`${vm.$const.LINK_STATIC_FLY}width=${widthsize}/${vm.topBackground[0].middle_image}`
              vm.backgroundUrl =`${vm.topBackground[0].middle_image}`
            }
            if (window.matchMedia('(min-width: 960px)').matches) {
              // vm.backgroundUrl =`${vm.$const.LINK_STATIC_FLY}width=${widthsize}/${vm.topBackground[0].large_image}`
              vm.backgroundUrl =`${vm.topBackground[0].large_image}`
            }
          }
        })
      },
    }
  }
</script>
<style>

</style>
