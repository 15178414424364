export default {
  order: {
      "order_code": "",
      "order_uuid": "",
      "order_status_code": "",
      "order_status_uuid": "",
      "collection_time": "",
      "order_information": {}
  }
}
