<template>
  <div>
    <header-component />
    <div>
      <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
          <div class="font-open-sans-bold uppercase text-center relative mb-20">
            <h1 class="px-40">{{$t('cart.checkout')}}</h1>
            <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
          </div>
          <div class="bg-white p-10">
            <div class="payment-title text-center">
             {{time}}
            </div>
            <div class="payment-title">
              {{$t('common.virtual_account')}}
            </div>
            <div class="paymnet-box-custom">
              <div class="p-5">
                {{$t('common.payment_bca_1')}}
                <hr class="line mt-10 mb-10"/>
               {{$t('common.payment_bca_2')}}
              </div>
            </div>
            <div class="payment-title">

              {{$t('common.payment_bca_2')}}
            </div>
            <h3 class="flex flex-center flex-row flex-start">
              <img style="padding-right: 10px;max-width: 150px;"
                   src="../assets/images/logo-bca-white.jpg">
              <span id="virtual-account"
                    style="padding-right: 10px;">{{code}}</span>
            </h3>
            <a href="javascript:void(0)" @click="copyClipboard()" class="underline tooltip ml-10" >
              <span class="tooltiptext" id="myTooltip"></span>
              <span class="payment-link-customize">{{$t('common.payment_bca_4')}}</span>
            </a>
            <div class="box-payment-list">
               <h3 class=""><span class="payment-title">{{$t('common.payment_bca_5')}}</span></h3>
              <h3 class="item" style="text-transform: capitalize;">{{$t('common.format_currency', {amount: formatCurrency(amount, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</h3>
            </div>
            <div class="box-payment-list">
              <h3 class="h4">
                <div class="payment-title">{{$t('common.payment_bca_6')}}</div>
                <br/>
                <v-select :options="methodList" v-model="bank"  class="input-transparent" ref="genders" id="genders" name="SelectPayment"></v-select>
              </h3>
              <div id="instruction" class="content p-10" v-if="!emptyVariable(bank) && bank.code">
                <h2 class="h3"><span id="title-payment-method" class="gold">{{bank.label}}</span></h2>
                <div class="box-payment-list" id="content-payment-method" v-html="$t(bank.code, {company_code: this.company_code})">
                </div>
              </div>
              <h3 class="pt-10 pb-10">
                <img style="width: 50%;"
                     src="../assets/images/payment-logos.png">
              </h3>
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-component />

  </div>
</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import FooterComponent from '../components/FooterComponent';
  import OrderApi from '../services/order'
  import {mapActions, mapGetters} from 'vuex'
  import types from '../store/mutationTypes'
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import manthan from "../services/manthan";
  import branch from "../services/branch";

  export default {
    name: 'payment-status-bca',
    components: {
      HeaderComponent,
      FooterComponent,
      vSelect
    },
    beforeMount: function () {

    },
    data () {
      return {
        status: '',
        time: '',
        amount: 0,
        code: '',
        payment: {},
        bank: {},
        paymentInterval: {},
        methodList: [],
        company_code: '35600', //default company code
      }
    },
    mounted () {
      this.checkPaymentStatus(this.$route.params.uuid);

      let key = 'payment-bca-'  + this.$route.params.uuid;
      let item = JSON.parse(localStorage.getItem(key)) || [];
      if (!this.emptyVariable(item)) {
        //this.amount = item.total;
        this.code = item.code;
        this.company_code = item.code.substring(0,5)
      }
    },
    created () {
      let transaction = this.$route.params.uuid || '';
      this.initTime(transaction);
      this.initData();
    },
    watch: {

    },
    destroyed(){
      clearInterval(this.paymentInterval);
    },
    computed:{
      ...mapGetters({
        authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
        cartObject: 'moduleCart/' + types.CART_DETAIL,
        localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
        orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      }),
    },
    methods:{
      ...mapActions({
        restLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
        resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
        resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
      }),
      copyClipboard () {
        const el = document.createElement('textarea');
        el.value = this.code;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        let tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copied: " + this.code;
        tooltip.classList.add('active')
        setTimeout (function () {
          tooltip.classList.remove('active')
        },2000 )
      },
      initData() {
        let _methods = this.$const.PAYMENT_METHOD_BCA;
        for (let i in _methods) {
          _methods[i].label = this.$t(_methods[i].label);
        }
        this.methodList = _methods;
        if (this.methodList.length > 0) {
          this.bank = this.methodList[0];
        }
      },
      clearCart () {
        this.restLocalisation();
        this.resetCart();
        if (this.orderType == this.$const.ORDERTYPE.DINEIN) {
          this.resetOrder();
        }
      },
      initTime (uuid) {
        var vm = this;
        let timer = 10 * 60;
        let minutes, seconds;
        this.paymentInterval = setInterval(function () {
          minutes = parseInt(timer / 60, 10)
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          vm.time = vm.$t('common.time_format', {minutes: minutes, seconds: seconds})

          if (--timer <= 0) {
            vm.notificationError(vm, vm.$t('message.error.payment_status'));
            vm.$router.push({name: 'checkout'});
          }
          if (parseInt(seconds) % 5 == 0) {
            vm.checkPaymentStatus(uuid);
          }
        }, 1000);

      },
      checkPaymentStatus (uuid) {
        var vm = this;
        let payload = {};
        const info = {
          firstName: vm.authUser.first_name,
          lastName: vm.authUser.last_name,
          line1: vm.localisation.deliveryInfo.streetNumber || '',
          line2: vm.localisation.deliveryInfo.streetName,
          city: vm.localisation.deliveryInfo.city,
          state: vm.localisation.deliveryInfo.state,
          zip: vm.localisation.deliveryInfo.postalCode,
          country: "Indonesia",
          emailAddress: vm.authUser.email,
          phoneNumber: vm.authUser.phone
        };
        let totalDiscount = 0;
        let couponCode = "";
        let cartProducts = [];
        if (vm.cartObject.products.length > 0) {
          for (const [index, item] of vm.cartObject.products.entries()) {
            totalDiscount += item.discount
            for (const value of item.product) {
              cartProducts.push({
                productId: value.uuid,
                productPosition: index + 1,
                action: "Checkout",
                quantity: item.quantity,
                productBasePrice: (item.quantity > 0) ? item.sub_price + item.quantity : item.sub_price,
                tax: (item.quantity > 0) ? item.tax * item.quantity : item.tax,
                discount: item.discount,
                couponCode: item.promotion_code,
              });
            }
          }
        }
      if (vm.cartObject.promotions.length > 0) {
        for (const [index, item] of vm.cartObject.promotions.entries()) {
          if (index == 0) {
            couponCode = item.code;
          } else {
            couponCode += ',' + item.code;
          }
        }
      }
        OrderApi.paymentStatus(uuid, payload,
          function (response) {
            vm.payment = response.data;
            vm.amount = vm.payment.amount
            if (response.data.status == vm.$const.PAYMENT_STATUS.COMPLETED) {
              const dataManthan = {
                transactionId: uuid,
                referrerPageUrl: window.location.href,
                orderId: response.data.order_code,
                tenderType: response.data.payment_method_name,
                checkoutType: vm.isAuth ? 'Logged in by ' + vm.authUser.email : 'Guest',
                totalPrice: response.data.amount,
                shippingCost: vm.cartObject.information.delivery_fee > 0 ? vm.cartObject.information.delivery_fee : 0,
                discount: totalDiscount,
                couponCode: couponCode,
                cartProducts: cartProducts,
                billingInfo: info,
                shippingInfo: info,
              }
              // eslint-disable-next-line no-undef
              manthan.execute(dataManthan,"eventTransactionComplete")
              branch.branchLog('PURCHASE', vm.cartObject.products, dataManthan)
              clearInterval(vm.paymentInterval)
              vm.notificationSuccess(vm, vm.$t('message.success.payment'))
              setTimeout(function () {
                if (!vm.isMobile()){
                  vm.$router.push({name:'order-confirm', params: {uuid: response.data.order_uuid}})
                } else {
                  vm.$router.push({name:'my-order'})
                }
              }, 5000)
              vm.clearCart()
              } else {
                const dataManthan = {
                  transactionId: uuid,
                  referrerPageUrl: window.location.href,
                  OrderId: response.data.order_uuid,
                  tenderType: response.data.payment_method_name,
                  checkoutType: vm.isAuth ? 'Logged in by ' + vm.authUser.email : 'Guest',
                  totalPrice: response.data.amount,
                  shippingCost: vm.cartObject.information.delivery_fee > 0 ? vm.cartObject.information.sub_delivery_fee : 0,
                  discount: totalDiscount,
                  couponCode: couponCode,
                  cartProducts: cartProducts,
                  reason: "Payment Failure",
                  billingInfo: info,
                  shippingInfo: info,
                }
                // eslint-disable-next-line no-undef
                manthan.execute(dataManthan,"eventTransactionFailure");
              }
            }, function (error) {
              // vm.notificationError(vm, vm.$t('message.error.payment_status'));
          }
        )
      }
    }
  }

</script>

<style scoped>
 .payment-title{
   font-size: 18px;
   font-weight: bold;
   color: #ff992f;
   padding: 10px;
 }
  .paymnet-box-custom {
    border: 2px solid #555555;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
  }
 .paymnet-box-custom .line{
   background: #555555;
   height: 1px;
  }
  .flex-start {
    justify-content: flex-start;
  }
  .box-payment-list .item{
    padding: 0 10px;
    font-weight: bold;
    font-size: 15px;
  }
  .payment-link-customize {
    text-transform: capitalize;
    text-decoration: underline;
    color: #ff992f;
    font-size: 16px;
  }
  #title-payment-method{
    font-size: 15px;
    font-weight: bold;
    color: #ff992f;
  }
</style>
