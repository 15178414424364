<template>
    <section class="app-carousel" ref="appCarousel">
        <carousel :per-page="1" :mouse-drag="false" :autoplay="true"  :autoplayTimeout="2000" v-if="items.length > 0"  :navigationEnabled="true" :loop="true">
            <slide v-for="(item, index) in data" :key="index">
                <img :src="item" alt="" title="">
            </slide>
        </carousel>
    </section>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel';
    export default {
        name: "carousel-menu-price",
        props: {
            items: {
                type: Array,
                default: () => []
            },
            settings: {
                type: Object,
                default: () => {}
            }
        },
        components: {Carousel, Slide},
        data() {
            return {
                data: []
            }
        },
        created() {
        },
        methods: {
        },
        watch: {
            items: function() {
                // let size = this.$refs.appCarousel.clientWidth
                let $this = this

                this.items.forEach(function(item) {
                    // let img = $this.$const.LINK_STATIC_FLY + ",width=" + size + "/" + item
                    let img = item
                    $this.data.push(img)
                })
            }
        }
    }
</script>

<style scoped>

    .item-card--full {
        background-color: #f5f7f9;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .app-item-card {
        display: flex !important;
        flex-direction: column;
        /*align-self: stretch;*/
        padding: 0 5px;
    }
    .item-card-title {
        font-size: 1.2rem;
        text-transform: none;
        font-weight: bold;
    }
    .item-card-body {
        background-color: #f5f7f9;
        padding: 1rem;
        text-align: left;
    }
    .app-item-img{
        width: auto;
        height: 200px;
        vertical-align: middle;
        max-width: 100%;
        margin: auto;
    }
</style>