<template>
  <div class="container">
    <template v-if="orderCode">
      <div class="flex flex-col items-center">
        <span class="typography-3 pt-40 text-grey text-center uppercase font-semibold" :class="{'pb-40': orderDetails ? !orderDetails.name : true}" :style="{ 'font-family': 'Barlow Condensed, sans-serif' }">
          {{$t('my_order.thank_you')}}
        </span>
        <span class="typography-3 pb-40 text-grey text-center uppercase font-semibold" :style="{ 'font-family': 'Barlow Condensed, sans-serif' }" v-if="orderDetails && orderDetails.name">
          {{orderDetails.name}}
        </span>
      </div>

      <div class="flex flex-col items-center">
        <span class="typography-7 pb-10 text-center font-semibold">
          {{$t('my_order.estimated_time')}}
        </span>
      </div>

      <div class="flex flex-col items-center">
        <span class="typography-2 pb-10 text-center font-bold">
          {{ getCollectionTime() }}
        </span>
      </div>

      <div class="flex flex-col items-center">
        <span class="typography-2 py-10 text-center font-semibold">
          {{orderTime}}
        </span>
      </div>

      <div class="block-order-icon mb-70">
          <div class="w-4\/5">
              <ul class="process-bar w-full m-auto flex justify-between px-30" v-if="this.localisation.orderType === this.$const.ORDERTYPE.DINEIN || (orderDetails && orderDetails.order_type.code === $const.ORDERTYPE.DINEIN)">
                  <li class="active" :class="{'highlight': orderStatus === 1}">
                    <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 1, 'text-red': orderStatus === 1}">
                      {{$const.ORDERSTATUS.ORDERED}}
                    </span>
                    <img 
                      alt="accepted" 
                      :src="require(`@/assets/images/trackers/icon-ordered-${orderStatus === 1 ? 'active' : 'inactive'}.png`)"
                    />
                    <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                      {{$const.ORDERSTATUS.ORDERED.charAt(0).toUpperCase() + $const.ORDERSTATUS.ORDERED.slice(1).toLowerCase()}}
                    </span>
                  </li>
                  <li :class="{'highlight': orderStatus === 2, 'active': orderStatus >= 2}">
                    <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 2, 'text-red': orderStatus === 2}">
                      {{$const.ORDERSTATUS.COOKING}}
                    </span>
                    <img 
                      :src="require(`@/assets/images/trackers/icon-baking-${orderStatus === 2 ? 'active' : 'inactive'}.png`)"
                    />
                    <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                      {{$const.ORDERSTATUS.COOKING.charAt(0).toUpperCase() + $const.ORDERSTATUS.COOKING.slice(1).toLowerCase()}}
                    </span>
                  </li>
                  <li :class="{'highlight': orderStatus === 3, 'active': orderStatus > 2}">
                    <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 3, 'text-red': orderStatus === 3}">
                      {{$const.ORDERSTATUS.SERVED}}
                    </span>
                    <img 
                      :src="require(`@/assets/images/trackers/icon-served-${orderStatus === 3 ? 'active' : 'inactive'}.png`)"
                    />
                    <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                      {{$const.ORDERSTATUS.SERVED.charAt(0).toUpperCase() + $const.ORDERSTATUS.SERVED.slice(1).toLowerCase()}}
                    </span>
                  </li>
              </ul>
              <ul class="process-bar w-full m-auto flex justify-between px-30" v-else>
                  <li class="icon-4 active" :class="{'highlight': orderStatus === 1}">
                    <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 1, 'text-red': orderStatus === 1}">
                      {{$const.ORDERSTATUS.ORDERED}}
                    </span>
                    <img 
                      alt="accepted" 
                      :src="require(`@/assets/images/trackers/icon-ordered-${orderStatus === 1 ? 'active' : 'inactive'}.png`)"
                    />
                    <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                      {{$const.ORDERSTATUS.ORDERED}}
                    </span>
                  </li>
                  <li class="icon-4" :class="{'highlight': orderStatus === 2, 'active': orderStatus >= 2}">
                    <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 2, 'text-red': orderStatus === 2}">
                      {{$const.ORDERSTATUS.COOKING}}
                    </span>
                    <img 
                      :src="require(`@/assets/images/trackers/icon-baking-${orderStatus === 2 ? 'active' : 'inactive'}.png`)"
                    />
                    <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                      {{$const.ORDERSTATUS.COOKING}}
                    </span>
                  </li>
                  <template v-if="localisation.orderType === $const.ORDERTYPE.DELIVERY || (orderDetails && orderDetails.order_type.code === $const.ORDERTYPE.DELIVERY)">
                    <li class="icon-4" :class="{'highlight': orderStatus === 3, 'active': orderStatus > 2}">
                      <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 3, 'text-red': orderStatus === 3}">
                        {{$const.ORDERSTATUS.DELIVERY}}
                      </span>
                      <img 
                        :src="require(`@/assets/images/trackers/icon-delivery-${orderStatus === 3 ? 'active' : 'inactive'}.png`)"
                      />
                      <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                        {{$const.ORDERSTATUS.DELIVERY}}
                      </span>
                    </li>
                  </template>
                  <template v-else-if="localisation.orderType === $const.ORDERTYPE.CARRYOUT || (orderDetails && orderDetails.order_type.code === $const.ORDERTYPE.TAKEAWAY)">
                    <li class="icon-4" :class="{'highlight': orderStatus === 3, 'active': orderStatus > 2}">
                      <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 3, 'text-red': orderStatus === 3}">
                        {{$const.ORDERSTATUS.TAKEAWAY}}
                      </span>
                      <img 
                        :src="require(`@/assets/images/trackers/icon-collection-${orderStatus === 3 ? 'active' : 'inactive'}.png`)"
                      />
                      <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                        {{$const.ORDERSTATUS.TAKEAWAY}}
                      </span>
                    </li>
                  </template>
                  <li class="icon-4" :class="{'highlight': orderStatus === 4, 'active': orderStatus > 3}">
                    <span class="font-open-sans typography-11 font-bold uppercase letter-1" :class="{'text-grey-dark': orderStatus !== 4, 'text-red': orderStatus === 4}">
                      {{$const.ORDERSTATUS.RECEIVED}}
                    </span>
                    <img 
                      :src="require(`@/assets/images/trackers/icon-received-${orderStatus === 4 ? 'active' : 'inactive'}.png`)"
                    />
                    <span class="absolute left-0 right-0 font-open-sans-bold typography-9 capitalize font-bold text-red bottom-text">
                      {{$const.ORDERSTATUS.RECEIVED}}
                    </span>
                  </li>
              </ul>
          </div>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col items-center justified-center py-40">
        <img src="@/assets/images/unsatisfied.png">
        <p class="typography-10 font-bold text-red">{{$t('my_order.no_order')}}</p>
        <p class="typography-7 text-center">{{$t('my_order.no_order_body')}}</p>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import OrderApi from '@/services/order'
import { mapGetters } from 'vuex'
import mutationTypes from "@/store/mutationTypes"

export default {
  data() {
    return {
      orderNumber: '',
      errors: {
        isValid: false,
        orderNumber: '',
      },
    }
  },
  computed: {
    ...mapGetters ({
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
      isAuth:  'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
      cartCount: 'moduleCart/' + mutationTypes.CART_COUNT,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
      cartTotal: 'moduleCart/' + mutationTypes.CART_GET_TOTAL,
      cartSuggest: 'moduleCart/' + mutationTypes.CART_GET_SUGGESTIONS,
      cartPromo: 'moduleCart/' + mutationTypes.CART_GET_PROMOTIONS,
      orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
      orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
      timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
      localisation: 'moduleLocalisation/' +  mutationTypes.LOCALISATION_GET_LOCALISATION,
      defaultCategory:  mutationTypes.GET_DEFAULT_CATEGORY,
      cartData: 'moduleCart/' + mutationTypes.CART_DETAIL,
      orderStatusCode: 'moduleOrder/' + mutationTypes.ORDER_GET_ORDER_STATUS_CODE,
      orderCode: 'moduleOrder/' + mutationTypes.ORDER_GET_ORDER_CODE,
      orderCollectionTime: 'moduleOrder/' + mutationTypes.ORDER_GET_COLLECTION_TIME,
      orderDetails: 'moduleOrder/' + mutationTypes.ORDER_GET_ORDER_INFORMATION,
    }),
    orderStatus() {
      let number = 1
      if (this.orderDetails && this.orderDetails.order_status.step.sequence) {
        number = this.orderDetails.order_status.step.sequence
      } else {
        if (this.localisation.orderType === this.$const.ORDERTYPE.DINEIN) {
          if (this.$const.ORDERSTATUS_DINEIN_MAPPING_CODE.COOKING.indexOf(this.orderStatusCode) !== -1) {
            number = 2
          } else if (this.$const.ORDERSTATUS_DINEIN_MAPPING_CODE.SERVED.indexOf(this.orderStatusCode) !== -1) {
            number = 3
          }
        } else {
          if (this.$const.ORDERSTATUS_MAPPING_CODE.BAKING.indexOf(this.orderStatusCode) !== -1) {
            number = 2
          } else if (this.$const.ORDERSTATUS_MAPPING_CODE.DELIVERING.indexOf(this.orderStatusCode) !== -1) {
            number = 3
          } else if (this.$const.ORDERSTATUS_MAPPING_CODE.FINISHED.indexOf(this.orderStatusCode) !== -1) {
            number = 4
          }
        }
      }
      return number
    }
  },
  watch: {
    'orderNumber': function (newValue) {
      this.errors.orderNumber = false;
      if (!newValue) {
        this.errors.orderNumber = this.$t('message.error.order_number');
      }
    }
  },
  methods: {
    addMore() {
      if (this.localisation.cartUuid) {
        this.viewAllDeal()
      }
    },
    viewAllDeal(){
        if (this.storeUuid) {
            this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
        } else {
            this.$router.push({name: 'home', query: {warning: '1', callback:'order/hot-promo'}});
            return false;
        }
    },
    initErrorMessage (mode) {
      if (mode && mode === 'inputPass') {
        this.errors = {
          isValid: false,
          orderNumber: false,
        }
      } else {
        this.errors = {
          isValid: false,
          orderNumber: false,
        }
      }
    },
    getCollectionTime() {
      return moment.utc(this.orderCollectionTime).local().format('hh:mm A')
    },
    checkForm(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();

      if (!this.orderNumber) {
        this.errors.orderNumber = this.$t('message.error.order_number');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      }

      if (isValidForm) {
        this.searchOrderLatest(this.orderNumber);
      }
    },
    searchOrderLatest (orderNumber) {
      var vm = this;
      OrderApi.orderLatest({vm: this, order_number: orderNumber},  function (response) {
        vm.$router.push({name:'order-confirm', params: {uuid: response.data.uuid}});
      } ,function (error) {
        vm.notificationError(vm, error.message);
      })
    },
  }
}
</script>

<style scoped>
  .process-bar li{
    list-style: none;
    float: left;
    position: relative;
    width: 25%;
    text-align: center;
    font: normal normal normal 14px/1 FontAwesome;
  }
  .process-bar li:before {
    content: '';
    width: 20px;
    height: 20px;
    line-height: 20px;
    outline: 0;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #e399a6;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
  }
  .process-bar li.highlight:before,
  .process-bar li.active:before {
    background-color: #cd2642;
  }
  .process-bar li.highlight:before {
    outline: 3px solid #e399a6;
  }
  .process-bar li:after {
    content: '';
    position: absolute;
    width: 150%;
    height: 2px;
    background-color: #e399a6;
    bottom: -21px;
    left: -100%;
    z-index: 1;
  }
  .process-bar li.icon-4:after {
    width: 100%;
    left: -50%;
  }
  .process-bar li.active:after {
    background-color: #cd2642;
  }
  .process-bar li:first-child:after {
    content: none;
  }
  .process-bar li.active {
    color: white;
  }
  .process-bar li.active:before,
  .process-bar li.highlight:before {
    color: white;
    background-color: #cd2642;
    content: '\f00c';
    font-size: 0.675rem;
  }
  .process-bar li img {
    margin: auto;
  }
  .process-bar .letter-1 {
    letter-spacing: -1px;
  }
  .process-bar .bottom-text {
    bottom: -56%;
  }
</style>