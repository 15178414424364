<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container h-full product-detail-model-container">
          <div class="modal-header" style="padding-top: 0;">
            <div class="product-detail-image-bg" :style="{ backgroundImage: `url(${require('@/assets/images/detail-bg.png')})` }">
              <img class="m-auto" :src="productData.image" />
            </div>
            <button type="button" aria-label="Close" class="product-detail-close-btn close" @click="$emit('close')"><i class="fa fa-angle-left" /></button>
          </div>

          <div class="modal-body product-detail-body container mb-100">
            <div class="title-block">
              <h2 class="font-united-sans-bold uppercase px-15 mb-0 mt-10">{{ productData.name }}</h2>
              <div class="font-open-sans text-grey px-15" v-html="productData.description"></div>
            </div>
            <div class="option-group-block flex flex-col" v-if="crustList.length > 0">
              <div class="bg-grey-lighter typography-7 font-semibold px-15 py-10 my-10" v-if="productData.category_type !== $const.CATEGORY_TYPE_STRING.CHICKEN">{{$t('menus.select_crust')}}</div>
              <div class="bg-grey-lighter typography-7 font-semibold px-15 py-10 my-10" v-else>{{$t('menus.select_sauce')}}</div>
              <div class="flex overflow-x-auto" style="max-width: 100vw;">
                <div class="inline-flex mx-auto">
                  <template v-for="(crust, key) in crustList">
                    <div class="crust-box border-2 border-solid border-grey-lighter border-rounded mx-5" :class="{'border-red': selectedCrust.uuid === crust.uuid}" :key="key" @click="selectedCrust = crust" v-if="productData.category_type !== $const.CATEGORY_TYPE_STRING.CHICKEN">
                      <img :src="crust.thumbnail" />
                    </div>
                    <div class="crust-box special border-2 border-solid border-grey-lighter border-rounded mx-5" :class="{'border-red': selectedCrust.uuid === crust.uuid}" :key="key" @click="selectedCrust = crust" v-else-if="productData.category_type === $const.CATEGORY_TYPE_STRING.CHICKEN">
                      <p class="typography-9 text-center whitespace-no-wrap text-grey-dark px-10 mt-10">{{ crust.name }}</p>
                      <p class="typography-9 font-bold text-center whitespace-no-wrap text-grey-dark px-10">{{ $t('common.format_currency', {amount: formatCurrency(crust.sub_price || 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY}) }}</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="option-block flex flex-col" v-if="sizeList.length > 0">
              <div class="bg-grey-lighter typography-7 font-semibold px-15 py-10 my-10">{{$t('menus.select_size')}}</div>
              <div class="flex mx-auto">
                <template v-for="(size, key) in sizeList">
                  <div class="size-box border-2 border-solid border-grey-lighter border-rounded text-center pb-20 mx-5" :class="{'border-red': selectedSize.uuid === size.uuid}" :key="key" @click="selectedSize = size">
                    <span class="text-black">{{size.name}}</span>
                    <img :src="size.thumbnail" />
                  </div>
                </template>
              </div>
            </div>
            <div class="quantity-block flex flex-col">
              <div class="bg-grey-lighter typography-7 font-semibold px-15 py-10 my-10">{{$t('common.quantity')}}</div>
              <div class="flex mx-auto mt-5">
                <button class="decrement-button border-2 border-solid border-rounded" :class="{ ' border-red text-red': !disabledDecrement, 'border-grey-lighter text-grey-lighter': disabledDecrement }" :disabled="disabledDecrement" @click="decrementQuantity">−</button>
                <input class="quantity-input font-open-sans text-grey border border-solid border-grey border-rounded" name="quantity" type="number" :min="min" :max="max" :step="step"
                        v-model.number="quantity"
                        @keydown.esc="validateAmount"
                        @blur="validateAmount"
                        @keydown.enter="validateAmount"
                />
                <button class="increment-button border-2 border-solid border-rounded" :class="{ ' border-red text-red': !disabledIncrement, 'border-grey-lightest text-grey-lightest': disabledIncrement }" :disabled="disabledIncrement" @click="incrementQuantity">+</button>
              </div>
            </div>
            <div class="extracheese-block flex flex-col px-15 mt-20" v-if="extraCheese.uuid && !emptyVariable(extraCheese.options)">
              <div class="flex mb-5 flex-row border border-grey-light border-rounded px-15 py-15">
                <label class="label mb-0 flex-center text-grey-darkest" for="term">
                  <div v-html="extraCheese.name"></div>
                </label>
                <span class="typography-7 text-grey-dark font-semibold ml-auto mr-10">{{$t('common.format_currency', {amount: formatCurrency(currentExtraCheese.sub_price || 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                <label class="switch">
                  <input type="checkbox" v-model="selectedExtraCheese" :true-value="1" :false-value="0">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="note-block px-15 mt-15">
              <div class="input-group border border-grey-light border-rounded px-15 py-10">
                <div class="input-group-append"><img class="m-auto" src="@/assets/images/note-icon.png" alt="note-icon" style="width: 30px;" /></div>
                <input type="text" v-model="note" class="form-control border-0 text-grey-dark typography-12 font-open-sans font normal" :placeholder="$t('common.item_note')"/>
              </div>
            </div>
          </div>

          <div class="modal-footer product-detail-footer absolute left-0 bottom-0 right-0 bg-white py-5">
            <button @click="addItemIntoCart" class="button button--md button--red flex-1 font-semibold t15 mt13" v-if="productData.point > 0">
                <span>{{$t('common.add_to_redeem')}}</span>
                <span class="w-auto" style="margin-left: 3px;">{{$t('common.format_point', {amount: formatCurrency(productData.point, $const.SUFFIX_CURRENCY)})}}</span>
            </button>
            <button @click="addItemIntoCart" class="button button--md button--red flex-1 font-semibold t15 mt14 py-10 h-auto" v-else>
                <span class="mx-auto">{{!isCombo ? $t('common.add_to_cart') : $t('common.add_to_deal')}}</span>
                <span class="absolute right-0 mr-20 w-auto" style="margin-left: 3px;" v-if="amount > 0">{{$t('common.format_currency', {amount: formatCurrency(amount, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { cloneDeep } from 'lodash'

export default {
  props: {
    productData: {
      type: Object,
      default: () => ({})
    },
    addToCart: {
      type: Function
    },
    addToDeals: {
      type: Function
    },
    isCombo: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 99
    },
    min: {
      type: Number,
      default: 1
    },
    step: {
      type: Number,
      default: 1
    }
  },

  data: () => ({
    note: '',
    quantity: 1,
    selectedCrust: {},
    selectedSize: {},
    selectedExtraCheese: 0,
    hasExtraCheese: {},
    disabledDecrement: true,
    disabledIncrement: false,
  }),

  computed: {
    crustList() {
      if (this.productData.option_groups) {
        if (!this.selectedCrust.uuid) {
          this.$set(this, 'selectedCrust', this.productData.option_groups[0])
        }
        return this.productData.option_groups
      }
      return []
    },
    sizeList() {
      if (this.productData.option_groups && !this.selectedSize.uuid) {
        if (this.selectedCrust.options && this.selectedCrust.options.length > 0) {
          this.$set(this, 'selectedSize', this.selectedCrust.options[0])
        }
      }
      return this.selectedCrust.options || []
    },
    extraCheese() {
      if (this.productData.toppings && this.productData.toppings.additional_toppings.length > 0) {
        return this.productData.toppings.additional_toppings[0]
      }

      return {}
    },
    currentExtraCheese() {
      if (!this.emptyVariable(this.extraCheese) && this.extraCheese.category_type_id === this.$const.ADDITIONAL_TYPE.ADDON){
        let res = {}
        if (this.productData.option_groups && this.extraCheese.options) {
          for (const item of this.extraCheese.options) {
            if (item.uuid === this.selectedSize.uuid) {
              res = item
              break
            }
          }
        } else {
          res = this.extraCheese.options[0]
        }
        return res
      }
      return {}
    },
    amount() {
      let amount = this.productData.sub_price
      let _selectedCrustItem = this.selectedCrust ? cloneDeep(this.selectedCrust) : {}
      let _selectedSizeItem = this.selectedSize ? cloneDeep(this.selectedSize) : {}
      if (!this.emptyVariable(_selectedCrustItem)) {
        amount += _selectedCrustItem.sub_price
      }
      if (!this.emptyVariable(_selectedSizeItem)) {
        amount += _selectedSizeItem.sub_price
      }
      if (!this.emptyVariable(this.hasExtraCheese)) {
        amount += this.hasExtraCheese.sub_price
      }
      amount = amount * this.quantity
      return amount || 0
    }
  },
  
  watch: {
    selectedExtraCheese(v) {
      if (v === 1) {
        this.$set(this, 'hasExtraCheese', this.currentExtraCheese)
      } else {
        this.$set(this, 'hasExtraCheese', {})
      }
    }
  },

  methods: {
    validateAmount(){
      if ( !Number.isInteger(this.quantity) || this.quantity < 0) {
        this.$set(this, 'quantity', this.amount ? this.amount : 0)
      }
    },
    incrementQuantity() {
      if (this.disabledIncrement || this.isCombo) {
        return
      }
      this.$set(this, 'disabledDecrement', false)
      this.handleValue(this.quantity + (this.step * 1))
    },
    handleValue (value){
      const old = this.quantity

      if (old === value) {
        return
      }
      if (value <= this.min) {
        value = this.min
        this.$set(this, 'disabledDecrement', true)
      }
      if (value >= this.max) {
        value = this.max
        this.$set(this, 'disabledIncrement', true)
      }
      this.$set(this, 'quantity', value)
    },
    decrementQuantity() {
      if (this.disabledDecrement || this.isCombo) {
        return
      }
      this.$set(this, 'disabledIncrement', false)
      this.handleValue(this.quantity + (this.step * (-1)))
    },
    addItemIntoCart() {
      if (!this.isCombo) {
        this.addToCart(this.quantity, this.selectedCrust, this.selectedSize, this.hasExtraCheese, this.note)
      } else {
        let _selectedCrustItem = this.selectedCrust ? cloneDeep(this.selectedCrust) : {};
        let _selectedSizeItem = this.selectedSize ? cloneDeep(this.selectedSize) : {};
        let _extracheese = {};
        if (!this.emptyVariable(this.hasExtraCheese)) {
          _extracheese = this.hasExtraCheese
          _extracheese.parent_uuid = this.extraCheese.uuid
        }
        let hasOption = false;
        if (this.crustList.length >= 2) {
          hasOption = true;
        } else if (this.crustList.length == 1 && this.crustList[0].options && this.crustList[0].options.length > 1 ) {
          hasOption = true;
        }
        let dataProduct = {
          "crust": _selectedCrustItem ? _selectedCrustItem : {},
          "size": _selectedSizeItem ? _selectedSizeItem : {},
          "product": this.productData,
          "quantity": 1,
          "hasExtraCheese": _extracheese,
          "hasOption": hasOption,
          "total": this.amount,
          "totalPoint": this.totalPoint,
          "toppings": {
            "removal": [],
            "addition": []
          },
          "note": this.note
        }
        this.addToDeals(dataProduct)
      }
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.product-detail-model-container {
  overflow-y: auto;
  .crust-box {
    width: 70px;
    height: 70px;
    color: #da291c;
    display: inline-block;
    &.special {
      width: auto;
      max-width: 200px;
    }
  }
  .size-box {
    width: 70px;
    color: #da291c;
  }
  .decrement-button, .increment-button {
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
  }
  .quantity-input {
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
    margin: 0 20px;
    font-size: 0.857rem !important;
  }
}

.product-detail-image-bg {
  background: grey;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  aspect-ratio: 16/9;
}

.product-detail-close-btn {
  position: absolute;
  font-size: 2rem;
  background: white;
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0 !important;
  margin-top: 15px !important;
  margin-left: 15px !important;
  border-radius: 50%;
  color: grey;
}
</style>