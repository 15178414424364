<template>
  <transition>
      <div class="modal-mask">
          <div class="modal-wrapper">
              <div class="modal-container">
                  <div class="modal-header"  >
                      <h5 class="modal-title text-center text-18 w-full">Warning</h5>
                  </div>
                  <div class="modal-body text-center">
                      <span class="text-black">{{ this.$t("cart.clear_cart_confirmation") }}</span>
                      <button type="button" class="button button--white-red" style="margin-bottom: 10px; margin-top: 5px" @click="closeCart">No</button>
                      <button class="button button--red" @click="clearCart">Yes</button>
                  </div>
              </div>
          </div>
      </div>
  </transition>
</template>

<script>
  import types from '../../store/mutationTypes'
  import { mapActions } from 'vuex'

  export default {
      name: "ModalClearCart",
      methods:{
        ...mapActions ({
          resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
          resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
          resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
        }),
        closeCart() {
          this.$emit('valueModal', false, false);
        },
        clearCart(){
          this.resetOrder();
          this.resetLocalisation();
          this.resetCart();
          this.$emit('valueModal', false, true);
        }
      }
  }
</script>

<style scoped>

</style>