<template>
  <div>
    <qrcode-stream :paused="paused" @decode="onDecode" @init="onInit" v-if="!$route.query.uuid"></qrcode-stream>
    <ModalClearCart v-if="isShowModal" @valueModal="setModalClearCart"></ModalClearCart>
  </div>
</template>

<script>
import moment from 'moment'
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapActions, mapGetters } from 'vuex'
import types from '../../store/mutationTypes'
import StoreApi from '../../services/store'
import MenuApi from '../../services/menu'
import OrderApi from '../../services/order'
import ModalNotifyBlack from "../modal/ModalNotifyBlack";
import ModalClearCart from "../modal/ModalClearCart";

export default {
  components: {
    QrcodeStream,
    ModalNotifyBlack,
    ModalClearCart
  },
  data() {
    return {
      error: null,
      scannedData: null,
      paused: false,
      storeTypeId: null,
      lat: 0,
      long: 0,
      isPopupVisible: false,
      popupText: '',
      nextPage: false,
      checkOpeningStore: false,
      invalidStatusCodes: [this.$const.ORDERSTATUS.FINISHED, this.$const.ORDERSTATUS.CANCELED, this.$const.ORDERSTATUS.REJECTED],
      isShowModal: false
    }
  },
  async created() {
    await this.getLatLong()
    if (this.lat && this.long) {
      let queries = undefined, segment = undefined, storeCode = undefined, table = undefined
      if (this.$route.query.uuid) {
        queries = atob(this.$route.query.uuid)
        const url = new URL('https://' + window.location.host + '/?' + queries)
        segment = url.searchParams.get("segment")
        storeCode = url.searchParams.get("store");
        table = url.searchParams.get("table")
      }
      let mustClear = false
      if (this.cartData.information && Object.keys(this.cartData.information).length > 0) {
        if (moment().unix() > moment(this.cartData.information.created_at).subtract(1, 'days').unix()) {
          mustClear = true
        }
      }
      if (Object.keys(this.orderDetails).length > 0) {
        if (moment().unix() > moment(this.orderDetails.collection_time).subtract(-1, 'days').unix()) {
          mustClear = true
        }
      }
      if (!mustClear) {
        if (!(this.localisation.orderType === this.$const.ORDERTYPE.DINEIN && this.localisation.tableNumber && this.orderCode) && this.$route.query.uuid) {
          if (this.cartUuid) {
            if ((storeCode && this.localisation.code === storeCode) && (table && this.localisation.tableNumber === table)) {
              this.setModalClearCart(true, null)
            } else {
              this.onDecode(window.location.href, null)
            }
          } else {
            if (segment && segment === this.$const.ORDERTYPE.DINEIN.toLowerCase()) {
              this.onDecode(window.location.href, null)
            }
          }
        } else if (this.localisation.orderType === this.$const.ORDERTYPE.DINEIN && this.localisation.tableNumber && this.orderCode && this.orderDetails && this.orderDetails.order_type.code === this.$const.ORDERTYPE.DINEIN) {
          if ((storeCode && this.localisation.code === storeCode) && (table && this.localisation.tableNumber === table)) {
            this.notificationError(this, this.$t("message.error.complete_order"));
            this.$router.push({name: 'status'});
          } else {
            if (segment && segment === this.$const.ORDERTYPE.DINEIN.toLowerCase()) {
              this.onDecode(window.location.href, null)
            }
          }
        } else if (this.localisation.orderType === this.$const.ORDERTYPE.DINEIN && this.localisation.tableNumber && !this.orderCode) {
          if ((storeCode && this.localisation.code === storeCode) && (table && this.localisation.tableNumber === table)) {
            this.viewAllDeal()
          } else {
            if (segment && segment === this.$const.ORDERTYPE.DINEIN.toLowerCase()) {
              this.onDecode(window.location.href, null)
            }
          }
        } else {
          this.onDecode(window.location.href, null)
        }
      } else {
        this.resetLocalisation()
        this.resetCart()
        this.resetOrder()
        if (segment && segment === this.$const.ORDERTYPE.DINEIN.toLowerCase()) {
          this.onDecode(window.location.href, null)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      defaultCategory:  types.GET_DEFAULT_CATEGORY,
      orderNow:  'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_NOW,
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      store: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      authUser: 'moduleAuth/' + types.CUSTOMER_GET_INFO,
      orderCode: 'moduleOrder/' + types.ORDER_GET_ORDER_CODE,
      localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      orderDetails: 'moduleOrder/' + types.ORDER_GET_ORDER_INFORMATION,
      cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
      cartData: 'moduleCart/' + types.CART_DETAIL,
    }),
  },
  methods: {
    ...mapActions({
      menuCategoryList: types.SET_MENU_CATEGORY_LIST,
      setDefaultCategory: types.SET_DEFAULT_CATEGORY,
      setStore: 'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
      resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
      saveOrder:  'moduleOrder/' + types.ORDER_SAVE_ITEM,
      resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
      setDeepLink: types.SET_DEEP_LINK,
    }),
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "You need to grant Camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "No camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "Secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "Is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "Installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "Stream API is not supported in this browser"
        }
        this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.error},
                {resizable: false, clickToClose: false, 
                    classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
      }
    },
    async onDecode(result, params) {
      this.paused = true
      this.scannedData = result
      let vm = this
      this.paused = true
      let storeCode, tableNum
      let data = {vm}
      const questionMarkIndex = this.scannedData.indexOf("?");
      const urlTemp = new URL(this.scannedData)

      if (questionMarkIndex !== -1) {
        // Mengambil string setelah tanda tanya
        let domainString = this.scannedData.substring(0, questionMarkIndex);
        // let queryString = this.scannedData.substring(questionMarkIndex + 1);
        let decodeString = atob(urlTemp.searchParams.get('uuid'))
        let urlString = domainString + "?" + decodeString
        const url = new URL(urlString);
        this.setDeepLink(this.scannedData)

        // Mengambil nilai dari parameter "segment", "store", dan "table"
        storeCode = url.searchParams.get("store");
        tableNum = url.searchParams.get("table");
      }

      if (storeCode && tableNum) {
        if (vm.isAuth){
          let payloadOrderTable = {
            ...data,
            store_code: storeCode,
            table: tableNum,
            lat: vm.lat,
            long: vm.long
          }
          await OrderApi.orderTable(payloadOrderTable, 
          (response) => {
            if (!this.invalidStatusCodes.includes(response.data.order_status.code)) {
              if (this.localisation.orderType !== '') {
                vm.resetLocalisation()
                vm.resetCart()
                vm.resetOrder()
              }
              vm.saveOrder({
                order_code: response.data.code,
                order_uuid: response.data.uuid,
                order_status_code: response.data.order_status.code, 
                order_status_uuid: response.data.order_status.uuid,
                collection_time: response.data.collection_time,
                order_information: response.data
              })
              this.nextPage = true
            } else {
              vm.resetOrder()
              this.nextPage = true
            }
          }, 
          (response) => {
            if (response.code == 6111) {
              // out of 100m
              this.$modal.show(ModalNotifyBlack, {hideOK: true, goHome: true, contain: this.$t(response.message)},
                          {resizable: false, clickToClose: false,
                              classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
              this.nextPage = false
              this.resetLocalisation()
            } else if (response.code == 6102) {
              // order not found
              vm.resetOrder()
              this.nextPage = true
            } else if (response.code == 1001) {
              // missing param tableNum or storeCode
              this.$modal.show(ModalNotifyBlack, {hideOK: true, goHome: true, contain: "Something went wrong, please scan again"},
                {resizable: false, clickToClose: false, 
                    classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
            }
          })

        } else {
          let payloadOrderTable = {
            ...data,
            store_code: storeCode,
            table: tableNum,
            lat: vm.lat,
            long: vm.long
          }
          await OrderApi.orderTableGuest(
          payloadOrderTable, 
          (response) => {
            if (!this.invalidStatusCodes.includes(response.data.order_status.code)) {
              if (this.localisation.orderType !== '') {
                vm.resetLocalisation()
                vm.resetCart()
                vm.resetOrder()
              }
              vm.saveOrder({
                order_code: response.data.code,
                order_uuid: response.data.uuid,
                order_status_code: response.data.order_status.code, 
                order_status_uuid: response.data.order_status.uuid,
                collection_time: response.data.collection_time,
                order_information: response.data
              })
              this.nextPage = true
            } else {
              vm.resetOrder()
              this.nextPage = true
            }
          }, 
          (response) => {
            if (response.code == 6111) {
              // out of 100m
              this.$modal.show(ModalNotifyBlack, {hideOK: true, goHome: true, contain: this.$t(response.message)},
                          {resizable: false, clickToClose: false,
                              classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
              this.nextPage = false
              this.resetLocalisation()
            } else if (response.code == 6102) {
              // order not found
              vm.resetOrder()
              this.nextPage = true
            } else if (response.code == 1001) {
              // missing param tableNum or storeCode
              this.$modal.show(ModalNotifyBlack, {hideOK: true, goHome: true, contain: "Something went wrong, please scan again"},
                {resizable: false, clickToClose: false, 
                    classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
            }
          })

        }
      } else {
        // this.$modal.show(ModalNotifyBlack, {hideOK: true, goHome: true, contain: "Please scan again using QR Code for Dine In"},
        //         {resizable: false, clickToClose: false, 
        //             classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
      }

      if (this.nextPage == true) {
        await StoreApi.searchByCode(storeCode, data, 
          (response) => {
              let store = response.data
              vm.checkOpeningStore = vm.checkOpeningTime(store.opening_timings, vm.$const.ORDER_TYPE_MAPPING.DINEIN, vm.$const.CHANNEL.ONLINE)
              let storeDataNew = {
                "note": store.hasOwnProperty('note') ? store.note : '',
                "clientUUID": store.client_uuid || '',
                "code": store.code || '',
                "distance": store.distance || 0,
                "lat": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lat : store.lat,
                "location": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.deliveryAddress.replace(', Indonesia', '') : '',
                "addressStore": store.location,
                "long": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lng : store.long,
                "name": store.name,
                "tierUUID": store.tier_uuid || null,
                "uuid": store.uuid,
                "timezone": store.timezone,
                "deliveryInfo": {},
                "businessHours": store.business_hours,
                "orderNow": this.orderNow,
                "orderType": this.$const.ORDERTYPE.DINEIN,
                "storeType": store.store_type_id,
                "storeBranch": store.store_branch,
                "tableNumber": tableNum
            }
            this.setStore(storeDataNew)
            vm.storeTypeId = response.data.store_type_id
          }
        ) 

        if (vm.checkOpeningStore) {
          data = { vm: this, store_type_id: this.storeTypeId }
          await MenuApi.categories(data, function (response) {
            let categoriesData = response.data;
            categoriesData.push(vm.$const.COUPON_CAT);
            vm.handleSetDefaultCategory(categoriesData, params);
            vm.menuCategoryList(categoriesData);
          }, function () {
            vm.handleSetDefaultCategory([], params);
            vm.menuCategoryList([]);
          })
          this.viewAllDeal()
        } else {
          this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.$t('message.error.store_closed')},
          {resizable: false, clickToClose: false, classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
          // this.$router.go(-1)      
        }
      } else {
        // this.$router.go(-1)  
      }
      this.paused = false
    },
    viewAllDeal(){
      if (this.storeUuid) {
        if (this.orderCode) {
          this.$router.push({name: 'status'});
        } else {
          this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
        }
      } else {
          this.$router.push({name: 'home', query: {warning: '1', callback:'order/hot-promo'}});
          return false;
      }
    },
    handleSetDefaultCategory (data, params) {
      let slug = this.defaultCategory;
      let _currentSlug = '';
      let defaultSlug = '';
      for (let item of data) {
        if (item.slug === slug) {
          _currentSlug = item.slug;
        }
        if (item.slug === this.$const.CATEGORY.DEAL) {
          defaultSlug = item.slug;
        }
      }

      if (slug === '' && data.length > 0) {
          slug = data[0].slug;
      }
      if (defaultSlug === '' && data.length > 0) {
        defaultSlug = data[0].slug;
      }
      this.setDefaultCategory(defaultSlug);

      if (params && params.goDeal) {
        this.$router.push({name: 'category', params: {slug: defaultSlug}});
      } else if ( params && params.callback) {
        let path =  params.callback.replace(/\/\/\//g, '');
        return this.redirectInternal(this, path);
      } else if ([this.$const.ROUTE.CATEGORY, this.$const.ROUTE.COMBO_DETAIL].indexOf(this.$route.name) >= 0) {
        this.setSelectedCategory(this.$route.name, _currentSlug, data);
      }
    },
    setModalClearCart(value, hasStore) {
      if (hasStore === false) {
        this.$router.push({name: 'home'})
      } else if (hasStore === true) {
        const queries = atob(this.$route.query.uuid)
        const url = new URL('https://' + window.location.host + '/?' + queries)
        const segment = url.searchParams.get("segment")
        if (segment === this.$const.ORDERTYPE.DINEIN.toLowerCase()) {
          this.onDecode(window.location.href, null)
        }
      }
      this.isShowModal = value
    },
  }
}
</script>
