<template>
  <div ref="googleLoginBtn" class="btn-google w-full" :class="{'mt-15': isMobile()}" id="google-sign-in" />
</template>

<script >
import jwt_decode from "jwt-decode"
    export default {
      name: 'GoogleLoginBtn',
      
      data: () => ({
        checkGoogleInterval: null
      }),

      created() {
        // window.onload = function() {
        //   let frameElement = document.getElementsByTagName("iframe");
        //   let doc = frameElement.contentDocument;
        //   doc.body.innerHTML = doc.body.innerHTML + '<style> #container {display: list-item !important}   div[role="buton"] {max-width: 100%}</style>';
        // }
        // this.onWindowLoad()
      },
      mounted() {
        this.checkGoogleInterval = setInterval(() => {
          if (window.google) {
            window.google.accounts.id.initialize({
              client_id: process.env.VUE_APP_OAUTH_ID,
              callback: this.handleCredentialResponse,
              auto_select: false,
              // allowed_parent_origin: true,
            })
            window.google.accounts.id.renderButton(
              this.$refs.googleLoginBtn, {
                text: 'signup_with', // or 'signup_with' | 'continue_with' | 'signin'
                size: 'large', // or 'small' | 'medium'
                theme: 'outline', // or 'filled_black' |  'filled_blue'
                logo_alignment: 'center', // or 'center',
                width: this.isMobile() ? 328 : 400
              }
            )
            clearInterval(this.checkGoogleInterval)
          }
        }, 200)
        // setTimeout(() => {
        //   let frameElement = this.$refs.googleLoginBtn.getElementsByTagName("iframe")[0]
        //   let doc = frameElement.contentDocument
        //   // doc.body.innerHTML = doc.body.innerHTML + '<style> #container { padding: 2px 0; display: block !important; color: red} div[role="buton"] {max-width: 100%}</style>'
        //   console.log(doc)
        // }, 5000)
      },
      methods: {
        handleCredentialResponse(response) {
          const responsePayload = jwt_decode(response.credential);
          // console.log(responsePayload)
          const promData =  new Promise((resolve, reject) => {
            if (responsePayload) {
              return resolve({
                "name": responsePayload.name,
                "first_name": responsePayload.given_name,
                "last_name": responsePayload.family_name,
                "email": responsePayload.email,
                "token": response.credential,
                "keep_otp": 1
              })
            } else {
              return reject({
                message: 'Google sign in failed.'
              })
            }
          })

          this.$emit('handleUserInfo', promData)
        }
      },
    }
</script>

<style>
  .btn-google > div {
    margin-left: -10px;
  }
  .btn-google span {
    color: #da291c !important;
    font-weight: bold !important;
    font-size: 16px;
  }
  .btn-google iframe {
    width: 100% !important;
    margin: 0 !important;
  }
  iframe #container {
    display: block !important;
  }
  div[role="buton"] {
    max-width: 100%;
  }
  iframe #container {
    display: list-item !important
  }
  iframe div[role="buton"] {
    max-width: 100%
  }
</style>
