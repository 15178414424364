import { render, staticRenderFns } from "./ModalStoreCarryout.vue?vue&type=template&id=478bcb74&scoped=true"
import script from "./ModalStoreCarryout.vue?vue&type=script&lang=js"
export * from "./ModalStoreCarryout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478bcb74",
  null
  
)

export default component.exports