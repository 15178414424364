var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-GB app-bg"},[_c('div',{staticClass:"container mt-20 mb-40"},[_c('div',{staticClass:"p-20 bg-white"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"photo"},[(!_vm.isMobile())?_c('ul',[_vm._m(2),_vm._m(3),_vm._m(4)]):_c('ul',{staticStyle:{"flex-direction":"column"}},[_vm._m(5),_vm._m(6),_vm._m(7)])]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticStyle:{"color":"#c8102e"}},[_c('b',[_vm._v("Brand Story")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"color":"#c8102e"}},[_c('b',[_vm._v("Kenalin, kami Pizza Hut")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story1.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story2.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/BrandStory_photo_3.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story1.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story2.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/BrandStory_photo_3.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_c('br')]),_c('span',[_vm._v("Cerita kami di Indonesia, dimulai pada tahun 1984, dimana Outlet Pertama Pizza Hut dibuka di gedung Djakarta Theatre. Hingga di tahun 2004, Pizza Hut di Indonesia makin mengepakkan sayapnya lewat akuisisi dari Sriboga Group. Pizza Hut Indonesia kemudian melakukan penawaran umum perdana melalui Bursa Efek Indonesia di tahun 2018.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Produk-produk Pizza Hut mendapatkan Sertifikasi Halal dari Majelis Ulama Indonesia sejak tahun 1997. Pizza Hut Indonesia selalu memastikan kepatuhan halal baik dalam proses perpanjangan saat berakhir maupun sertifikasi untuk produk-produk baru sebelum diperkenalkan kepada Masyarakat.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Hingga saat ini Pizza Hut telah berada di Indonesia selama 40 tahun, dan saat ini mempekerjakan lebih dari 13.000 karyawan di 600+ Outlet dari Sabang sampai Merauke, dipimpin oleh jajaran Direksi dan Komisaris yang keseluruhannya Warga Negara Indonesia.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Pizza Hut Indonesia terdiri dari Pizza Hut Restaurant dan PHD (Pizza Hut Delivery) dengan layanan “30 menit pasti tiba”. Karena kami mengerti, kesibukan dan terbatasnya waktu konsumen yang ingin semuanya serba cepat, sehingga pesanan tetap hangat dan nikmat saat tiba di tujuan. Kami selalu berkomitmen untuk memberikan pelayanan terbaik dan inovasi bagi pelanggan setia Pizza Hut Indonesia.")])])
}]

export { render, staticRenderFns }