<template>
  <div>
      <div class=" en-GB app-bg">
          <div class="bg-white">
            <!-- <div v-if="!isMobile()" class="relative">
              <img src="../assets/images/Banner_Benefit_Web.jpg" alt="" style="width: 100%">
            </div>
            <reward-level-section :is-auth="isAuth" :auth-user="authUser" />
            <div v-if="isMobile()" class="relative mobile-banner cursor-pointer" >
              <img src="../assets/images/Banner_Benefit_Web_mobile.png" alt="Banner_Benefit_Web_mobile" @click="$router.push({name: 'ph-reward-detail'})"
              >
            </div> -->
            <div class="container py-40">
              <div :class="`${isMobile() ? 'px-30' : 'px-60'}`">
                <h1 class="mb-30 text-20 uppercase-text"><b>Sertifikasi Halal Pizza Hut Indonesia</b></h1>
                <div class="text-justify mb-30">
                  <p class="text-justify mb-30 text-16 inline">Pizza Hut Indonesia mendapatkan </p><h2 class="text-16 inline font-normal">Sertifikat Halal</h2><p class="text-justify mb-30 text-16 inline"> pertama kali dari LPPOM Majelis Ulama Indonesia (MUI) pada tahun 1997. Ruang lingkup Sertifikasi yang diberikan </p><b>tidak hanya</b><p class="text-justify mb-30 text-16 inline"> untuk seluruh Produk Makanan dan Minuman, namun juga mencakup Outlet-Outlet, Gudang-Gudang Penyimpanan, serta Kendaraan Pengangkutan.</p>
                </div>
                
                <div class="text-justify mb-30">
                  <p class="text-justify mb-30 text-16 inline">Selain Sertifikat Halal, Pizza Hut Indonesia juga telah memperoleh sertifikasi Sistem Jaminan Halal (SJH) dari LPPOM </p><h2 class="text-16 inline font-normal">MUI</h2><p class="text-justify mb-30 text-16 inline"> yang juga merupakan pembuktian bahwa Majelis Ulama Indonesia memberikan Penilaian dengan <b>“Grade A”</b> untuk Pizza Hut Indonesia selama periode 3x (tiga kali) berturut-turut. </p>
                </div>

                <p class="text-justify mb-30 text-16">Pizza Hut Indonesia selalu menjalankan kepatuhan Halal tanpa ada pengecualian, termasuk untuk memperpanjang masa keberlakuan setiap kali berakhir sesuai dengan kebijakan dan ketetapan yang diberlakukan oleh Majelis Ulama Indonesia. Ini merupakan komitmen Kami untuk memastikan keamanan dan kenyamanan Para Pelanggan dalam mengkonsumsi produk-produk Pizza Hut di berbagai wilayah dari Sabang sampai Merauke.</p>

                <p class="text-justify mb-30 text-16">Ketika Pemerintah Republik Indonesia memberlakukan Peraturan Pemerintah No. 39 Tahun 2021 tentang Penyelenggaraan Bidang Jaminan Produk Halal, Kami segera melakukan pembaharuan dan penyesuaian kepatuhan terhadap Regulasi Halal sesuai dengan ketentuan Pemerintah <b>pada Tahun yang sama.</b></p>

                <p class="text-justify mb-30 text-16">Saat ini (1) Ketetapan Halal Majelis Ulama Indonesia, (2) Sertifikat Sistem Jaminan Halal LPPOM MUI dan (3) Sertifikat Halal Badan Penyelenggara Jaminan Produk Halal (BPJPH) yang dimiliki Pizza Hut Indonesia masih berlaku sejak Tahun 2021 sampai dengan <b>Tahun 2025.</b></p>

                <p class="text-justify mb-30 text-16 inline">Pizza Hut Indonesia terakhir kali menerima penghargaan LPPOM Majelis Ulama Indonesia </p><h2 class="text-16 inline font-normal">Halal Award</h2><p class="text-justify mb-30 text-16 inline"> 2023 untuk kategori <b>Favorite Halal Brand – Category of Food Services (Food)</b> pada tanggal 4 September 2023. </p>

                <div class="position-relative" style="z-index: -9999;">
                  <h2 class="text-white position-abolute">Pizza Hut</h2>
                  <h2 class="text-white inline position-abolute">Sertifikat Halal Pizza Hut</h2>
                  <h2 class="text-white inline position-abolute">Pizza Hut Halal</h2>
                  <h2 class="text-white inline position-abolute">Restoran Halal</h2>
                  <h2 class="text-white inline position-abolute">Donasi Pizza Hut</h2>
                </div>
              </div>
              <div class="mt-40 mb-40 px-60">
                <div class="image-container">
                  <img src="../assets/images/mui/Sertifikat_Halal_BPJPH.jpg" alt="Sertifikat Halal BPJPH Pizza Hut">
                  <img src="../assets/images/mui/Fatwa_MUI_Ketetapan_Halal.jpg" alt="Fatwa MUI Ketetapan Halal Pizza Hut">
                </div>
                <div class="image-container">
                  <img src="../assets/images/mui/Sertifikat_SJH.jpg" alt="Sertifikat SJH Pizza Hut">
                  <img src="../assets/images/mui/Fresh_Halal_Brand.jpg" alt="Fresh Halal Brand Pizza Hut">
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
// import VueMeta from 'vue-meta';

  export default {
    name: "news",
    // metaInfo: {
    //   title: 'Halaman Utama',
    //   meta: [
    //     { name: 'Pizza Hut', content: 'desc' },
    //     { name: 'Pizza Hut Halal', content: 'desc' },
    //     { name: 'Sertifikat Halal', content: 'desc' },
    //     { name: 'Sertifikat Halal Pizza Hut', content: 'desc' },
    //     { name: 'MUI', content: 'desc' },
    //     { name: 'Halal Award', content: 'desc' },
    //     { name: 'Pizza Hut Indonesia', content: 'desc' },
    //     { name: 'Restoran halal', content: 'desc' },
    //     { name: 'Donasi Pizza Hut', content: 'desc' },
    //     // Tag meta tambahan
    //     // ...
    //   ],
    // },
  }
</script>

<style scoped>

  .position-relative {
    position: relative;
  }
  .position-abolute {
    position: absolute;
  }
  .uppercase-text {
    text-transform: uppercase;
  }

  @media (min-width: 769px) {
    .image-container {
      display: flex;
      justify-content: space-between;
    }
  
    .image-container img {
      margin-left: 75px;
      margin-right: 75px;
      max-width: 35%;
    }
  }

  @media (max-width: 768px) {
    .py-40 {
      padding-top: 40px;
      padding-bottom: 40px
    }
    .image-container {
      flex-direction: column;
      justify-content: space-between;
    }

    .image-container img {
      margin-bottom: 20px;
    }
  }
</style>